import { Box } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";

interface Props {
  title: string;
  content: string | null;
}

export const EventRecords = ({ title, content }: Props) => {
  return (
    <GlobalContentBox
      title={title}
      style={{ minHeight: "10rem", width: "100%" }}
    >
      <Box width="100%" height="100%">
        {content}
      </Box>
    </GlobalContentBox>
  );
};
