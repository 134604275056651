import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { MyInput } from "../../../../../shared/components/inputs";
import { PhoneInput } from "../../../../../shared/components/inputs/PhoneInput";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { Company } from "../../../../../shared/types";
import { selectUser } from "../../../../auth/redux";
import {
  CreateClientAffiliateFormProps,
  CreateClientAffiliateFormShape,
} from "../../../schemas";
import { useCreateClientAffiliate } from "../../../services";
import * as S from "./CreateClientAffiliate.styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

type Key = keyof CreateClientAffiliateFormProps;

export const CreateClientAffiliate = ({ isOpen, onClose }: Props) => {
  const user = useSelector(selectUser) as Company;
  const [createAffiliate, { isLoading }] = useCreateClientAffiliate();

  const methods = useForm<CreateClientAffiliateFormProps>({
    resolver: yupResolver(CreateClientAffiliateFormShape),
  });

  const onSubmit = (data: CreateClientAffiliateFormProps) => {
    const birthday = data.affiliate_birthday.toISOString();
    const phone = `${data.country_code}${data.affiliate_phone}`;
    createAffiliate({
      birthday,
      company_id: user.company_id,
      ...(data.affiliate_curp && { affiliate_curp: data.affiliate_curp }),
      ...(data.affiliate_rfc && { affiliate_rfc: data.affiliate_rfc }),
      user: {
        phone,
        email: data.affiliate_email,
        surname: data.affiliate_surname,
        fullname: data.affiliate_fullname,
        password: data.affiliate_password,
      },
    })
      .unwrap()
      .then(onClose);
  };

  const afterClose = React.useCallback(() => {
    methods.reset();
  }, [methods]);

  return (
    <BaseModal
      width="45rem"
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={afterClose}
      title="Crear afiliado"
    >
      <S.CreateClientAffiliateForm
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MyInput<Key> name="affiliate_fullname" label="Nombres" />
        <MyInput<Key> name="affiliate_surname" label="Apellidos" />
        <MyInput<Key> name="affiliate_email" label="Correo electrónico" />
        <PhoneInput<Key> name="affiliate_phone" label="Teléfono" />
        <MyInput<Key> name="affiliate_rfc" label="RFC" />
        <MyInput<Key> name="affiliate_curp" label="CURP" />
        <MyInput<Key>
          name="affiliate_birthday"
          label="Fecha de nacimiento"
          type="date"
        />
        <MyInput<Key>
          name="affiliate_password"
          label="Contraseña"
          type="password"
        />
        <MyInput<Key>
          name="affiliate_confirm_password"
          label="Confirmar contraseña"
          type="password"
        />
        <PageButtons
          show
          proceedText="Crear"
          onCancel={onClose}
          isLoading={isLoading}
          className="buttons-container"
        />
      </S.CreateClientAffiliateForm>
    </BaseModal>
  );
};
