import { useMemo } from "react";
import * as T from "../../../../shared/components/styled";
import { getMoneyFormat } from "../../../../shared/helpers";
import { WatchReportCell } from "../../../events/components/reports-table/ReportsTable.styles";
import { ClientProduct } from "../../services";

interface Props {
  subscription: ClientProduct;
  onWatchDetails: (plan: ClientProduct) => void;
}

const intervalName: Record<string, string> = {
  month: "Mensual",
  year: "Anual",
  week: "Semanal",
};

export const SubscriptionsTableRow = ({
  onWatchDetails,
  subscription,
}: Props) => {
  const value = useMemo(
    () => `$${getMoneyFormat(subscription.unit_amount)}`,
    [subscription.unit_amount]
  );

  const onClick = () => onWatchDetails(subscription);

  return (
    <T.TableRow>
      <T.TableCell>{subscription.name}</T.TableCell>
      <T.TableCell>{subscription.metadata.service_name}</T.TableCell>
      <T.TableCell>{intervalName[subscription.interval] ?? "NA"}</T.TableCell>
      <T.TableCell>{subscription.metadata.initial_quantity}</T.TableCell>
      <T.TableCell>{value}</T.TableCell>
      <T.TableCell>
        <WatchReportCell onClick={onClick}>Ver</WatchReportCell>
      </T.TableCell>
    </T.TableRow>
  );
};
