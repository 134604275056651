import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Affiliate } from "../../../shared/types";

interface InitialState {
  activeAffiliate: Affiliate | null;
}

const initialState: InitialState = {
  activeAffiliate: null,
};

export const usersReducer = createSlice({
  name: "users",
  initialState,
  reducers: {
    setActiveAffiliate: (state, action: PayloadAction<Affiliate | null>) => {
      state.activeAffiliate = action.payload;
    },
  },
});

export const { setActiveAffiliate } = usersReducer.actions;
