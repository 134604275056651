import { colors } from "../../../theme";
import { Loader } from "../Loader/Loader";
import { PageLoaderWrapper } from "./PageLoader.styles";

interface Props {
  isOpaque?: boolean;
  size?: string;
  color?: string;
  showIf?: boolean;
}

export const PageLoader = (props: Props) => {
  if (!props.showIf) return null;

  return (
    <PageLoaderWrapper isOpaque={props.isOpaque}>
      <Loader size={props.size || "5rem"} color={props.color || colors.alert} />
    </PageLoaderWrapper>
  );
};
