import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Coverage } from "../../modules/events/models/interfaces";
import { Score } from "../../modules/operators/models";
import { Service } from "../../modules/users/services";
import { handleServiceRequest } from "../../shared/helpers";
import {
  Country,
  CountryResponse,
} from "../../shared/interfaces/country.interface";
import { User } from "../../shared/types";
import { generalRoutes, managersRoutes, usersRoutes } from "./api.routes";

export const globalApi = createApi({
  reducerPath: "globalApi",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const access = localStorage.getItem("access");
      access && headers.set("Authorization", `Bearer ${access}`);
      return headers;
    },
  }),
  tagTypes: [
    "services",
    "client-companies",
    "client-admins",
    "company affiliates",
    "affiliate medical records",
    "affiliates to call",
    "affiliate beneficiaries",
    "providers",
    "provider workers",
    "operators",
    "operator events",
    "profile",
    "user details",
    "provider vehicles",
    "vehicle types",
    "reports",
    "histories",
    "provider coverage",
    "provider zone",
    "client subscriptions",
    "coverages",
    "company plans",
  ],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getUserDetails: builder.query<User, string>({
      query: (id) => usersRoutes.getUserDetailsRT(id),
      transformResponse: (response: { data: User }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        handleServiceRequest(
          queryFulfilled,
          "Error al obtener los detalles del usuario, intente nuevamente"
        );
      },
      providesTags: ["user details"],
    }),
    getServices: builder.query<Service[], void>({
      query: () => generalRoutes.getServicesRT(),
      transformResponse: (response: { data: Service[] }) => response.data,
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(queryFulfilled, "Error al obtener los servicios");
      },
      keepUnusedDataFor: 86400,
      providesTags: ["services"],
    }),
    getUserScore: builder.query<number, User>({
      query: (user) => {
        let url = "";

        if (user.role_name === "Proveedor") {
          url = usersRoutes.getUserScoreRT("supplier", user.supplier_id);
        } else if (user.role_name === "Cliente") {
          url = usersRoutes.getUserScoreRT("client", user.client_id);
        } else if (user.role_name === "Operador") {
          url = usersRoutes.getUserScoreRT("operator", user.operator_id);
        } else if (user.role_name === "Empresa") {
          url = usersRoutes.getUserScoreRT("company", user.company_id);
        } else if (user.role_name === "Afiliado") {
          url = usersRoutes.getUserScoreRT("affiliate", user.affiliate_id);
        } else if (user.role_name === "Trabajador") {
          url = usersRoutes.getUserScoreRT("worker", user.worker_id);
        }

        return url;
      },
      transformResponse: (response: { data: Score }) => response.data.score,
      onQueryStarted: (user, { queryFulfilled }) => {
        if (user.role_name === "Gerente") return;
        handleServiceRequest(
          queryFulfilled,
          "Error al obtener el puntaje del usuario"
        );
      },
    }),
    getCountries: builder.query<Country[], void>({
      query: () => generalRoutes.getCountriesRT(),
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(
          queryFulfilled,
          "Error al obtener los países, por favor recargue la página"
        );
      },
      transformResponse: (response: { data: CountryResponse[] }) =>
        response.data.map((country) => ({
          ...country,
          code: `+${country.callingCodes[0]}`,
        })),
    }),
    getCoverage: builder.query<Coverage[], void>({
      query: () => managersRoutes.getCoverageRT(),
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(
          queryFulfilled,
          "Error al obtener la cobertura, por favor recargue la página"
        );
      },
      transformResponse: (response: { data: Coverage[] }) => response.data,
      providesTags: ["coverages"],
    }),
  }),
});

export const {
  useGetCoverageQuery: useGetCoverage,
  useGetServicesQuery: useGetServices,
  useGetUserScoreQuery: useGetUserScore,
  useGetCountriesQuery: useGetCountries,
  useGetUserDetailsQuery: useGetUserDetails,
} = globalApi;
