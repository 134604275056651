import { Box } from "../../../../../shared/components/styled";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  isCoverage?: boolean;
  onClose: () => void;
  afterClose?: () => void;
  onDeleteZone: () => void;
}

export const DeleteProviderZoneModal = (props: Props) => {
  const title = props.isCoverage ? "Eliminar cobertura" : "Eliminar zona";

  const content = props.isCoverage
    ? "¿Desea eliminar la cobertura seleccionada?"
    : "¿Desea eliminar la zona seleccionada?";

  return (
    <BaseModal
      title={title}
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      onAfterClose={props.afterClose}
      onlyCloseOnButton={props.isLoading}
    >
      <Box fontSize="1rem" style={{ fontWeight: 500, textAlign: "center" }}>
        {content}
      </Box>
      <PageButtons
        show
        fullWidth
        onCancel={props.onClose}
        cancelText="No, cancelar"
        proceedText="Si, eliminar"
        isLoading={props.isLoading}
        onProceed={props.onDeleteZone}
      />
    </BaseModal>
  );
};
