import * as Yup from "yup";
import {
  birthDateRequired,
  booleanRequired,
  curpRequired,
  emailRequired,
  numberRequired,
  phoneNoCodeRequired,
  rfcRequired,
  stringOptional,
  stringRequired,
  validPassword,
} from "../../../shared/schemas";

export const UserSearchFormShape = Yup.object({
  fullname: stringOptional,
  email: stringOptional,
  phone: stringOptional,
});

export type UserSearchFormProps = Yup.InferType<typeof UserSearchFormShape>;

/* ---------------------------------------------------------------------- */

export const NewClientPasswordShape = Yup.object({
  actualPassword: stringRequired,
  newPassword: validPassword,
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword"), null], "Las contraseñas no coinciden"),
});

export type NewClientPasswordProps = Yup.InferType<
  typeof NewClientPasswordShape
>;

/* ---------------------------------------------------------------------- */

export const NewClientFormShape = Yup.object({
  client_rfc: rfcRequired,
  country_code: stringRequired,
  client_email: emailRequired,
  client_phone: phoneNoCodeRequired,
  client_fullname: stringRequired,
  client_password: validPassword,
  client_confirmPassword: stringRequired.oneOf(
    [Yup.ref("client_password"), null],
    "Las contraseñas no coinciden"
  ),
  client_services: Yup.array().of(
    Yup.object({
      serviceId: stringRequired,
      service_quantity: numberRequired
        .min(1, "El valor debe ser mínimo 1")
        .integer("El valor debe ser entero"),
      service_value: numberRequired.min(1, "El valor debe ser mayor a 0"),
      pay_excess_consumption: booleanRequired.typeError("Campo requerido"),
    })
  ),
});

export type NewClientFormProps = Yup.InferType<typeof NewClientFormShape>;

/* ---------------------------------------------------------------------- */

export const UpdateClientFormShape = Yup.object({
  name: stringRequired,
  email: emailRequired,
  rfc: rfcRequired,
  country_code: stringRequired,
  phone: phoneNoCodeRequired,
  full_phone: stringOptional,
});

export type UpdateClientFormProps = Yup.InferType<typeof UpdateClientFormShape>;

/* ---------------------------------------------------------------------- */

export const CreateClientAffiliateFormShape = Yup.object({
  country_code: stringRequired,
  affiliate_rfc: rfcRequired,
  affiliate_curp: curpRequired,
  affiliate_email: emailRequired,
  affiliate_surname: stringRequired,
  affiliate_fullname: stringRequired,
  affiliate_password: validPassword,
  affiliate_phone: phoneNoCodeRequired,
  affiliate_birthday: birthDateRequired,
  affiliate_confirm_password: stringRequired.oneOf(
    [Yup.ref("affiliate_password"), null],
    "Las contraseñas no coinciden"
  ),
});

export type CreateClientAffiliateFormProps = Yup.InferType<
  typeof CreateClientAffiliateFormShape
>;

/* ---------------------------------------------------------------------- */
