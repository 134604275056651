import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import io, { Socket } from "socket.io-client";
import { UserRole } from "../../../shared/enums";
import { User } from "../../../shared/types";
import { VehiclePosition } from "../../operators/models";
import { EventDetails } from "../models/interfaces";
import {
  CancelEventSocket,
  CreateEventSocket,
  UpdateOperatorPqrSocket,
} from "../services/requests.interfaces";
import {
  SocketErrorResponse,
  SocketSuccessResponse,
  WorkerPosition,
} from "../services/responses.interfaces";

enum SocketEvents {
  // EMITS
  JOIN_ROOM = "joinRoom",
  LEAVE_ROOM = "leaveRoom",
  CREATE_EVENT = "createEvent",
  PQR_OPERATOR = "pqrOperator",
  GENERAL_EVENTS = "generalEvents",
  OPERATOR_EVENTS = "operatorEvents",
  SEND_ROOM_BY_EVENT = "sendRoomByEvent",
  GET_VEHICLES_POSITIONS = "getVehiclePositions",

  // LISTENS
  EVENT_ERROR = "eventError",
  CANCEL_EVENT = "cancelEvent",
  GET_EVENT_BY_ROOM = "getEventByRoom",
  GET_GENERAL_EVENTS = "getGeneralEvents",
  GET_OPERATOR_EVENTS = "getOperatorEvents",
  LISTEN_VEHICLES_POSITIONS = "vehiclePositions",
  GET_POINT_WORKER_VEHICLE = "getPointWorkerVehicle",
}

const SOCKET_URL = process.env.REACT_APP_EVENTS_SOCKET_URL || "";
const SOCKET_PATH = "/events";

const allowedRoles: UserRole[] = ["Gerente", "Operador", "Proveedor"];

export const useEventsSocket = (user: User | null) => {
  const [socket, setSocket] = useState<Socket>();
  const [events, setEvents] = useState<EventDetails[]>();
  const [activeEvent, setActiveEvent] = useState<EventDetails>();
  const [workerPosition, setWorkerPosition] = useState<WorkerPosition>();
  const [vehiclePositions, setVehiclePositions] = useState<
    VehiclePosition[] | null
  >([]);

  const allowRequest = useMemo(
    () => !!user?.role_name && allowedRoles.includes(user.role_name),
    [user?.role_name]
  );

  const isOperator = useMemo(
    () => user?.role_name === "Operador",
    [user?.role_name]
  );

  const isLoadingEvents = useMemo(() => !events, [events]);

  // Get Events as manager
  /* --------------------------------------------------------- */
  const requestManagerEvents = useCallback(
    (managerId: string) => {
      socket?.emit(SocketEvents.JOIN_ROOM, "general");
      socket?.emit(SocketEvents.GENERAL_EVENTS, { managerId });
    },
    [socket]
  );

  const getManagerEvents = useCallback(
    (managerId: string) => {
      requestManagerEvents(managerId);
      socket?.on(
        SocketEvents.GET_GENERAL_EVENTS,
        (receivedEvents: EventDetails[]) => {
          console.log("active events", receivedEvents);
          setEvents(receivedEvents);
        }
      );
    },
    [socket, requestManagerEvents]
  );
  /* --------------------------------------------------------- */

  // Get Events as operator
  /* --------------------------------------------------------- */
  const requestOperatorEvents = useCallback(
    (operatorId: string) => {
      socket?.emit(SocketEvents.JOIN_ROOM, operatorId);
      socket?.emit(SocketEvents.OPERATOR_EVENTS, { operatorId });
    },
    [socket]
  );

  const getOperatorEvents = useCallback(
    (operatorId: string) => {
      requestOperatorEvents(operatorId);
      socket?.on(
        SocketEvents.GET_OPERATOR_EVENTS,
        (receivedEvents: EventDetails[]) => {
          console.log("active receivedEvents", receivedEvents);
          setEvents(receivedEvents);
        }
      );
    },
    [socket, requestOperatorEvents]
  );
  /* --------------------------------------------------------- */

  // Create Event as operator
  /* --------------------------------------------------------- */
  const createEvent = useCallback<CreateEventSocket>(
    (...args) => {
      const [body, onSuccess, onError, onFinally] = args;
      if (isOperator) {
        socket?.emit(
          SocketEvents.CREATE_EVENT,
          body,
          (res: SocketSuccessResponse | SocketErrorResponse) => {
            console.log("Create event response", res);
            res.success ? onSuccess?.(res) : onError?.(res);
            onFinally?.();
          }
        );
      } else {
        toast.warn("No tienes permisos para crear eventos");
      }
    },
    [socket, isOperator]
  );
  /* --------------------------------------------------------- */

  // Function to cancel event
  /* --------------------------------------------------------- */
  const cancelEvent = useCallback<CancelEventSocket>(
    (...args) => {
      if (!isOperator) {
        toast.warn("No tienes permisos para cancelar eventos");
        return;
      }
      const [eventId, onSuccess, onError, onFinally] = args;
      socket?.emit(
        SocketEvents.CANCEL_EVENT,
        { eventId },
        (res: SocketSuccessResponse | SocketErrorResponse) => {
          console.log("Cancel event response", res);
          res.success ? onSuccess?.(res) : onError?.(res);
          onFinally?.();
        }
      );
    },
    [socket, isOperator]
  );
  /* --------------------------------------------------------- */

  // Function to update operator pqr
  /* --------------------------------------------------------- */
  const updateOperatorPqr = useCallback<UpdateOperatorPqrSocket>(
    (...args) => {
      if (!isOperator) {
        toast.warn("No tienes permisos para actualizar registros");
        return;
      }
      const [body, onSuccess, onError, onFinally] = args;
      socket?.emit(
        SocketEvents.PQR_OPERATOR,
        body,
        (res: SocketSuccessResponse | SocketErrorResponse) => {
          console.log("Update operator pqr response", res);
          res.success ? onSuccess?.(res) : onError?.(res);
          onFinally?.();
        }
      );
    },
    [socket, isOperator]
  );
  /* --------------------------------------------------------- */

  // Get event by id and track worker position
  /* --------------------------------------------------------- */
  const requestEventById = useCallback(
    (eventId: string) => {
      socket?.emit(SocketEvents.JOIN_ROOM, eventId);
      socket?.emit(SocketEvents.SEND_ROOM_BY_EVENT, { room: eventId });
    },
    [socket]
  );

  const getEventById = useCallback(
    (eventId: string) => {
      requestEventById(eventId);
      socket?.on(SocketEvents.GET_EVENT_BY_ROOM, (event: EventDetails) => {
        setActiveEvent(event);
      });
      socket?.on(
        SocketEvents.GET_POINT_WORKER_VEHICLE,
        (position: WorkerPosition) => {
          console.log("Worker position", position);
          setWorkerPosition(position);
        }
      );
    },
    [socket, requestEventById, setActiveEvent, setWorkerPosition]
  );
  /* --------------------------------------------------------- */

  // Function to leave the event details room
  /* --------------------------------------------------------- */
  const leaveEventRoom = useCallback(
    (eventId: string) => {
      socket?.emit(SocketEvents.LEAVE_ROOM, eventId);
      socket?.off(SocketEvents.GET_EVENT_BY_ROOM);
      socket?.off(SocketEvents.GET_POINT_WORKER_VEHICLE);
      setActiveEvent(undefined);
      setWorkerPosition(undefined);
    },
    [socket]
  );
  /* --------------------------------------------------------- */

  // Function to get and listen the active vehicles positions
  /* --------------------------------------------------------- */
  const requestVehiclesPositions = useCallback(() => {
    console.log("request vehicles positions");
    socket?.emit(
      SocketEvents.GET_VEHICLES_POSITIONS,
      (vehicles: VehiclePosition[]) => {
        console.log("get vehicle Positions", vehicles);
        setVehiclePositions(vehicles);
      }
    );
    socket?.on(
      SocketEvents.LISTEN_VEHICLES_POSITIONS,
      (vehicles: VehiclePosition[]) => {
        console.log("listen vehicle Positions", vehicles);
        setVehiclePositions(vehicles);
      }
    );
  }, [socket]);
  /* --------------------------------------------------------- */

  // Function to leave the vehicles positions room
  /* --------------------------------------------------------- */
  const leaveVehiclesPositions = useCallback(() => {
    socket?.off(SocketEvents.LISTEN_VEHICLES_POSITIONS);
    setVehiclePositions([]);
  }, [socket]);
  /* --------------------------------------------------------- */

  // Connect to socket
  useLayoutEffect(() => {
    if (!allowRequest) return;
    const authorization = `Bearer ${localStorage.getItem("access")}`;
    const mySocket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      transports: ["polling"],
      extraHeaders: { authorization },
    });
    setSocket(mySocket);
  }, [allowRequest]);

  // Get events depending on user role
  useEffect(() => {
    if (!allowRequest) return;
    if (user?.role_name === "Gerente") {
      getManagerEvents(user.manager_id);
    }
    if (user?.role_name === "Operador") {
      getOperatorEvents(user.operator_id);
    }

    return () => {
      socket?.off(SocketEvents.GET_GENERAL_EVENTS);
      socket?.off(SocketEvents.GET_OPERATOR_EVENTS);
      socket?.off(SocketEvents.GET_EVENT_BY_ROOM);
      socket?.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowRequest, user?.role_name, getManagerEvents]);

  return {
    events,
    activeEvent,
    isLoadingEvents,
    workerPosition,
    vehiclePositions,

    createEvent,
    cancelEvent,
    getEventById,
    leaveEventRoom,
    updateOperatorPqr,
    leaveVehiclesPositions,
    requestVehiclesPositions,
  };
};
