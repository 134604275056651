import styled from "styled-components";

interface TextProps {
  secondary?: boolean;
  fontWeight?: string;
  fontSize?: string;
  lineHeight?: string;
  color?: string;

  m?: string;
  mt?: string;
  mr?: string;
  mb?: string;
  ml?: string;
  mx?: string;
  my?: string;

  p?: string;
  pt?: string;
  pr?: string;
  pb?: string;
  pl?: string;
  px?: string;
  py?: string;

  textAlign?: string;
}

export const Text = styled.h2<TextProps>`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => props.color || props.theme.colors.text};
  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt};
  margin-right: ${(props) => props.mr};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};

  margin-left: ${(props) => props.mx};
  margin-right: ${(props) => props.mx};

  margin-top: ${(props) => props.my};
  margin-bottom: ${(props) => props.my};

  padding: ${(props) => props.p};
  padding-top: ${(props) => props.pt};
  padding-right: ${(props) => props.pr};
  padding-bottom: ${(props) => props.pb};
  padding-left: ${(props) => props.pl};

  padding-left: ${(props) => props.px};
  padding-right: ${(props) => props.px};

  padding-top: ${(props) => props.py};
  padding-bottom: ${(props) => props.py};

  text-align: ${(props) => props.textAlign};

  ${(props) =>
    props.secondary &&
    `
        color: ${props.theme.colors.gray.primary};
    `}
`;
