import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { MyInput } from "../../../../shared/components/inputs";
import { Button } from "../../../../shared/components/ui/button";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { GetUsersParams } from "../../../../shared/interfaces";
import { ReactSetter } from "../../../../shared/types";
import {
  OperatorSearchFormProps,
  OperatorSearchFormShape,
} from "../../schemas/index";
import { OperatorSearchForm } from "./OperatorsSearch.styles";

type Keys = keyof OperatorSearchFormProps;

interface Props {
  isLoading: boolean;
  setOperatorFilters: ReactSetter<GetUsersParams>;
}

export const OperatorsSearch = ({ isLoading, setOperatorFilters }: Props) => {
  const methods = useForm<OperatorSearchFormProps>({
    resolver: yupResolver(OperatorSearchFormShape),
  });

  const onSubmit = (data: OperatorSearchFormProps) => {
    const birthday = data.birthday
      ? dayjs(data.birthday).toISOString()
      : undefined;
    setOperatorFilters({
      ...data,
      birthday,
    });
  };

  return (
    <GlobalContentBox title="Búsqueda de operadores">
      <OperatorSearchForm
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MyInput<Keys>
          name="fullname"
          label="Nombre"
          placeholder="Ingresa un nombre"
        />
        <MyInput<Keys>
          name="birthday"
          label="Fecha de nacimiento"
          placeholder="Ingresa una fecha"
          type="date"
        />
        <MyInput<Keys>
          name="phone"
          label="Número de teléfono"
          placeholder="Ingresa un número"
        />
        <Button animate loading={isLoading}>
          Buscar
        </Button>
      </OperatorSearchForm>
    </GlobalContentBox>
  );
};
