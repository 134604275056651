export const getPhoneWithFormat = (phone: string) => {
  const countryCode = phone.slice(0, 3);
  const restOfPhone = phone.slice(3);

  // TODO: WHEN THE COUNTRY CODE IS 3 LETTERS LONG, THIS WILL NOT WORK CORRECTLY, SO FIX IT
  const firstSection = restOfPhone.slice(0, 3);
  const secondSection = restOfPhone.slice(3, 6);
  const thirdSection = restOfPhone.slice(6, 10);

  return `${countryCode} ${firstSection} ${secondSection} ${thirdSection}`;
};
