import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Coverage } from "../../../modules/events/models/interfaces";
import { Form } from "../form";
import { MySelect } from "../my-select";
import { Box } from "../styled";
import { Button } from "../ui/button";

interface Props {
  map: google.maps.Map | undefined;
  coverages: Coverage[];
  onChange?: (coverageId: string) => void;
}

interface FormProps {
  coverage_id: string;
  countrySelected: string;
}

export const CoverageSelector = ({ coverages, map, onChange }: Props) => {
  const methods = useForm<FormProps>({
    values: {
      coverage_id:
        coverages.find((cov) => cov.coverage_country === "Mexico")
          ?.coverage_id ??
        coverages[0]?.coverage_id ??
        "",
      countrySelected:
        coverages.find((cov) => cov.coverage_country === "Mexico")
          ?.coverage_country ??
        coverages[0]?.coverage_country ??
        "",
    },
  });

  const coverageId = methods.watch("coverage_id");
  const countrySelected = methods.watch("countrySelected");

  const countries = useMemo<[]>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => [...new Set(coverages.map((cov) => cov.coverage_country))],
    [coverages]
  );

  const coverageCities = useMemo(() => {
    return coverages.filter((cov) => cov.coverage_country === countrySelected);
  }, [coverages, countrySelected]);

  const onShowAllCountryCoverages = () => {
    const bounds = new google.maps.LatLngBounds();
    if (coverageCities.length > 1) {
      coverageCities.forEach((cov) => {
        bounds.extend({ lat: +cov.coverage_lat, lng: +cov.coverage_lng });
      });
      map?.fitBounds(bounds, {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      });
      return;
    }
    const [firstCoverage] = coverageCities;
    if (firstCoverage) {
      map?.panTo({
        lat: +firstCoverage.coverage_lat,
        lng: +firstCoverage.coverage_lng,
      });
      map?.setZoom(10);
    }
  };

  useEffect(() => {
    onChange?.(coverageId);
  }, [coverageId, onChange]);

  useEffect(() => {
    const coverage = coverages.find((cov) => cov.coverage_id === coverageId);
    if (coverage && map) {
      map.panTo({ lat: +coverage.coverage_lat, lng: +coverage.coverage_lng });
      map.setZoom(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverageId]);

  useEffect(() => {
    const [firstCoverage] = coverageCities;
    if (firstCoverage) {
      methods.setValue("coverage_id", firstCoverage.coverage_id);
    }
  }, [coverageCities, methods]);

  return (
    <Form methods={methods}>
      <Box grid alignEnd gridColumns="1fr 1fr 0.5fr" gap="1rem">
        <MySelect
          label="País"
          values={countries}
          name="countrySelected"
          placeholder="Selecciona una cobertura"
          defaultValue={countries.find((c) => c === "Colombia") && "Colombia"}
        />
        <MySelect
          label="Ciudad"
          get="coverage_id"
          name="coverage_id"
          show="coverage_city"
          values={coverageCities}
          placeholder="Selecciona una cobertura"
        />
        <Button
          py="0.9rem"
          type="button"
          fontSize="1rem"
          tippyDelay={[500, 0]}
          onClick={onShowAllCountryCoverages}
          tippy="Muestra las coberturas del país seleccionado"
        >
          Mostrar coberturas
        </Button>
      </Box>
    </Form>
  );
};
