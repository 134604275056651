import * as Yup from "yup";
import {
  booleanRequired,
  emailRequired,
  numberRequired,
  phoneNoCodeRequired,
  rfcRequired,
  stringRequired,
  validPassword,
} from "../../../shared/schemas";

export const CreateAdminClientFormShape = Yup.object({
  client_rfc: rfcRequired,
  client_email: emailRequired,
  client_code: stringRequired
    .min(6, "El código debe ser mínimo 6 caracteres")
    .matches(/^[a-zA-Z0-9]+$/, "El código debe tener solo letras y números"),
  country_code: stringRequired,
  client_password: validPassword,
  client_fullname: stringRequired,
  client_phone: phoneNoCodeRequired,
  client_confirm_password: stringRequired.oneOf(
    [Yup.ref("client_password"), null],
    "Las contraseñas no coinciden"
  ),
});

export type CreateADminClientFormProps = Yup.InferType<
  typeof CreateAdminClientFormShape
>;

/* ------------------------------------------------------- */

export const UpdateAdminClientFormShape = Yup.object({
  email: emailRequired,
  rfc: rfcRequired,
  fullname: stringRequired,
  country_code: stringRequired,
  full_phone: stringRequired,
  phone: phoneNoCodeRequired,
});

export type UpdateAdminClientFormProps = Yup.InferType<
  typeof UpdateAdminClientFormShape
>;

/* ------------------------------------------------------- */

export const CreateSubscriptionFormShape = Yup.object({
  name: stringRequired,
  interval: stringRequired,
  description: stringRequired,
  quantity: numberRequired
    .min(1, "La cantidad debe ser mínimo 1")
    .integer("La cantidad debe ser entero"),
  serviceId: stringRequired,
  price: numberRequired.min(1, "El precio debe ser mayor a 1"),
  allow_promotion_codes: booleanRequired.typeError("Campo requerido"),
});

export type CreateSubscriptionFormProps = Yup.InferType<
  typeof CreateSubscriptionFormShape
>;

/* ------------------------------------------------------- */
