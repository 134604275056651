import styled from "styled-components";
import { Form } from "../../../../shared/components/form";

export const LoginContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.5rem;
  padding-bottom: 5rem;
  border-radius: 3.125rem;
  width: 25rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  > img {
    width: 14.25rem;
    height: 9rem;
  }
`;

export const LoginForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const ValidateCodeForm = styled(LoginForm)`
  > h3 {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .buttons-container {
    flex-direction: column-reverse;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const ForgotPasswordText = styled.p`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blue};
  text-align: center;
  cursor: pointer;
`;
