import {
  GoogleMap,
  useJsApiLoader,
  useLoadScript,
} from "@react-google-maps/api";
import React, { useMemo } from "react";
import { MyLoader } from "../ui/Loader";

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY as string;

const options: google.maps.MapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  disableDoubleClickZoom: true,
};

export const defaultCenter = { lat: 23.634501, lng: -102.552784 };

type Libraries = Parameters<typeof useLoadScript>[0]["libraries"];
export type MapType = "js" | "load";
interface Props {
  height?: string;
  zoom?: number;
  children?: React.ReactNode;
  center?: google.maps.LatLngLiteral;
  libraries?: Libraries;

  onUnmounted?: () => void;
  onLoaded?: (map: google.maps.Map) => void;
  type?: MapType;
}

export const Map = ({
  height = "20rem",
  zoom,
  children,
  center,
  type = "js",
  onLoaded,
  onUnmounted,
}: Props) => {
  const containerStyle: React.CSSProperties = {
    height,
    width: "100%",
    borderRadius: "0.5rem",
  };

  const useMapHook = useMemo(
    () => (type === "load" ? useLoadScript : useJsApiLoader),
    [type]
  );

  const { isLoaded } = useMapHook({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: type === "load" ? ["drawing"] : undefined,
  });

  const onLoad = React.useCallback(
    (map: google.maps.Map) => {
      map.panTo(center || defaultCenter);
      map.setZoom(zoom || 5);
      onLoaded?.(map);
    },
    [onLoaded, center, zoom]
  );

  const onUnmount = React.useCallback(() => {
    onUnmounted?.();
  }, [onUnmounted]);

  if (!isLoaded) return <MyLoader />;

  return (
    <GoogleMap
      onLoad={onLoad}
      center={center}
      options={options}
      onUnmount={onUnmount}
      mapContainerStyle={containerStyle}
    >
      {/* <Marker
                icon={{
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 7,
                }}
                position={center}
            /> */}
      {/* <Marker
                icon={{
                    path:
                        "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                    fillColor: "yellow",
                    fillOpacity: 0.9,
                    scale: 2,
                    strokeColor: "gold",
                    strokeWeight: 2,
                }}
                position={center}
            /> */}
      {children}
    </GoogleMap>
  );
};
