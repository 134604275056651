import { createContext, useContext } from "react";
import { Operator } from "../../../../shared/types";

interface IOperatorDetailsCtx {
  operator?: Operator;
  isEditMode: boolean;
  formRef: React.RefObject<any>;
  toggleEditMode: () => void;
}

export const OperatorDetailsCtx = createContext({} as IOperatorDetailsCtx);

export const useOperatorDetailsCtx = () => useContext(OperatorDetailsCtx);
