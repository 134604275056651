import * as T from "../../../../../shared/components/styled";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { For } from "../../../../../shared/utilities/For";
import { CallInList } from "../../../models";
import { CallingListRow } from "./CallingListRow";

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  callTickets: CallInList[];
  onRequestClose: () => void;
  onCallTicket: (ticket: CallInList) => void;
}

const tableTitles = ["Ticket", "Nombre", "Solicitado hace"];

const columns = "0.5fr 1fr 1fr 0.9fr";

export const CallingListModal = ({
  isOpen,
  isLoading,
  callTickets,
  onCallTicket,
  onRequestClose,
}: Props) => {
  return (
    <BaseModal
      width="auto"
      title="Llamadas"
      isOpen={isOpen}
      isLoading={isLoading}
      styles={{ maxWidth: "45rem" }}
      onRequestClose={onRequestClose}
    >
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={tableTitles}
            render={(title) => <T.TableTitle>{title}</T.TableTitle>}
          />
        </T.TableTitlesBox>
        <T.TableBody gridColumns={columns}>
          <For
            each={callTickets}
            render={(ticket) => (
              <CallingListRow ticket={ticket} onCallTicket={onCallTicket} />
            )}
          />
        </T.TableBody>
      </T.Table>
    </BaseModal>
  );
};
