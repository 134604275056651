import { AdminClientRoutes } from "../modules/admin-client/routes";
import { AffiliatesRoutes } from "../modules/affiliates/routes";
import { NotAllowedPage } from "../modules/auth/pages/not-allowed";
import { AuthRoutes } from "../modules/auth/routes";
import { EventsRoutes } from "../modules/events/routes";
import { InfoRoutes } from "../modules/info/routes";
import { ManagerRoutes } from "../modules/manager/routes";
import { OperatorsRoutes } from "../modules/operators/routes";
import { ProfileRoutes } from "../modules/profile/routes";
import { ProvidersRoutes } from "../modules/providers/routes";
import { UsersRoutes } from "../modules/users/routes";

export const PublicRoutes = [...AuthRoutes, ...InfoRoutes];

export const PrivateRoutes = [
  ...AdminClientRoutes,

  ...UsersRoutes,

  ...ManagerRoutes,

  ...OperatorsRoutes,

  ...ProvidersRoutes,

  ...ProfileRoutes,

  ...AffiliatesRoutes,

  ...EventsRoutes,

  {
    path: "/not-allowed",
    name: "Sin acceso",
    Component: () => <NotAllowedPage />,
  } as const,
];

export const AllRoutes = [...PublicRoutes, ...PrivateRoutes];

const routesNames = AllRoutes.map((route) => route.name);
export type RouteName = (typeof routesNames)[number];

if (new Set(routesNames).size !== routesNames.length) {
  throw new Error("There is two routes with the same name");
}
