import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useRef } from "react";
import { useClickOutside } from "react-haiku";
import { setIsDeleteAffiliateModal } from "../../../../redux/reducers/ui-reducer";
import { useGetUserScore } from "../../../../redux/rtk-query";
import { useAppDispatch } from "../../../../redux/store";
import { ActionsCellBox } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { UserDetailsCard } from "../../../../shared/components/user-info/user-details-card";
import { useToggle } from "../../../../shared/hooks";
import { ActionsCellStyles } from "../../../../shared/styles";
import { If } from "../../../../shared/utilities/If";
import { OpenOptionsButton } from "../../../users/components/client-details-card/ClientDetailsCard.styles";
import { useAffiliateDetailsCtx } from "../../context/affiliate-details-ctx/AffiliateDetailsCtx";
import { AffiliateInfoForm } from "../affiliate-info-form";

export const AffiliateDetailsCard = () => {
  const actionsRef = useRef(null);
  const dispatch = useAppDispatch();
  const [optionsOpen, toggleOptionsOpen, setIsOptionsOpen] = useToggle();
  const { affiliate, isEditMode, formRef, toggleEditMode } =
    useAffiliateDetailsCtx();

  const { data: score } = useGetUserScore(affiliate || skipToken);

  useClickOutside(actionsRef, () => setIsOptionsOpen(false));

  const onDeleteAffiliate = () => {
    dispatch(setIsDeleteAffiliateModal(true));
    setIsOptionsOpen(false);
  };

  const editTitle =
    affiliate?.user_status !== "Suspendido" ? "Editar Afiliado" : undefined;

  return (
    <GlobalContentBox style={{ position: "relative" }}>
      <UserDetailsCard
        score={score}
        toggleEditMode={toggleEditMode}
        profilePhoto={affiliate?.user_photography}
        editTitle={isEditMode ? undefined : editTitle}
      />
      <AffiliateInfoForm ref={formRef} />
      <If showIf={isEditMode && !optionsOpen}>
        <OpenOptionsButton
          extraStyles={ActionsCellStyles}
          onClick={toggleOptionsOpen}
        >
          <div />
          <div />
          <div />
        </OpenOptionsButton>
      </If>
      <If showIf={isEditMode && optionsOpen}>
        <OpenOptionsButton extraStyles={ActionsCellStyles}>
          <div />
          <div />
          <div />
        </OpenOptionsButton>
      </If>
      <If showIf={isEditMode && optionsOpen}>
        <ActionsCellBox top="2rem" right="1.5rem" ref={actionsRef}>
          <div onClick={onDeleteAffiliate}>Eliminar Afiliado</div>
        </ActionsCellBox>
      </If>
    </GlobalContentBox>
  );
};
