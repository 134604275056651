import dayjs from "dayjs";
import { useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetUserDetails } from "../../../../redux/rtk-query";
import { PageContainer } from "../../../../shared/components/styled";
import { MyLoader } from "../../../../shared/components/ui/Loader";
import { PageButtons } from "../../../../shared/components/ui/page-buttons";
import { UserSectionNav } from "../../../../shared/components/user-info/user-section-nav";
import { useToggle } from "../../../../shared/hooks";
import { Affiliate } from "../../../../shared/types";
import { ClientAffiliatesTable } from "../../../users/components/client-affiliates-table";
import { DeleteAffiliateModal } from "../../../users/components/modals/delete-affiliate-modal";
import { AffiliateDetailsCard } from "../../components/affiliate-details-card";
import {
  AffiliateDetailsCtx,
  IAffiliateDetailsCtx,
} from "../../context/affiliate-details-ctx";
import { UpdateAffiliateFormProps } from "../../schemas";
import {
  useGetAffiliateBeneficiaries,
  useUpdateAffiliate,
} from "../../services";
import { UpdateAffiliateBody } from "../../services/requests.interfaces";
import { If } from "../../../../shared/utilities/If";

interface FormReturn {
  validateForm: () => Promise<{
    isValid: boolean;
    values: UpdateAffiliateFormProps;
  }>;
}

export const AffiliateDetailsPage = () => {
  const formRef = useRef<FormReturn>();
  const { userId } = useParams() as { userId: string };
  const [isEditMode, toggleEditMode] = useToggle();

  const { data: affiliate, isFetching } = useGetUserDetails(userId);
  const [updateAffiliate, { isLoading: isUpdating }] = useUpdateAffiliate();
  const { data: beneficiaries = [], isLoading: isGettingBeneficiaries } =
    useGetAffiliateBeneficiaries(affiliate?.contributor_id || skipToken);

  const onUpdate = async () => {
    if (!formRef.current || affiliate?.role_name !== "Afiliado") return;
    const { isValid, values } = await formRef.current.validateForm();
    if (!isValid) return;

    const birthday = dayjs(values.birthday).toISOString();
    const phone = `${values.country_code}${values.phone}`;
    const body: UpdateAffiliateBody = {
      birthday,
      affiliate_id: affiliate.affiliate_id,
      curp: (values.curp ?? "").toUpperCase(),
      rfc: (values.rfc ?? "").toUpperCase(),
      user: {
        phone,
        email: values.email,
        surname: values.surname,
        fullname: values.fullname,
      },
    };
    updateAffiliate(body).unwrap().then(toggleEditMode);
  };

  const value = useMemo<IAffiliateDetailsCtx>(
    () => ({
      affiliate: affiliate as Affiliate,
      formRef,
      isEditMode,
      toggleEditMode,
    }),
    [affiliate, formRef, isEditMode, toggleEditMode]
  );

  if (isFetching) return <MyLoader />;

  return (
    <AffiliateDetailsCtx.Provider value={value}>
      <PageContainer gap="1rem">
        <UserSectionNav
          title={`${affiliate?.user_fullname} - ${affiliate?.role_name}`}
          actualSection={affiliate?.user_fullname || ""}
          toggleText="Usuario Activo"
        />
        <AffiliateDetailsCard />
        <PageButtons
          show={isEditMode}
          isLoading={isUpdating}
          onProceed={onUpdate}
          onCancel={toggleEditMode}
          proceedText="Guardar Cambios"
        />
        <If showIf={!!affiliate?.contributor_id}>
          <ClientAffiliatesTable
            pageCount={1}
            title="Beneficiarios"
            canHandleActions={false}
            affiliates={beneficiaries}
            isLoading={isGettingBeneficiaries}
          />
        </If>
      </PageContainer>
      <DeleteAffiliateModal affiliate={affiliate as Affiliate} />
    </AffiliateDetailsCtx.Provider>
  );
};
