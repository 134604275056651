import { useState, useMemo } from "react";
import { PageContainer, PageTitle } from "../../../../shared/components/styled";
import { ReportsSearch } from "../../components/reports-search";
import { ReportsTable } from "../../components/reports-table/ReportsTable";
import { useGetHistories, useGetReports } from "../../services";
import { EventsQueryFilters } from "../../services/requests.interfaces";

export type ReportPageType = "reports" | "historic";

interface Props {
  pageType: ReportPageType;
}

export const ReportsListPage = ({ pageType }: Props) => {
  const isReportType = pageType === "reports";
  const [reportsFilter, setReportsFilter] = useState<EventsQueryFilters>({});

  const {
    data: reports,
    isLoading: isLoadingReports,
    isFetching: isFetchingReports,
  } = useGetReports(reportsFilter, { skip: !isReportType });

  const {
    data: histories,
    isLoading: isLoadingHistories,
    isFetching: isFetchingHistories,
  } = useGetHistories(reportsFilter, { skip: isReportType });

  const title = useMemo(
    () => (isReportType ? "Reportes" : "Historial"),
    [isReportType]
  );

  const isLoading = useMemo(
    () => (isReportType ? isLoadingReports : isLoadingHistories),
    [isReportType, isLoadingReports, isLoadingHistories]
  );

  const isFetching = useMemo(
    () => (isReportType ? isFetchingReports : isFetchingHistories),
    [isReportType, isFetchingReports, isFetchingHistories]
  );

  const events = useMemo(
    () => (isReportType ? reports : histories),
    [isReportType, reports, histories]
  );

  const onPaginate = (page: number) => {
    setReportsFilter({
      ...reportsFilter,
      page,
    });
  };

  return (
    <PageContainer>
      <PageTitle>{title}</PageTitle>
      <ReportsSearch isLoading={isFetching} setFilters={setReportsFilter} />
      <ReportsTable
        pageType={pageType}
        isLoading={isLoading}
        onPageChange={onPaginate}
        events={events?.results || []}
        pageCount={events?.total_pages || 0}
      />
    </PageContainer>
  );
};
