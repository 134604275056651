import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../auth/redux";
import { useEventsSocket } from "../../hooks/useEventsSocket";
import { EventsSocketCtx } from "./EventsSocketCtx";

interface Props {
  children: React.ReactNode;
}

export const EventsSocketProvider = ({ children }: Props) => {
  const user = useSelector(selectUser);
  const result = useEventsSocket(user);

  return (
    <EventsSocketCtx.Provider value={result}>
      {children}
    </EventsSocketCtx.Provider>
  );
};
