import React from "react";
import { ActiveEvent } from "../../models";
import { ActiveEvensItem } from "./ActiveEvensItem";
import * as S from "./OperatorActiveEvents.styles";

interface Props {
  events: ActiveEvent[];
}

export const ActiveEventsList = ({ events }: Props) => {
  return (
    <S.ActiveEventsListWrapper>
      {events.map((event) => (
        <ActiveEvensItem event={event} key={event._id} />
      ))}
    </S.ActiveEventsListWrapper>
  );
};
