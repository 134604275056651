import React from "react";
import {
  DrawingManager as Drawing,
  DrawingManagerProps,
  useGoogleMap,
} from "@react-google-maps/api";

interface Props extends DrawingManagerProps {
  visible: boolean;
}

export const DrawingManager = ({ visible, ...props }: Props) => {
  const map = useGoogleMap();

  const options: DrawingManagerProps["options"] = {
    map,
    drawingControl: visible,
    drawingControlOptions: {
      position: 5,
      drawingModes: ["polygon"] as any,
    },
  };

  return <Drawing {...props} options={options} />;
};
