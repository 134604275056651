import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectIsCreateAdminClientModal,
  setIsCreateAdminClientModal,
} from "../../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../../redux/store";
import { MyInput } from "../../../../../shared/components/inputs";
import { PhoneInput } from "../../../../../shared/components/inputs/PhoneInput";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { selectUser } from "../../../../auth/redux";
import {
  CreateADminClientFormProps,
  CreateAdminClientFormShape,
} from "../../../schemas";
import { CreateAdminClientBody, useCreateAdminClient } from "../../../services";
import * as S from "./CreateAdminClientStyles";

type Key = keyof CreateADminClientFormProps;

export const CreateAdminClientModal = () => {
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const isOpen = useSelector(selectIsCreateAdminClientModal);

  const methods = useForm<CreateADminClientFormProps>({
    resolver: yupResolver(CreateAdminClientFormShape),
  });

  const [createAdminClient, { isLoading }] = useCreateAdminClient();

  const onClose = () => {
    dispatch(setIsCreateAdminClientModal(false));
  };

  const onSubmit = (data: CreateADminClientFormProps) => {
    if (user?.role_name !== "Gerente") return;
    const phone = `${data.country_code}${data.client_phone}`;
    const body: CreateAdminClientBody = {
      manager_id: user.manager_id,
      code: data.client_code,
      ...(data.client_rfc && { rfc: data.client_rfc }),
      user: {
        phone,
        email: data.client_email,
        fullname: data.client_fullname,
        password: data.client_password,
      },
    };
    createAdminClient(body).unwrap().then(onClose);
  };

  return (
    <BaseModal
      isOpen={isOpen}
      title="Crear Cliente"
      onRequestClose={onClose}
      onAfterClose={methods.reset}
    >
      <S.CreateAdminClientFormWrapper
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MyInput<Key> name="client_fullname" label="Nombre Completo" />
        <MyInput<Key> name="client_email" label="Correo Electrónico" />
        <MyInput<Key> name="client_code" label="Código de suscripción" />
        <PhoneInput<Key> name="client_phone" label="Teléfono" />
        <MyInput<Key> name="client_rfc" label="RFC" />
        <MyInput<Key>
          name="client_password"
          label="Contraseña"
          type="password"
        />
        <MyInput<Key>
          name="client_confirm_password"
          label="Confirmar Contraseña"
          type="password"
        />
        <PageButtons
          show
          onCancel={onClose}
          isLoading={isLoading}
          proceedText="Crear Cliente"
        />
      </S.CreateAdminClientFormWrapper>
    </BaseModal>
  );
};
