import * as Yup from "yup";
import { patterns } from "../constants";

const stringRequired = Yup.string().required("Este campo es requerido");

const numberRequired = Yup.number()
  .required("Este campo es requerido")
  .typeError("Este campo debe ser un número");

const booleanRequired = Yup.boolean().required("Este campo es requerido");

const emailRequired = stringRequired
  .lowercase()
  .matches(patterns.email, "El email no es válido");

const stringOptional = Yup.string();

const emailOptional = Yup.mixed().test(
  "isEmail",
  "El email no es válido",
  (value) => {
    if (!value) return true;
    return patterns.email.test(value);
  }
);

const birthDateRequired = Yup.date()
  .required("Este campo es requerido")
  .max(new Date(), "La fecha no puede ser mayor a la fecha actual")
  .typeError("Ingrese una fecha válida");

const validPassword = Yup.string()
  .required("Este campo es requerido")
  .min(9, "La contraseña debe tener 9 o mas caracteres")
  .matches(
    patterns.atLeastOneUppercase,
    "La contraseña debe tener al menos una mayúscula"
  )
  .matches(
    patterns.atLeastOneLowercase,
    "La contraseña debe tener al menos una minúscula"
  )
  .matches(
    patterns.atLeastOneNumber,
    "La contraseña debe tener al menos un número"
  );

const phoneRequired = stringRequired.matches(
  patterns.phone,
  "El teléfono no es válido"
);

const phoneNoCodeRequired = stringRequired.matches(
  patterns.phoneNoCode,
  "El teléfono no es válido"
);

const rfcRequired = stringOptional.uppercase().matches(patterns.rfc, {
  message: "El RFC no es válido",
  excludeEmptyString: true,
});

const curpRequired = stringOptional.uppercase().matches(patterns.curp, {
  message: "La CURP no es válida",
  excludeEmptyString: true,
});

export {
  rfcRequired,
  curpRequired,
  emailRequired,
  emailOptional,
  validPassword,
  phoneRequired,
  stringRequired,
  numberRequired,
  stringOptional,
  booleanRequired,
  birthDateRequired,
  phoneNoCodeRequired,
};
