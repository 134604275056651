import React from "react";
import { Box } from "../../styled";
import { GlobalContentBox } from "../../ui/global-content-box";
import * as S from "./StatsItem.styles";

interface Props {
  title: string;
  icon: JSX.Element;
  value?: string | number;
  isLoading?: boolean;
}

export const StatsItem = ({ icon, title, value, isLoading }: Props) => {
  return (
    <GlobalContentBox isLoading={isLoading}>
      <S.StatsItemWrapper>
        <Box flex flexColumn gap=".5rem">
          {icon}
          <h4>{title}</h4>
        </Box>
        <S.StatsValue>{value}</S.StatsValue>
      </S.StatsItemWrapper>
    </GlobalContentBox>
  );
};
