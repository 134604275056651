import styled from "styled-components";
import { Form } from "../../../../shared/components/form";
import { SearchComponentStyles } from "../../../../shared/styles";

interface Props {
  hasErrors: boolean;
}

export const UserSearchForm = styled(Form)<Props>`
  ${SearchComponentStyles}
`;
