import { toast } from "react-toastify";
import {
  communicationRoutes,
  globalApi,
  operatorRoutes,
  usersRoutes,
} from "../../../redux/rtk-query";
import store from "../../../redux/store";
import { UserStatusEnum } from "../../../shared/enums";
import { handleServiceRequest, includesAll } from "../../../shared/helpers";
import { ErrorResponse, GetUsersParams } from "../../../shared/interfaces";
import { Affiliate, Operator } from "../../../shared/types";
import { PaginateRes } from "../../../shared/types/paginate";
import { Coverage } from "../../events/models/interfaces";
import { OperatorEvents, Score } from "../models";
import { CreateOperatorBody, UpdateOperatorBody } from "./requests.interfaces";
import { CallInfo } from "./response.interfaces";

const operatorService = globalApi.injectEndpoints({
  endpoints: (builder) => ({
    getOperators: builder.query<PaginateRes<Operator[]>, GetUsersParams>({
      query: (params) => {
        const { user } = store.getState().auth;
        const url =
          user?.role_name === "Empresa"
            ? usersRoutes.getCompanyOperatorsRT(user.company_id)
            : user?.role_name === "Cliente"
            ? usersRoutes.getClientOperatorsRT(user.client_id)
            : usersRoutes.getAllOperatorsRT();
        return { url, params };
      },
      transformResponse: (response: { data: PaginateRes<Operator[]> }) =>
        response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al obtener los operadores",
        });
      },
      providesTags: ["operators"],
    }),
    createOperator: builder.mutation<void, CreateOperatorBody>({
      query: ({ entity_id, role_name, ...body }) => {
        const url =
          role_name === "Empresa"
            ? usersRoutes.createCompanyOperatorRT(entity_id)
            : usersRoutes.createClientOperatorRT(entity_id);

        return {
          url,
          method: "POST",
          body,
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "email", "already exists")) {
            toast.warn("El correo electrónico ya está en uso");
          } else if (includesAll(message, "rfc", "already exists")) {
            toast.warn("El RFC ya está en uso");
          } else if (includesAll(message, "curp", "already exists")) {
            toast.warn("El CURP ya está en uso");
          } else {
            toast.error(
              "Ha ocurrido un error al crear el operador, intente nuevamente"
            );
          }
        };
        await handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Operador creado exitosamente",
        });
      },
      invalidatesTags: (_, error) => (error ? [] : ["operators"]),
    }),
    updateOperator: builder.mutation<void, UpdateOperatorBody>({
      query: ({ operator_id, ...body }) => ({
        url: usersRoutes.updateOperatorRT(operator_id),
        method: "PUT",
        body,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "email", "already exists")) {
            toast.warn("El correo electrónico ya está en uso");
          } else if (includesAll(message, "rfc", "already exists")) {
            toast.warn("El RFC ya está en uso");
          } else if (includesAll(message, "curp", "already exists")) {
            toast.warn("El CURP ya está en uso");
          } else {
            toast.error(
              "Ha ocurrido un error al actualizar los datos del operador, intente nuevamente"
            );
          }
        };
        await handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Operador actualizado exitosamente",
        });
      },
      invalidatesTags: (_, error) =>
        error ? [] : ["operators", "user details"],
    }),
    deleteOperator: builder.mutation<void, string>({
      query: (operator_id) => ({
        url: usersRoutes.deleteOperatorRT(operator_id),
        method: "DELETE",
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          successMessage: "Operador eliminado exitosamente",
          errorMessage:
            "Ha ocurrido un error al eliminar el operador, intente nuevamente",
        });
      },
      invalidatesTags: (_, error) => (error ? [] : ["operators"]),
    }),
    getOperatorStats: builder.query<OperatorEvents, string>({
      query: (operator_id) => usersRoutes.getOperatorStatsRT(operator_id),
      transformResponse: (response: { data: OperatorEvents }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(
          queryFulfilled,
          "Error al obtener los eventos del operador"
        );
      },
      providesTags: ["operator events"],
    }),
    getOperatorScore: builder.query<number, string>({
      query: (operator_id) =>
        usersRoutes.getUserScoreRT("operator", operator_id),
      transformResponse: (response: { data: Score }) => response.data.score,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(
          queryFulfilled,
          "Error al el puntaje del operador"
        );
      },
    }),
    getOperatorAffiliates: builder.query<Affiliate[], GetUsersParams>({
      query: (params) => {
        const operator = store.getState().auth.user as Operator;
        const url = operator.operator_clientId
          ? usersRoutes.getAllClientAffiliatesRT(
              operator.operator_clientId as string
            )
          : usersRoutes.getAllCompanyAffiliatesRT(
              operator.operator_companyId as string
            );
        return {
          url,
          params: {
            ...params,
            status: UserStatusEnum.ACTIVE,
          },
        };
      },
      transformResponse: (response: { data: Affiliate[] }) => response.data,
    }),
    getAffiliatesToCall: builder.query<
      PaginateRes<Affiliate[]>,
      GetUsersParams
    >({
      query: (params) => ({
        url: usersRoutes.getAffiliatesToCallRt(),
        params,
      }),
      transformResponse: (response: { data: PaginateRes<Affiliate[]> }) =>
        response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(
          queryFulfilled,
          "Error al obtener los afiliados del operador"
        );
      },
      providesTags: ["affiliates to call"],
    }),
    getOperatorCoverages: builder.query<Coverage[], void>({
      query: () => operatorRoutes.getOperatorCoveragesRT(),
      transformResponse: (response: { data: Coverage[] }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(
          queryFulfilled,
          "Error al obtener las coberturas del operador"
        );
      },
    }),
    getCallToken: builder.query<{ token: string }, void>({
      query: () => communicationRoutes.getCallTokenRT(),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(
          queryFulfilled,
          "Error al obtener el token de llamada, por favor recargue la página"
        );
      },
    }),
    getCallInfo: builder.query<CallInfo | undefined, string>({
      query: (call_id) => communicationRoutes.getCallInfoRT(call_id),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(
          queryFulfilled,
          "Error al obtener la información de la llamada, por favor recargue la página"
        );
      },
      keepUnusedDataFor: 0,
    }),
    getAllAffiliates: builder.query<Affiliate[], GetUsersParams>({
      query: (params) => {
        const url = usersRoutes.getAllAffiliates();
        return {
          url,
          params: {
            ...params,
            status: UserStatusEnum.ACTIVE,
          },
        };
      },
      transformResponse: (response: { data: Affiliate[] }) => response.data,
    }),
  }),
});

export const {
  useLazyGetCallInfoQuery: useGetCallInfo,
  useGetCallTokenQuery: useGetCallToken,
  useGetOperatorsQuery: useGetOperators,
  useCreateOperatorMutation: useCreateOperator,
  useUpdateOperatorMutation: useUpdateOperator,
  useDeleteOperatorMutation: useDeleteOperator,
  useGetOperatorScoreQuery: useGetOperatorScore,
  useGetOperatorStatsQuery: useGetOperatorStats,
  useGetAffiliatesToCallQuery: useGetAffiliatesToCall,
  useGetOperatorCoveragesQuery: useGetOperatorCoverages,
  useGetOperatorAffiliatesQuery: useGetOperatorAffiliates,
  useGetAllAffiliatesQuery: useGetAllAffiliates,
} = operatorService;
