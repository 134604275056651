import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0",
    background: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "1.875rem",
  },
  overlay: {
    background: "#C9C9C990",
  },
};

export interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  styles?: React.CSSProperties;
  className?: string;

  onRequestClose: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
}

export const MyModal = ({ children, styles, ...props }: ModalProps) => {
  return (
    <Modal
      ariaHideApp={false}
      closeTimeoutMS={150}
      style={{
        overlay: customStyles.overlay,
        content: {
          ...customStyles.content,
          ...styles,
        },
      }}
      {...props}
    >
      {children}
    </Modal>
  );
};
