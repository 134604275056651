import React, { Fragment } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaPlus, FaTimes, FaCheck } from "react-icons/fa";
import { Box } from "../../../../shared/components/styled";
import { Button } from "../../../../shared/components/ui/button";
import { If } from "../../../../shared/utilities/If";

interface Props {
  isEditMode: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isCreateMode: boolean;
  onCanceled: () => void;
  toggleIsEditMode: () => void;
  onCreateCoverages: () => void;
  onUpdatedCoverages: () => void;
  toggleIsCreateMode: () => void;
}

export const ManagerCoveragesActions = ({
  isEditMode,
  isCreating,
  isUpdating,
  isCreateMode,
  onCanceled,
  toggleIsEditMode,
  onCreateCoverages,
  onUpdatedCoverages,
  toggleIsCreateMode,
}: Props) => {
  return (
    <>
      <If showIf={!isCreateMode && !isEditMode}>
        <Box flex gap="1rem">
          <Button
            width="14rem"
            fontSize="1rem"
            loaderSize="1.3rem"
            text="Editar coberturas"
            loading={isUpdating}
            Icon={AiOutlineEdit}
            onClick={toggleIsEditMode}
          />
          <Button
            Icon={FaPlus}
            width="14rem"
            fontSize="1rem"
            text="Agregar cobertura"
            onClick={toggleIsCreateMode}
          />
        </Box>
      </If>
      <If showIf={isCreateMode}>
        <Box flex gap="1rem">
          <Button
            secondary
            Icon={FaTimes}
            fontSize="1rem"
            onClick={onCanceled}
            disabled={isCreating}
            text="Cancelar"
          />
          <Button
            Icon={FaCheck}
            fontSize="1rem"
            loading={isCreating}
            text="Guardar cambios"
            width="13.375rem"
            onClick={onCreateCoverages}
          />
        </Box>
      </If>
      <If showIf={isEditMode}>
        <Box flex gap="1rem">
          <Button
            secondary
            Icon={FaTimes}
            fontSize="1rem"
            onClick={toggleIsEditMode}
          >
            Cancelar
          </Button>
          <Button
            Icon={FaCheck}
            fontSize="1rem"
            width="13.375rem"
            loading={isUpdating}
            onClick={onUpdatedCoverages}
          >
            Guardar cambios
          </Button>
        </Box>
      </If>
    </>
  );
};
