import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { clientRoutes, globalApi, usersRoutes } from "../../../redux/rtk-query";
import { handleServiceRequest, includesAll } from "../../../shared/helpers";
import { ErrorResponse, GetUsersParams } from "../../../shared/interfaces";
import { Client } from "../../../shared/types";
import {
  CreateAdminClientBody,
  CreateSubscriptionBody,
  UpdateAdminClientBody,
  UpdateSubscriptionBody,
} from "./requests.interfaces";
import { PaginateRes } from "../../../shared/types/paginate";
import {
  ClientAffiliate,
  ClientPlansResponse,
  ClientProduct,
} from "./responses.interfaces";
import store from "../../../redux/store";

const adminClientService = globalApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminClients: builder.query<PaginateRes<Client[]>, GetUsersParams>({
      query: (params) => ({
        url: usersRoutes.getAdminClientsRT(),
        params,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        handleServiceRequest(
          queryFulfilled,
          "Error al obtener los clientes, intente nuevamente"
        );
      },
      transformResponse: (response: { data: PaginateRes<Client[]> }) =>
        response.data,
      providesTags: ["client-companies"],
    }),
    createAdminClient: builder.mutation<void, CreateAdminClientBody>({
      query: ({ manager_id, ...body }) => ({
        url: usersRoutes.createAdminClientRT(manager_id),
        method: "POST",
        body,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "email", "already exists")) {
            toast.warn("El correo ya se encuentra registrado");
          } else if (
            includesAll(message, "the code has already been registered")
          ) {
            toast.warn("El código ya se encuentra registrado");
          } else if (includesAll(message, "rfc", "already exists")) {
            toast.warn("El RFC ya se encuentra registrado");
          } else {
            toast.error("Error al crear el cliente, intente nuevamente");
          }
        };
        handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Cliente creado correctamente",
        });
      },
      invalidatesTags: (_, error) => (error ? [] : ["client-companies"]),
    }),
    updateAdminClient: builder.mutation<void, UpdateAdminClientBody>({
      query: ({ client_id, ...body }) => ({
        url: usersRoutes.updateAdminClientRT(client_id),
        method: "PUT",
        body,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "email", "already exists")) {
            toast.warn("El correo ya se encuentra registrado");
          } else if (includesAll(message, "rfc", "already exists")) {
            toast.warn("El RFC ya se encuentra registrado");
          } else {
            toast.error(
              "Error al actualizar los datos del cliente, intente nuevamente"
            );
          }
        };
        handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Cliente actualizado correctamente",
        });
      },
      invalidatesTags: (_, error) =>
        error ? [] : ["client-companies", "user details"],
    }),
    deleteAdminClient: builder.mutation<void, string>({
      query: (client_id) => ({
        url: usersRoutes.deleteAdminClientRT(client_id),
        method: "DELETE",
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        handleServiceRequest(queryFulfilled, {
          successMessage: "Cliente eliminado correctamente",
          errorMessage: "Error al eliminar el cliente, intente nuevamente",
        });
      },
      invalidatesTags: (_, error) => (error ? [] : ["client-admins"]),
    }),
    getClientSubscriptions: builder.query<ClientProduct[], string>({
      query: (client_id) => ({
        url: clientRoutes.getClientSubscriptionsRT(client_id),
        params: {
          limit: 100,
        },
      }),
      transformResponse: (response: ClientPlansResponse) =>
        response.data.products,
      onQueryStarted: async (_, { queryFulfilled }) => {
        handleServiceRequest(
          queryFulfilled,
          "Error al obtener las suscripciones del cliente, intente nuevamente"
        );
      },
      providesTags: ["client subscriptions"],
    }),
    createClientSubscription: builder.mutation<void, CreateSubscriptionBody>({
      query: (body) => {
        const { client_id: clientId } = store.getState().auth.user as Client;
        return {
          url: clientRoutes.createClientSubscriptionRT(clientId),
          method: "POST",
          body,
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onSuccess = () => {
          Swal.fire({
            title: "Suscripción creada correctamente",
            text: "Los cambios se verán reflejados en los próximos minutos",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        };
        handleServiceRequest(queryFulfilled, {
          onSuccess,
          errorMessage: "Error al crear la suscripción, intente nuevamente",
        });
      },
      invalidatesTags: (_, error) => (error ? [] : ["client subscriptions"]),
    }),
    updateClientSubscription: builder.mutation<void, UpdateSubscriptionBody>({
      query: ({ subscriptionId, ...body }) => ({
        url: clientRoutes.updateClientSubscriptionRT(subscriptionId),
        method: "PUT",
        body,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        handleServiceRequest(queryFulfilled, {
          successMessage: "Plan actualizado correctamente",
          errorMessage: "Error al actualizar el plan, intente nuevamente",
        });
      },
      invalidatesTags: (_, error) => (error ? [] : ["client subscriptions"]),
    }),
    getAdminClientAffiliates: builder.query<ClientAffiliate[], string>({
      query: (id) => clientRoutes.getAdminClientAffiliatesRT(id),
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ statusCode }: ErrorResponse) => {
          if (statusCode === 404) return [];
          toast.error("Error al obtener los afiliados, intente nuevamente");
        };
        handleServiceRequest(queryFulfilled, {
          onError,
        });
      },
      transformResponse: (response: { data: ClientAffiliate[] }) =>
        response.data,
    }),
  }),
});

export const {
  useGetAdminClientsQuery: useGetAdminClients,
  useCreateAdminClientMutation: useCreateAdminClient,
  useUpdateAdminClientMutation: useUpdateAdminClient,
  useDeleteAdminClientMutation: useDeleteAdminClient,
  useGetClientSubscriptionsQuery: useGetClientSubscriptions,
  useGetAdminClientAffiliatesQuery: useGetAdminClientAffiliates,
  useCreateClientSubscriptionMutation: useCreateClientSubscription,
  useUpdateClientSubscriptionMutation: useUpdateClientSubscription,
} = adminClientService;
