import { useCallback, useEffect, useMemo, useState } from "react";
import { RiMoreLine } from "react-icons/ri";
import { TiArrowSortedDown } from "react-icons/ti";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import * as T from "../../../../shared/components/styled/Table";
import { getDateWithFormat } from "../../../../shared/helpers";
import { useToggle } from "../../../../shared/hooks";
import { For } from "../../../../shared/utilities/For";
import { If } from "../../../../shared/utilities/If";
import {
  eventStepsIcons,
  getEventTypeInfo,
} from "../../helpers/getEventTypeInfo";
import { EventDetails } from "../../models/interfaces";
import * as S from "./EventsTable.styles";

dayjs.extend(duration);

interface Props {
  event: EventDetails;
  onRowClick: (event: EventDetails) => void;
}

export const EventTableRow = ({ event, onRowClick }: Props) => {
  const [showSteps, toggleShowSteps] = useToggle();
  const [activeTime, setActiveTime] = useState<string>();

  const eventInfo = useMemo(() => getEventTypeInfo(event), [event]);

  const activeTimeFormat = useMemo(() => {
    const hour = `${getDateWithFormat(activeTime, "HH")} hr`;
    const minutes = `${getDateWithFormat(activeTime, "mm")} min`;
    const seconds = `${getDateWithFormat(activeTime, "ss")} seg`;
    return { hour, minutes, seconds };
  }, [activeTime]);

  const handleRowClick = () => {
    onRowClick(event);
  };

  const getActiveTime = useCallback(() => {
    const created_at = dayjs(event.created_at);
    const date_now = dayjs();
    const diff = date_now.diff(created_at, "second");
    const duration = dayjs.duration(diff, "seconds");
    setActiveTime(duration.format());
  }, [event.created_at]);

  useEffect(() => {
    getActiveTime();
    const interval = setInterval(() => {
      getActiveTime();
    }, 2500);
    return () => clearInterval(interval);
  }, [getActiveTime]);

  return (
    <T.TableRow extraStyles={showSteps ? S.EventRowStyles : undefined}>
      <T.TableCell pointer onClick={handleRowClick}>
        {event.ticket?.slice(0, 10).toUpperCase()}
      </T.TableCell>
      <T.TableCell pointer onClick={handleRowClick}>
        {event.service_name}
      </T.TableCell>
      <T.TableCell>
        {event.affiliate_fullname} {event.affiliate_surname}
      </T.TableCell>
      <T.TableCell>{event.supplier_fullname || "Sin asignar"}</T.TableCell>
      <T.TableCell extraStyles={S.EventTimeCell}>
        <span>{activeTimeFormat.hour}</span>
        <span>{activeTimeFormat.minutes}</span>
        <span>{activeTimeFormat.seconds}</span>
      </T.TableCell>
      <T.TableCell>
        <S.EventStatus
          color={eventInfo.color}
          showSteps={showSteps}
          onClick={toggleShowSteps}
        >
          <span>{eventInfo.text}</span>
          <TiArrowSortedDown />
        </S.EventStatus>
      </T.TableCell>
      <If showIf={showSteps}>
        <S.EventSteps>
          <For
            each={Object.values(eventStepsIcons)}
            render={(Icon, i) => (
              <>
                <S.EventStep actualStep={eventInfo.step} index={i + 1}>
                  <Icon />
                </S.EventStep>
                <div className="separator-container">
                  <RiMoreLine />
                  <RiMoreLine />
                </div>
              </>
            )}
          />
        </S.EventSteps>
      </If>
    </T.TableRow>
  );
};
