import React, { useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { For } from "../../../../shared/utilities/For";
import {
  DatabaseValidationError,
  JoiValidationError,
  ValidationError,
} from "../../services/responses.interfaces";
import * as S from "./AffiliatesExcelErrorsList.styles";
import { If } from "../../../../shared/utilities/If";
import { Box } from "../../../../shared/components/styled";

interface Props {
  errors: (JoiValidationError | DatabaseValidationError)[];
  type?: "joi" | "database";
}

enum ErrorType {
  NACIMIENTO = "nacimiento",
  CORREO = "correo",
  NOMBRE = "nombre",
  APELLIDO = "apellido",
  INDICATIVO = "indicativo",
  TELEFONO = "telefono",
}

export const AffiliatesExcelErrorsList = ({ errors, type = "joi" }: Props) => {
  const [itemOpen, setItemOpen] = useState(-1);

  const handleItemClick = (index: number) => {
    setItemOpen(index);
  };

  const getMessageError = (value: string) => {
    if (value.includes("was found but is not a affiliate")) {
      const getMail = value.split(" ")[2];
      return `Se ha encontrado el usuario ${getMail} pero no es un afiliado`;
    }
    return value;
  };

  return (
    <div>
      <p>
        Se encontraron errores en el archivo, por favor corrija los siguientes
        errores y vuelva a intentarlo
      </p>
      <S.ErrorsList>
        <For
          each={errors}
          render={(error, index) => (
            <S.ErrorItemAccordion
              isOpen={index === itemOpen}
              onClick={() => handleItemClick(index)}
            >
              {typeof error === "string" ? (
                getMessageError(error)
              ) : (
                <>
                  <Box flex>
                    <TiArrowSortedDown />
                    <p>Fila {index + 2}</p>
                  </Box>
                  <If showIf={index === itemOpen}>
                    <S.ErrorItemList>
                      <For
                        each={error.errors}
                        render={(errorEl, indexEl) => (
                          <li>
                            {indexEl + 1}.{" "}
                            {type === "joi"
                              ? `${errorEl.path[1]} - Invalido`
                              : errorEl.message}
                          </li>
                        )}
                      />
                    </S.ErrorItemList>
                  </If>
                </>
              )}
            </S.ErrorItemAccordion>
          )}
        />
      </S.ErrorsList>
    </div>
  );
};
