import { useCallback, useEffect, useMemo, useState } from "react";
import { Map } from "../../../../shared/components/map/Map";
import CustomMarker from "../../../../shared/components/marker/Marker";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { EventDetails } from "../../models/interfaces";
import { WorkerAddress } from "../../services/responses.interfaces";
import { getAddressFromLatLng } from "../../services";

interface Props {
  event: EventDetails;
  workerPosition?: google.maps.LatLngLiteral;
}

export const EventMap = ({ event, workerPosition }: Props) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [workerAddress, setWorkerAddress] = useState<WorkerAddress>();

  const affiliateLocation = useMemo(
    () => ({ lat: +event.attention_latitude, lng: +event.attention_longitude }),
    [event.attention_latitude, event.attention_longitude]
  );

  const providerLocation = useMemo(() => {
    if (!event.initial_vehicle_latitude || !event.initial_vehicle_longitude)
      return null;
    return {
      lat: +event.initial_vehicle_latitude,
      lng: +event.initial_vehicle_longitude,
    };
  }, [event.initial_vehicle_latitude, event.initial_vehicle_longitude]);

  const onMapLoad = useCallback((mapLoaded: google.maps.Map) => {
    setMap(mapLoaded);
  }, []);

  const onMapUnmount = useCallback(() => {
    setMap(undefined);
  }, []);

  useEffect(() => {
    if (!map) return;
    if (providerLocation) {
      const bounds = new window.google.maps.LatLngBounds(affiliateLocation);
      bounds.extend(
        workerPosition?.lat && workerPosition.lng
          ? { lat: workerPosition.lat, lng: workerPosition.lng }
          : providerLocation
      );
      map.fitBounds(bounds);
      getAddressFromLatLng(
        providerLocation.lat.toString(),
        providerLocation.lng.toString()
      ).then((address: string) => {
        setWorkerAddress({
          address: `${address}`,
          lat: providerLocation.lat.toString(),
          lng: providerLocation.lng.toString(),
        });
      });
    } else {
      map.panTo(affiliateLocation);
      map.setZoom(16);
    }
  }, [
    map,
    providerLocation,
    affiliateLocation,
    workerPosition?.lat,
    workerPosition?.lng,
  ]);

  return (
    <GlobalContentBox contentStyle={{ padding: ".6rem" }}>
      <Map
        onLoaded={onMapLoad}
        onUnmounted={onMapUnmount}
        center={affiliateLocation}
        height="30rem"
      >
        {map && (
          <CustomMarker
            map={map}
            name={event.affiliate_fullname}
            position={affiliateLocation}
            img={event.affiliate_photography}
          />
        )}
        {providerLocation && map && (
          <CustomMarker
            map={map}
            img={event.worker_photography}
            position={workerPosition || providerLocation}
            name={event.worker_fullname || "Proveedor"}
            address={workerAddress?.address ?? ""}
          />
        )}
      </Map>
    </GlobalContentBox>
  );
};
