import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setServiceText,
  setStatusColor,
} from "../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../redux/store";
import { imgUrl } from "../../../../shared/components/navbar";
import {
  Box,
  PageContainer,
  PageTitle,
} from "../../../../shared/components/styled";
import { Button } from "../../../../shared/components/ui/button";
import { MyLoader } from "../../../../shared/components/ui/Loader";
import { colors } from "../../../../shared/theme";
import { If } from "../../../../shared/utilities/If";
import { EventMap } from "../../components/event-map/EventMap";
import { EventPeople } from "../../components/event-people";
import {
  AutomaticEventRecords,
  EventRecords,
  OperatorEventRecords,
} from "../../components/event-records";
import { EventStatus } from "../../components/events-table/EventsTable.styles";
import { AffiliateMedicalRecord } from "../../components/modals/affiliate-medical-record";
import { CancelEventModal } from "../../components/modals/cancel-event";
import { OperatorChatModal } from "../../components/modals/operator-chat";
import { useEventsSocketCtx } from "../../context/events-socket-ctx";
import { getEventTypeInfo } from "../../helpers";
import { useGetAffiliateChat, useGetSupplierChat } from "../../hooks";

export const EventDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { eventId } = useParams() as { eventId: string };

  const {
    activeEvent,
    workerPosition,
    getEventById,
    cancelEvent,
    leaveEventRoom,
  } = useEventsSocketCtx();

  const {
    socket,
    messages: clientMessages,
    sendOperatorMessage: sendMessageToClient,
    completeChat: completeClientChat,
  } = useGetAffiliateChat(activeEvent);

  const {
    messages: supplierMessages,
    sendOperatorMessage: sendMessageToSupplier,
    completeChat: completeSupplierChat,
  } = useGetSupplierChat(activeEvent, socket);

  const [isCancelingEvent, setIsCancelingEvent] = useState(false);
  const [isClientChatOpen, setIsClientChatOpen] = useState(false);
  const [isSupplierChatOpen, setIsSupplierChatOpen] = useState(false);
  const [isCancelEventModal, setIsCancelEventModal] = useState(false);
  const [isAffiliateMedicalRecordModal, setIsAffiliateMedicalRecordModal] =
    useState(false);

  const eventInfo = useMemo(
    () => activeEvent && getEventTypeInfo(activeEvent),
    [activeEvent]
  );

  const showCancelBtn = useMemo(
    () => activeEvent?.status === "Nuevo" || activeEvent?.status === "Iniciado",
    [activeEvent?.status]
  );

  const setCancelEventModal = (value: boolean) => {
    return () => setIsCancelEventModal(value);
  };

  const setClientChat = (value: boolean) => {
    return () => setIsClientChatOpen(value);
  };

  const setSupplierChat = (value: boolean) => {
    return () => setIsSupplierChatOpen(value);
  };

  const setAffiliateMedicalRecordModal = (value: boolean) => {
    return () => setIsAffiliateMedicalRecordModal(value);
  };

  const onEventCanceled = React.useCallback(() => {
    setIsCancelEventModal(false);
    completeClientChat();
    completeSupplierChat();
    toast.success("Evento cancelado");
  }, [completeClientChat, completeSupplierChat]);

  const onCancelEvent = React.useCallback(() => {
    setIsCancelingEvent(true);
    cancelEvent(
      eventId,
      onEventCanceled,
      () => toast.error("No se pudo cancelar el evento"),
      () => setIsCancelingEvent(false)
    );
  }, [cancelEvent, eventId, onEventCanceled]);

  useEffect(() => {
    getEventById(eventId);
  }, [getEventById, eventId]);

  useEffect(() => {
    if (!activeEvent?.service_name) return;
    const color =
      activeEvent?.service_name === "Seguridad"
        ? colors.security_blue
        : colors.red;
    dispatch(setStatusColor(color));
    dispatch(setServiceText(activeEvent?.service_name));
  }, [activeEvent?.service_description, activeEvent?.service_name, dispatch]);

  useEffect(
    () => () => {
      dispatch(setServiceText(undefined));
      dispatch(setStatusColor(colors.green.primary));
      leaveEventRoom(eventId);
    },
    [dispatch, leaveEventRoom, eventId]
  );

  if (!activeEvent) return <MyLoader />;

  return (
    <PageContainer>
      <Box flex justifyBetween>
        <PageTitle>
          Ticket: {activeEvent.ticket.slice(0, 10).toUpperCase()}
        </PageTitle>
        <EventStatus color={eventInfo?.color}>{eventInfo?.text}</EventStatus>
      </Box>

      <EventPeople
        event={activeEvent}
        onOpenClientChat={setClientChat(true)}
        openSupplierChat={setSupplierChat(true)}
        onOpenAffiliateRecord={setAffiliateMedicalRecordModal(true)}
      />

      <EventMap
        event={activeEvent}
        workerPosition={
          workerPosition && {
            lat: +workerPosition.vehicle_latitude,
            lng: +workerPosition.vehicle_longitude,
          }
        }
      />
      <Box flex gap="1rem">
        <OperatorEventRecords
          eventId={eventId}
          eventStatus={activeEvent.status}
          operatorPqr={activeEvent.pqr_operator}
        />
        <EventRecords
          content={activeEvent.pqr_worker}
          title="Registros manuales del proveedor"
        />
        <AutomaticEventRecords event={activeEvent} />
      </Box>

      <If showIf={showCancelBtn}>
        <Button xl secondary onClick={setCancelEventModal(true)}>
          Cancelar evento
        </Button>
      </If>

      <CancelEventModal
        title="Cancelar solicitud"
        isOpen={isCancelEventModal}
        isLoading={isCancelingEvent}
        onCancelEvent={onCancelEvent}
        serviceName={activeEvent.service_name}
        closeModal={setCancelEventModal(false)}
      />

      <OperatorChatModal
        messages={clientMessages}
        isOpen={isClientChatOpen}
        eventStatus={activeEvent.status}
        onSendMessage={sendMessageToClient}
        closeModal={setClientChat(false)}
        operatorImg={activeEvent.operator_photography || imgUrl}
        userImg={activeEvent.affiliate_photography || imgUrl}
      />

      <If showIf={!!activeEvent?.supplier_id}>
        <OperatorChatModal
          messages={supplierMessages}
          isOpen={isSupplierChatOpen}
          eventStatus={activeEvent.status}
          onSendMessage={sendMessageToSupplier}
          closeModal={setSupplierChat(false)}
          operatorImg={activeEvent.operator_photography || imgUrl}
          userImg={activeEvent.supplier_photography || imgUrl}
        />
      </If>

      <AffiliateMedicalRecord
        affiliateId={activeEvent.affiliate_id}
        isOpen={isAffiliateMedicalRecordModal}
        closeModal={setAffiliateMedicalRecordModal(false)}
      />
    </PageContainer>
  );
};
