import React from "react";
import { useSelector } from "react-redux";
import {
  setIsDeleteAdminClientModal,
  selectIsDeleteAdminClientModal,
} from "../../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../../redux/store";
import { Box } from "../../../../../shared/components/styled";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { useAdminClientDetailsCtx } from "../../../context/admin-client-details-ctx";

import { useDeleteAdminClient } from "../../../services";
import { useMyNavigate } from "../../../../../shared/hooks";

export const DeleteAdminClientModal = () => {
  const navigate = useMyNavigate();
  const dispatch = useAppDispatch();
  const isOpen = useSelector(selectIsDeleteAdminClientModal);
  const { adminClient } = useAdminClientDetailsCtx();

  const [deleteClient, { isLoading: isDeleting }] = useDeleteAdminClient();

  const onClose = () => {
    dispatch(setIsDeleteAdminClientModal(false));
  };

  const onDelete = () => {
    adminClient &&
      deleteClient(adminClient.client_id)
        .unwrap()
        .then(() => {
          onClose();
          navigate({ name: "Clientes" });
        });
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title="Eliminar Cliente"
    >
      <Box fontSize="1rem" style={{ fontWeight: 500, textAlign: "center" }}>
        {`¿Desea eliminar al cliente ${adminClient?.user_fullname}?`}
      </Box>
      <PageButtons
        show
        onCancel={onClose}
        isLoading={isDeleting}
        onProceed={onDelete}
        proceedText="Si, eliminar"
      />
    </BaseModal>
  );
};
