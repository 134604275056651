import * as T from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { TablePagination } from "../../../../shared/components/ui/table-title";
import { Operator } from "../../../../shared/types";
import { TableWithPaginationProps } from "../../../../shared/types/paginate";
import { For } from "../../../../shared/utilities/For";
import { OperatorsTableRow } from "./OperatorsTableRow";

const titles = [
  "Nombre Completo",
  "Fecha de nacimiento",
  "Número de teléfono",
  "Correo electrónico",
  "Acciones",
];

const columns = "repeat(4, 1fr) .5fr";
interface Props extends TableWithPaginationProps {
  operators: Operator[];
}

export const OperatorsTable = ({
  isLoading,
  operators,
  pageCount,
  onPageChange,
}: Props) => {
  const pagination = (
    <TablePagination
      pageCount={pageCount}
      onPageChange={onPageChange}
      title="Listado de operadores"
    />
  );

  return (
    <GlobalContentBox title={pagination} isLoading={isLoading}>
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={titles}
            render={(title) => <T.TableTitle>{title}</T.TableTitle>}
          />
        </T.TableTitlesBox>

        <T.TableBody gridColumns={columns}>
          <For
            each={operators}
            render={(operator) => <OperatorsTableRow operator={operator} />}
          />
        </T.TableBody>
      </T.Table>
    </GlobalContentBox>
  );
};
