import { createContext, useContext } from "react";
import { Supplier } from "../../../../shared/types";

interface IProviderDetailsCtx {
  provider?: Supplier;
  isEditMode: boolean;
  toggleEditMode: () => void;
  formRef: React.RefObject<any>;
}

export const ProviderDetailsCtx = createContext({} as IProviderDetailsCtx);

export const useProviderDetailsCtx = () => useContext(ProviderDetailsCtx);
