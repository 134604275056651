import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { MyInput } from "../../../../shared/components/inputs";
import { Button } from "../../../../shared/components/ui/button";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { GetUsersParams } from "../../../../shared/interfaces";
import { ReactSetter } from "../../../../shared/types";
import { UserSearchFormProps, UserSearchFormShape } from "../../schemas";
import * as S from "./ClientSearch.styles";

type keys = keyof UserSearchFormProps;

interface Props {
  isLoading: boolean;
  setClientFilters: ReactSetter<GetUsersParams>;
}

export const ClientSearch = ({ isLoading, setClientFilters }: Props) => {
  const methods = useForm<UserSearchFormProps>({
    resolver: yupResolver(UserSearchFormShape),
  });
  const hasErrors = !!methods.formState.errors.email;

  const onSubmit = (data: UserSearchFormProps) => {
    console.log(data);
    setClientFilters((prev) => ({
      ...prev,
      ...data,
    }));
  };
  return (
    <GlobalContentBox title="Búsqueda de empresas">
      <S.UserSearchForm
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
        hasErrors={hasErrors}
      >
        <MyInput<keys>
          name="fullname"
          label="Nombre"
          placeholder="Ingresa un nombre"
        />
        <MyInput<keys>
          name="phone"
          label="Número de teléfono"
          placeholder="Ingresa un teléfono"
        />
        <MyInput<keys>
          name="email"
          label="Correo electrónico"
          placeholder="Ingresa un correo electrónico"
        />
        <Button animate loading={isLoading}>
          Buscar
        </Button>
      </S.UserSearchForm>
    </GlobalContentBox>
  );
};
