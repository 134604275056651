import React from "react";
import { MdWifiCalling3, MdCallEnd } from "react-icons/md";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import * as S from "../calling-affiliate-modal/CallingAffiliateModal.styles";
import { If } from "../../../../../shared/utilities/If";
import { Button } from "../../../../../shared/components/ui/button";
import { CallInList } from "../../../models";

interface Props {
  isOpen: boolean;
  isCalling: boolean;
  activeTicket?: CallInList;
  isGettingCallInfo: boolean;

  onHangUp: () => void;
  afterClose: () => void;
  onCall: (number: string) => void;
  onRequestClose: () => void;
}

export const TicketInCallModal = ({
  isOpen,
  isCalling,
  activeTicket,
  isGettingCallInfo,
  onHangUp,
  afterClose,
  onCall,
  onRequestClose,
}: Props) => {
  const CallIcon = isCalling ? MdWifiCalling3 : MdCallEnd;

  const title = isCalling
    ? `En llamada con ${activeTicket?.name}`
    : `Llamada con ${activeTicket?.name} finalizada`;

  const onCallAgain = () => {
    if (!activeTicket) return;
    onCall(activeTicket.cellphone);
  };

  return (
    <BaseModal
      title={title}
      onlyCloseOnButton
      isOpen={isOpen}
      onAfterClose={afterClose}
      onRequestClose={onRequestClose}
    >
      <S.CallingModalWrapper>
        <CallIcon />
        <If showIf={isCalling}>
          <Button
            text="Colgar"
            xl
            width="100%"
            onClick={onHangUp}
            loading={isGettingCallInfo}
          />
        </If>
        <If showIf={!isCalling}>
          <Button
            onClick={onCallAgain}
            text="Llamar nuevamente"
            secondary
            xl
            width="100%"
          />
        </If>
      </S.CallingModalWrapper>
    </BaseModal>
  );
};
