import { LoginPage } from "../pages/Login";
import { RecoverPasswordPage } from "../pages/recover-password";

const AuthRoutes = [
  {
    path: "/login",
    name: "Login",
    Component: () => <LoginPage />,
  },
  {
    path: "/recover-password",
    name: "Recuperar contraseña",
    Component: () => <RecoverPasswordPage />,
  },
] as const;

export { AuthRoutes };
