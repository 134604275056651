import { SVGProps } from "react";

export const ProviderSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m11 7.941-1.578-.788a2 2 0 0 0-.894-.212h-.7a2 2 0 0 0-1.414.586l-.828.829A2 2 0 0 0 5 9.77v5.101a2 2 0 0 0 .89 1.664l3.867 2.578a2 2 0 0 0 2.39-.128L17 14.941"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.455 12.856a2 2 0 0 0-2.59-.543l-1.856 1.055a1.523 1.523 0 0 1-1.728-2.494l4.163-3.469a2 2 0 0 1 1.28-.464h.804a2 2 0 0 1 .894.212l2.473 1.236A2 2 0 0 1 19 10.178v2.743c0 1.924-2.45 2.738-3.602 1.197l-.943-1.262ZM1 16.941v-9M1 16.941v-9M23 16.941v-9"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
