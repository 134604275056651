import { ValueOf } from "../types";

export const UserRoleEnum = {
  MANAGER: "Gerente",
  COMPANY: "Empresa",
  AFFILIATE: "Afiliado",
  OPERATOR: "Operador",
  SUPPLIER: "Proveedor",
  WORKER: "Trabajador",
  CLIENT: "Cliente",
} as const;

export enum UserAvailabilityEnum {
  AVAILABLE = "Disponible",
  OCCUPIED = "Ocupado",
  OFFLINE = "Fuera de linea",
  DISCONNECTED = "Desconectado",
}

export enum EntityNameEnum {
  COMPANY = "company",
  AFFILIATE = "affiliate",
  OPERATOR = "operator",
  SUPPLIER = "supplier",
  WORKER = "worker",
  CLIENT = "client",
}

export enum UserStatusEnum {
  ACTIVE = "Activo",
  INACTIVE = "Inactivo",
  SUSPENDED = "Suspendido",
}

export enum EventStatusEnum {
  NEW = "Nuevo",
  STARTED = "Iniciado",
  SCHEDULED = "Programado",
  CANCELLED = "Cancelado",
  FINISHED = "Finalizado",
}

export type UserRole = ValueOf<typeof UserRoleEnum>;
export type UserStatus = `${UserStatusEnum}`;
export type EntityName = `${EntityNameEnum}`;
export type EventStatus = `${EventStatusEnum}`;
export type UserAvailability = `${UserAvailabilityEnum}`;
