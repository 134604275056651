import styled from "styled-components";
import { Form } from "../../../../shared/components/form";

export const ClientInfoFormWrapper = styled(Form)<{ isEditMode?: boolean }>`
  width: 100%;
  display: grid;
  align-items: center;
  gap: 1.5rem;

  grid-template-columns: ${({ isEditMode }) =>
    isEditMode ? "1.5fr repeat(3, 1fr)" : "repeat(4, 1fr)"};

  > * {
    align-self: flex-start;
  }

  .input-container,
  .select-container {
    height: 2.6rem;
  }

  .phone-input,
  .email-input {
    .input-icon-right {
      color: ${({ theme }) => theme.colors.green.primary};
    }
  }

  .card-input {
    .input-icon-right {
      color: ${({ theme }) => theme.colors.gray.primary};
    }
  }
`;
