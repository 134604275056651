import styled from "styled-components";

export const EventPeopleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .global-content-box-wrapper {
    width: 100%;
  }

  .global-content-box-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .info-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 0.5rem;

    h3 {
      font-weight: 400;
    }

    span {
      font-weight: 600;
    }
  }

  .provider-not-assigned {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray.primary};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  button {
    width: 100%;
    /* font-size: 1rem; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }
`;

export const EntityInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > img {
    width: 6.875rem;
    height: 6.875rem;
    border-radius: 1rem;
  }
`;
