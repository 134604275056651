import styled from "styled-components";

export const NotAllowedPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  h2 {
    font-size: 2rem;
    font-weight: 500;
  }
`;
