import { Device } from "@twilio/voice-sdk";
import { useEffect, useMemo, useState } from "react";
import { imgUrl } from "../../../../shared/components/navbar";
import { Button } from "../../../../shared/components/ui/button";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import {
  getDateWithFormat,
  getPhoneWithFormat,
} from "../../../../shared/helpers";
import { If } from "../../../../shared/utilities/If";
import { useGetCallToken } from "../../../operators/services";
import { EventDetails } from "../../models/interfaces";
import { CallingUserEventModal } from "../modals/calling-user-event";
import * as S from "./EventPeople.styles";
import { WorkerAddress } from "../../services/responses.interfaces";
import { getAddressFromLatLng } from "../../services";

interface Props {
  event: EventDetails;
  onOpenClientChat: () => void;
  openSupplierChat: () => void;
  onOpenAffiliateRecord: () => void;
}

export const EventPeople = ({
  event,
  onOpenClientChat,
  onOpenAffiliateRecord,
  openSupplierChat,
}: Props) => {
  const [device, setDevice] = useState<Device>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workerAddress, setWorkerAddress] = useState<WorkerAddress>();

  const { data } = useGetCallToken();

  const affiliateImg = useMemo(
    () => event.affiliate_photography || imgUrl,
    [event.affiliate_photography]
  );
  const workerImg = useMemo(
    () => event.worker_photography || imgUrl,
    [event.worker_photography]
  );

  const onCall = (phoneNumber: string) => {
    setIsModalOpen(true);
    device?.connect({
      params: { phoneNumber },
    });
  };

  const onHangUp = () => {
    device?.disconnectAll();
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    onHangUp();
  };

  useEffect(() => {
    if (!data?.token) return;
    const device = new Device(data.token);
    setDevice(device);

    return () => {
      device.disconnectAll();
    };
  }, [data?.token]);

  const providerLocation = useMemo(() => {
    if (!event.initial_vehicle_latitude || !event.initial_vehicle_longitude)
      return null;
    return {
      lat: +event.initial_vehicle_latitude,
      lng: +event.initial_vehicle_longitude,
    };
  }, [event.initial_vehicle_latitude, event.initial_vehicle_longitude]);

  useEffect(() => {
    if (!providerLocation) return;
    getAddressFromLatLng(
      providerLocation.lat.toString(),
      providerLocation.lng.toString()
    ).then((address: string) => {
      setWorkerAddress({
        address: `${address}`,
        lat: providerLocation.lat.toString(),
        lng: providerLocation.lng.toString(),
      });
    });
  }, [providerLocation]);

  return (
    <S.EventPeopleWrapper>
      <GlobalContentBox title="Solicitante">
        <S.EntityInfoWrapper>
          <img src={affiliateImg} alt="profile" />
          <div className="info-container">
            <h3>
              Lugar de la emergencia: <span>{event.attention_location}</span>
            </h3>
            <h3>
              Nombre:{" "}
              <span>
                {event.affiliate_fullname} {event.affiliate_surname}
              </span>
            </h3>
            <h3>
              Correo: <span>{event.affiliate_email}</span>
            </h3>
            <h3>
              Teléfono: <span>{getPhoneWithFormat(event.affiliate_phone)}</span>
            </h3>
            <h3>
              Fecha de nacimiento:{" "}
              <span>{getDateWithFormat(event.affiliate_birthday)}</span>
            </h3>
          </div>
        </S.EntityInfoWrapper>
        <S.ButtonsContainer>
          <Button secondary onClick={onOpenAffiliateRecord}>
            Expediente medico
          </Button>
          <Button secondary onClick={onOpenClientChat}>
            Chat
          </Button>
          <Button onClick={() => onCall(event.affiliate_phone)}>Llamar</Button>
        </S.ButtonsContainer>
      </GlobalContentBox>

      <GlobalContentBox title="Proveedor" style={{ height: "100%" }}>
        <If showIf={!!event.worker_fullname}>
          <S.EntityInfoWrapper>
            <img src={workerImg} alt="profile" />
            <div className="info-container">
              <h3>
                Nombre:{" "}
                <span>
                  {event.worker_fullname} {event.worker_surname}
                </span>
              </h3>
              <h3>
                Telefono:{" "}
                <span>{getPhoneWithFormat(event.worker_phone ?? "")}</span>
              </h3>
              <h3>
                Unidad: <span>{event.vehicle_license_plate}</span>
              </h3>
              <h3>
                Lugar:{" "}
                <span>
                  {workerAddress?.address ?? event.initial_vehicle_location}
                </span>
              </h3>
              <h3>
                Proveedor: <span>{event.supplier_fullname}</span>
              </h3>
            </div>
          </S.EntityInfoWrapper>
          <S.ButtonsContainer>
            <Button secondary onClick={openSupplierChat}>
              Chat
            </Button>
            <Button secondary onClick={() => onCall(event.worker_phone!)}>
              Llamar unidad
            </Button>
            {/* <Button>Usuario encontrado</Button> */}
          </S.ButtonsContainer>
        </If>
        <If showIf={!event.worker_fullname}>
          <div className="provider-not-assigned">
            El proveedor aun no ha sido asignado
          </div>
        </If>
      </GlobalContentBox>
      <CallingUserEventModal isOpen={isModalOpen} onClose={onCloseModal} />
    </S.EventPeopleWrapper>
  );
};
