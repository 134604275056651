import styled from "styled-components";

export const PrivateLayoutWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.colors.gray.lightest};

  display: flex;
  flex-direction: column;

  > nav,
  main {
    padding: 0 1.8rem;
  }

  > nav {
    padding-bottom: 1rem;
  }
`;

export const OutletWrapper = styled.main`
  overflow: auto;
  width: 100%;
  height: 100%;
`;
