import { AiOutlineStar } from "react-icons/ai";
import { BsStopwatch } from "react-icons/bs";
import { TbActivityHeartbeat } from "react-icons/tb";
import { EventStats } from "../../../../shared/components/stats/event-stats";
import { StatsItem } from "../../../../shared/components/stats/stats-item";
import { Box } from "../../../../shared/components/styled";
import { If } from "../../../../shared/utilities/If";
import { OperatorEvents } from "../../models";

interface DefaultProps {
  score?: number;
  isLoading?: boolean;
  showScore?: boolean;
  operatorEvents?: OperatorEvents;
}

export const OperatorStatsList = (props: DefaultProps) => {
  const columns = props.showScore ? 4 : 3;

  return (
    <Box as="ul" gap="1rem" grid columns={columns}>
      <StatsItem
        isLoading={props.isLoading}
        icon={<BsStopwatch />}
        title="Tiempo de atención promedio (Minutos)"
        value={props.operatorEvents?.average_attention_time_in_minutes.toFixed(
          0
        )}
      />
      <StatsItem
        isLoading={props.isLoading}
        title="Eventos atendidos"
        icon={<TbActivityHeartbeat />}
        value={props.operatorEvents?.events_attended}
      />
      <If showIf={props.showScore}>
        <StatsItem
          isLoading={props.isLoading}
          title="Evaluación"
          icon={<AiOutlineStar />}
          value={props.score}
        />
      </If>
      <EventStats
        width="70%"
        isLoading={props.isLoading}
        title="Tipos de eventos atendidos"
        events={props.operatorEvents?.types_of_events_attended}
      />
    </Box>
  );
};
