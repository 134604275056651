import * as Yup from "yup";
import {
  birthDateRequired,
  curpRequired,
  emailRequired,
  phoneNoCodeRequired,
  rfcRequired,
  stringOptional,
  stringRequired,
} from "../../../shared/schemas";

export const UpdateAffiliateFormShape = Yup.object({
  rfc: rfcRequired,
  curp: curpRequired,
  birthday: birthDateRequired,
  email: emailRequired,
  fullname: stringRequired,
  surname: stringRequired,
  complete_name: stringOptional,
  country_code: stringRequired,
  full_phone: stringOptional,
  phone: phoneNoCodeRequired,
});

export type UpdateAffiliateFormProps = Yup.InferType<
  typeof UpdateAffiliateFormShape
>;

/* ---------------------------------------------- */
