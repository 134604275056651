import styled from "styled-components";
import { TableCell } from "../../../../shared/components/styled";

export const OpenOptionsButton = styled(TableCell)`
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
