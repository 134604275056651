import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { PageButtons } from "../../../../shared/components/ui/page-buttons";
import { EventStatus } from "../../../../shared/enums";
import { useEventsSocketCtx } from "../../context/events-socket-ctx";
import { UpdateOperatorPqrBody } from "../../services/requests.interfaces";
import * as S from "./EventRecords.styles";

interface Props {
  eventId: string;
  eventStatus: EventStatus;
  operatorPqr: string | null;
}

export const OperatorEventRecords = ({
  operatorPqr,
  eventId,
  eventStatus,
}: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [pqrInput, setPqrInput] = useState<string>(operatorPqr || "");

  const { updateOperatorPqr } = useEventsSocketCtx();

  const onEdit = () => {
    if (
      isEditMode ||
      eventStatus === "Cancelado" ||
      eventStatus === "Finalizado"
    )
      return;
    setIsEditMode(true);
    setPqrInput(operatorPqr || "");
  };

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPqrInput(e.target.value);
  };

  const onUpdatePqr = async () => {
    if (pqrInput?.trim().length === 0) {
      toast.dismiss();
      toast.warning("Los registros no pueden estar vacíos");
      return;
    }
    const body: UpdateOperatorPqrBody = {
      eventId,
      pqr_operator: pqrInput,
    };
    setIsUpdating(true);
    updateOperatorPqr(
      body,
      () => setIsEditMode(false),
      () => toast.error("No se pudo actualizar los registros del operador"),
      () => setIsUpdating(false)
    );
  };

  const onCancel = () => {
    setIsEditMode(false);
    setPqrInput(operatorPqr || "");
  };

  useEffect(() => {
    if (eventStatus === "Cancelado" || eventStatus === "Finalizado") {
      setIsEditMode(false);
    }
  }, [eventStatus]);

  useEffect(() => {
    if (isEditMode) return;
    setPqrInput(operatorPqr || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorPqr]);

  return (
    <GlobalContentBox
      style={{ minHeight: "12rem", width: "100%" }}
      title="Registros manuales del operador"
    >
      <S.OperatorRecordsContainer isEditMode={isEditMode}>
        <S.OperatorRecordsField
          value={pqrInput}
          onClick={onEdit}
          readOnly={!isEditMode}
          onChange={onInputChange}
        />
        <PageButtons
          fullWidth
          xl={false}
          show={isEditMode}
          proceedText="Guardar"
          isLoading={isUpdating}
          onCancel={onCancel}
          onProceed={onUpdatePqr}
        />
      </S.OperatorRecordsContainer>
    </GlobalContentBox>
  );
};
