import React from "react";

type InitialValue = boolean | (() => boolean);

export const useToggle = (initialValue: InitialValue = false) => {
  const [state, setState] = React.useState(initialValue);
  const toggle = React.useCallback(
    () => setState((actualState) => !actualState),
    []
  );
  return [state, toggle, setState] as const;
};
