import { useLayoutEffect, useState, useEffect, useCallback } from "react";
import { io, Socket } from "socket.io-client";
import { setStatusText } from "../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../redux/store";
import { UserAvailability } from "../../../shared/enums";

enum SocketEvents {
  // EMITS
  USER_AVAILABILITY = "user_availability",

  // LISTENS
  CURRENT_AVAILABILITY = "current_availability",
}

const SOCKET_URL = process.env.REACT_APP_AVAILABILITY_SOCKET_URL || "";
const SOCKET_PATH = "/availabilities";

export const useAvailabilitySocket = (userId?: string) => {
  const dispatch = useAppDispatch();

  const [socket, setSocket] = useState<Socket>();

  // Function to set availability
  /* --------------------------------------------------------- */
  const setAvailability = useCallback(
    (availability: UserAvailability) => {
      const body = {
        userId,
        availability,
      };
      socket?.emit(SocketEvents.USER_AVAILABILITY, body, (res: unknown) => {
        console.log("setAvailability", res);
      });
    },
    [socket, userId]
  );
  /* --------------------------------------------------------- */

  // Connect to socket
  useLayoutEffect(() => {
    if (!userId) return;
    const authorization = `Bearer ${localStorage.getItem("access")}`;
    const mySocket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      transports: ["polling"],
      extraHeaders: { authorization },
    });
    setSocket(mySocket);
  }, [userId]);

  // Listen to availability
  useEffect(() => {
    if (!socket) return;
    setAvailability("Disponible");
    socket.on(
      SocketEvents.CURRENT_AVAILABILITY,
      (res: { availability: UserAvailability; userId: string }) => {
        dispatch(setStatusText(res.availability));
      }
    );
    return () => {
      socket.off(SocketEvents.CURRENT_AVAILABILITY);
    };
  }, [socket, setAvailability, dispatch]);

  return {
    setAvailability,
  };
};
