import styled, { css } from "styled-components";

export const ReportDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ReportSectionsList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
/* ------------------------------------------------------------- */

interface ReportSectionProps {
  active: boolean;
}

const activeSectionsStyle = css`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
`;

export const ReportSection = styled.li<ReportSectionProps>`
  padding: 0.5rem 0.7rem;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2rem;
  transition: all 0.1s linear;
  :hover {
    ${activeSectionsStyle}
  }
  ${({ active }) => active && activeSectionsStyle}
`;
/* ------------------------------------------------------------- */

export const ReportItem = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  :not(:last-child) {
    border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.gray.lightest};
  }
  h4 {
    font-weight: 500;
  }
  span {
    font-weight: 700;
  }

  svg {
    color: ${({ theme }) => theme.colors.alert};
  }
`;

/* ------------------------------------------------------------- */
