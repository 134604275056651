import dayjs from "dayjs";
import { useEffect, useState, useCallback } from "react";
import duration from "dayjs/plugin/duration";
import * as T from "../../../../../shared/components/styled/Table";
import { Button } from "../../../../../shared/components/ui/button";
import { CallInList } from "../../../models";

dayjs.extend(duration);

interface Props {
  ticket: CallInList;
  onCallTicket: (ticket: CallInList) => void;
}

export const CallingListRow = ({ ticket, onCallTicket }: Props) => {
  const [activeTime, setActiveTime] = useState<string>();

  const getActiveTime = useCallback(() => {
    const dateNow = dayjs();
    const minDiff = dateNow.diff(ticket.date, "m");
    const hourDiff = dateNow.diff(ticket.date, "h");
    setActiveTime(
      hourDiff > 0 ? `${hourDiff}h ${minDiff - hourDiff * 60}m` : `${minDiff}m`
    );
  }, [ticket.date]);

  useEffect(() => {
    getActiveTime();
    const interval = setInterval(() => {
      getActiveTime();
    }, 5000);
    return () => clearInterval(interval);
  }, [getActiveTime]);

  return (
    <T.TableRow>
      <T.TableCell>{ticket.number}</T.TableCell>
      <T.TableCell>{ticket.name}</T.TableCell>
      <T.TableCell>{activeTime}</T.TableCell>
      <T.TableCell>
        <Button
          animate
          padding="0.3rem 1.5rem"
          onClick={() => onCallTicket(ticket)}
        >
          Llamar
        </Button>
      </T.TableCell>
    </T.TableRow>
  );
};
