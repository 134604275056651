import styled from "styled-components";
import { Form } from "../../../../shared/components/form";
import { SearchComponentStyles } from "../../../../shared/styles";

export const ReportsSearchForm = styled(Form)`
  ${SearchComponentStyles}

  #ticket {
    text-transform: uppercase;
  }
`;
