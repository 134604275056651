import * as T from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { TablePagination } from "../../../../shared/components/ui/table-title";
import { Company } from "../../../../shared/types";
import { TableWithPaginationProps } from "../../../../shared/types/paginate";
import { For } from "../../../../shared/utilities/For";
import { ClientTableRow } from "./ClientsTableRow";

const titles = [
  "Contacto Principal",
  "RFC",
  "Número de teléfono",
  "Correo electrónico",
  "Estatus",
  "Acciones",
];
const columns = "repeat(5, 1fr) .5fr";

interface Props extends TableWithPaginationProps {
  clients: Company[];
}

export const ClientTable = ({
  clients,
  isLoading,
  pageCount,
  onPageChange,
}: Props) => {
  const pagination = (
    <TablePagination
      pageCount={pageCount}
      onPageChange={onPageChange}
      title="Listado de empresas"
    />
  );

  return (
    <GlobalContentBox title={pagination} isLoading={isLoading}>
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={titles}
            render={(title) => <T.TableTitle>{title}</T.TableTitle>}
          />
        </T.TableTitlesBox>
        <T.TableBody gridColumns={columns}>
          <For
            each={clients}
            render={(client) => <ClientTableRow client={client} />}
          />
        </T.TableBody>
      </T.Table>
    </GlobalContentBox>
  );
};
