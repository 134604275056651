import { PrivateRoutes } from "../../../../router/app.routes";
import { RouteProps } from "../../../../router/interfaces";
import { APP_VERSION } from "../../../constants";
import { For } from "../../../utilities/For";
import { SidebarItem } from "../sidebar-item/SidebarItem";
import * as S from "./SidebarList.styles";

const routesToShow = (PrivateRoutes as RouteProps[]).filter(
  (route) => route.showInMenu
);

export const SidebarList = () => {
  return (
    <S.SidebarListWrapper>
      <S.SidebarAppVersion>{APP_VERSION}</S.SidebarAppVersion>
      <For
        each={routesToShow}
        render={(route) => <SidebarItem route={route} />}
      />
    </S.SidebarListWrapper>
  );
};
