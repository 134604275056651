import { useRef } from "react";
import { useClickOutside } from "react-haiku";
import { setIsDeleteOperatorModal } from "../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../redux/store";
import { ActionsCellBox } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { UserDetailsCard } from "../../../../shared/components/user-info/user-details-card";
import { useToggle } from "../../../../shared/hooks";
import { ActionsCellStyles } from "../../../../shared/styles";
import { If } from "../../../../shared/utilities/If";
import { OpenOptionsButton } from "../../../users/components/client-details-card/ClientDetailsCard.styles";
import { useOperatorDetailsCtx } from "../../context/operator-details-ctx";
import { DeleteOperatorModal } from "../modals/delete-operator-modal";
import { OperatorInfoForm } from "../operator-info-form";

interface Props {
  score?: number;
}

export const OperatorDetailsCard = ({ score }: Props) => {
  const dispatch = useAppDispatch();
  const actionsRef = useRef<HTMLDivElement>(null);
  const [optionsOpen, toggleOptionsOpen, setOptionsOpen] = useToggle();
  const { toggleEditMode, isEditMode, formRef, operator } =
    useOperatorDetailsCtx();

  useClickOutside(actionsRef, () => setOptionsOpen(false));

  const onOpenDeleteModal = () => {
    dispatch(setIsDeleteOperatorModal(true));
    setOptionsOpen(false);
  };

  const editTitle =
    operator?.user_status !== "Suspendido" ? "Editar operador" : undefined;

  return (
    <GlobalContentBox style={{ position: "relative" }}>
      <UserDetailsCard
        score={score}
        toggleEditMode={toggleEditMode}
        profilePhoto={operator?.user_photography}
        editTitle={isEditMode ? undefined : editTitle}
      />
      <OperatorInfoForm ref={formRef} />
      <If showIf={isEditMode && !optionsOpen}>
        <OpenOptionsButton
          extraStyles={ActionsCellStyles}
          onClick={toggleOptionsOpen}
        >
          <div />
          <div />
          <div />
        </OpenOptionsButton>
      </If>
      <If showIf={isEditMode && optionsOpen}>
        <OpenOptionsButton extraStyles={ActionsCellStyles}>
          <div />
          <div />
          <div />
        </OpenOptionsButton>
      </If>
      <If showIf={isEditMode && optionsOpen}>
        <ActionsCellBox ref={actionsRef} top="2rem" right="1.5rem">
          <div onClick={onOpenDeleteModal}>Eliminar operador</div>
        </ActionsCellBox>
      </If>
      <DeleteOperatorModal operator={operator} />
    </GlobalContentBox>
  );
};
