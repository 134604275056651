import { useSelector } from "react-redux";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { validateUserRoute } from "../../modules/auth/helpers";
import {
  selectIsAuthenticated,
  selectIsValidating,
  selectUser,
} from "../../modules/auth/redux";
import * as S from "./PublicLayout.styles";

export const PublicLayout = () => {
  const [params] = useSearchParams();
  const user = useSelector(selectUser);
  const isValidating = useSelector(selectIsValidating);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (isValidating) {
    return null;
  }
  if (!isValidating && isAuthenticated) {
    const { path } = validateUserRoute(user?.role_name);
    const from = params.get("fr") || path;
    return <Navigate to={from} replace />;
  }

  return (
    <S.PublicLayoutContainer>
      <Outlet />
    </S.PublicLayoutContainer>
  );
};
