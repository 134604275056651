import { PolygonProps } from "@react-google-maps/api";
import React, { useCallback, useState } from "react";
import { useToggle } from "../../../../shared/hooks";
import { colors } from "../../../../shared/theme";
import { For } from "../../../../shared/utilities/For";
import { Coverage, Zone } from "../../../events/models/interfaces";
import {
  UpdateZoneBody,
  useDeleteProviderZone,
  useUpdateProviderZone,
} from "../../services";
import { DeleteProviderZoneModal } from "../modals/delete-provider-zone";
import { AreaItem } from "./AreaItem";

interface Props {
  zones: Zone[];
  editable?: boolean;
  canDelete?: boolean;
  coverages: Coverage[];
}

export const polygonOptions: PolygonProps["options"] = {
  strokeColor: colors.red,
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: colors.red,
  fillOpacity: 0.35,
  zIndex: 1,
};

export const CoverageAreas = ({
  zones,
  canDelete,
  editable,
  coverages,
}: Props) => {
  const [zoneId, setZoneId] = useState<string>();
  const [isDeleteMode, toggleDeleteMode] = useToggle();

  const [deleteZone, { isLoading: isDeleting }] = useDeleteProviderZone();
  const [updateZone] = useUpdateProviderZone({
    fixedCacheKey: "updateZone",
  });

  const afterModalClose = useCallback(() => {
    setZoneId(undefined);
  }, []);

  const onUpdateZone = useCallback(
    (zoneId: string, positions: google.maps.LatLngLiteral[]) => {
      const body: UpdateZoneBody = {
        zoneId,
        zone: positions,
      };
      updateZone(body).unwrap();
    },
    [updateZone]
  );

  const onSelectZone = useCallback(
    (zoneId: string) => {
      if (!canDelete) return;
      setZoneId(zoneId);
      toggleDeleteMode();
    },
    [canDelete, toggleDeleteMode]
  );

  const onDeleteZone = useCallback(() => {
    if (!zoneId) return;
    deleteZone(zoneId)
      .unwrap()
      .then(() => {
        toggleDeleteMode();
        setZoneId(undefined);
      });
  }, [deleteZone, zoneId, toggleDeleteMode]);

  return (
    <>
      <For
        each={zones}
        render={(zone) => (
          <AreaItem
            zone={zone}
            coverages={coverages}
            onSelectZone={onSelectZone}
            onUpdateZone={onUpdateZone}
            options={{ ...polygonOptions, editable }}
          />
        )}
      />
      <DeleteProviderZoneModal
        isOpen={isDeleteMode}
        isLoading={isDeleting}
        onClose={toggleDeleteMode}
        onDeleteZone={onDeleteZone}
        afterClose={afterModalClose}
      />
    </>
  );
};
