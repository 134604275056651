import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useRef } from "react";
import { useClickOutside } from "react-haiku";
import { setIsDeleteAdminClientModal } from "../../../../redux/reducers/ui-reducer";
import { useGetUserScore } from "../../../../redux/rtk-query";
import { useAppDispatch } from "../../../../redux/store";
import { ActionsCellBox, Box } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { UserDetailsCard } from "../../../../shared/components/user-info/user-details-card";
import { useToggle } from "../../../../shared/hooks";
import { ActionsCellStyles } from "../../../../shared/styles";
import { If } from "../../../../shared/utilities/If";
import { OpenOptionsButton } from "../../../users/components/client-details-card/ClientDetailsCard.styles";
import { useAdminClientDetailsCtx } from "../../context/admin-client-details-ctx";
import { AdminClientInfoForm } from "../admin-client-info-form";

export const AdminClientDetailsCard = () => {
  const dispatch = useAppDispatch();
  const actionsRef = useRef(null);
  const [optionsOpen, toggleOptionsOpen, setIsOptionsOpen] = useToggle(false);
  const { adminClient, isEditMode, formRef, toggleEditMode } =
    useAdminClientDetailsCtx();

  const { data: score } = useGetUserScore(adminClient || skipToken);

  useClickOutside(actionsRef, () => setIsOptionsOpen(false));

  const onOpenModal = () => {
    dispatch(setIsDeleteAdminClientModal(true));
    setIsOptionsOpen(false);
  };

  const editTitle =
    adminClient?.user_status !== "Suspendido" ? "Editar Cliente" : undefined;

  return (
    <GlobalContentBox style={{ position: "relative" }}>
      <Box flex flexColumn gap="1rem">
        <UserDetailsCard
          score={score}
          profilePhoto={adminClient?.user_photography}
          toggleEditMode={toggleEditMode}
          editTitle={isEditMode ? undefined : editTitle}
        />
        <AdminClientInfoForm ref={formRef} />
      </Box>
      <If showIf={isEditMode && !optionsOpen}>
        <OpenOptionsButton
          extraStyles={ActionsCellStyles}
          onClick={toggleOptionsOpen}
        >
          <div />
          <div />
          <div />
        </OpenOptionsButton>
      </If>
      <If showIf={isEditMode && optionsOpen}>
        <OpenOptionsButton extraStyles={ActionsCellStyles}>
          <div />
          <div />
          <div />
        </OpenOptionsButton>
      </If>
      <If showIf={isEditMode && optionsOpen}>
        <ActionsCellBox ref={actionsRef} top="2rem" right="1.5rem">
          <div onClick={onOpenModal}>Eliminar Cliente</div>
        </ActionsCellBox>
      </If>
      {/* <ClientDeleteModal /> */}
    </GlobalContentBox>
  );
};
