import React from "react";
import * as T from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { For } from "../../../../shared/utilities/For";
import { ClientProduct } from "../../services";
import { SubscriptionsTableRow } from "./SubscriptionsTableRow";

const titles = [
  "Nombre",
  "Servicios",
  "Intervalo",
  "Cantidad",
  "Precio",
  "Detalles",
];

const columns = "repeat(3, 1fr) .7fr .7fr 0.5fr";

interface Props {
  isLoading: boolean;
  subscriptions: ClientProduct[];

  onWatchDetails: (plan: ClientProduct) => void;
}

export const SubscriptionsTable = ({
  subscriptions,
  isLoading,
  onWatchDetails,
}: Props) => {
  return (
    <GlobalContentBox isLoading={isLoading}>
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={titles}
            render={(title) => <T.TableTitle>{title}</T.TableTitle>}
          />
        </T.TableTitlesBox>
        <T.TableBody gridColumns={columns}>
          <For
            each={subscriptions}
            render={(sub) => (
              <SubscriptionsTableRow
                subscription={sub}
                onWatchDetails={onWatchDetails}
              />
            )}
          />
        </T.TableBody>
      </T.Table>
    </GlobalContentBox>
  );
};
