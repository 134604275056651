import React, { createContext } from "react";

import { ReactSetter } from "../../../../shared/types";
import { Vehicle } from "../../models";

export interface IProviderVehicleCtx {
  openModal: () => void;
  setActiveVehicle: ReactSetter<Vehicle | undefined>;
  openCodeModal: () => void;
  closeCodeModal: () => void;
  openDeleteVehicleModal: () => void;
}

export const ProviderVehiclesCtx = createContext({} as IProviderVehicleCtx);

export const useProviderVehiclesCtx = () =>
  React.useContext(ProviderVehiclesCtx);
