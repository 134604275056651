import styled from "styled-components";
import { Form } from "../../../../../shared/components/form";

export const CreateSubscriptionForm = styled(Form)`
  gap: 2rem;
  display: grid;
  flex-direction: column;

  .description-input {
    grid-column: 1 / 3;
  }
`;
