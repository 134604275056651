import styled, { keyframes } from "styled-components";

export const Dash4 = keyframes`
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dashoffset: -125px;
    }
`;

export const Rotate4 = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

export interface LoaderProps {
  size?: string;
  color?: string;
}

export const LoaderWrapper = styled.svg<LoaderProps>`
  width: ${(props) => props.size || "1.2rem"};
  transform-origin: center;
  animation: ${Rotate4} 2s linear infinite;

  > circle {
    fill: none;
    stroke: ${(props) => props.color || props.theme.colors.white};
    stroke-width: 2;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: ${Dash4} 1.5s ease-in-out infinite;
  }
`;
