import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { HiOutlineKey } from "react-icons/hi";
import { MyInput } from "../../../../../shared/components/inputs";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import {
  ValidateVehicleCodeFormProps,
  ValidateVehicleCodeFormShape,
} from "../../../schemas";
import * as S from "./CreateVehicle.styles";

type Keys = keyof ValidateVehicleCodeFormProps;

interface Props {
  isValidating: boolean;
  onClose: () => void;
  onValidateCode: (code: ValidateVehicleCodeFormProps) => void;
}

export const VehicleCodeForm = ({
  isValidating,
  onClose,
  onValidateCode,
}: Props) => {
  const methods = useForm<ValidateVehicleCodeFormProps>({
    resolver: yupResolver(ValidateVehicleCodeFormShape),
  });

  return (
    <S.ValidateVehicleCodeWrapper
      methods={methods}
      onSubmit={methods.handleSubmit(onValidateCode)}
    >
      <HiOutlineKey />
      <p>
        Hemos enviado en código de 6 dígitos al número de teléfono registrado,
        favor ingresarlos para finalizar el proceso de registro.
      </p>
      <MyInput<Keys> name="code" placeholder="Ej: 123456" />
      <PageButtons
        show
        fullWidth
        onCancel={onClose}
        isLoading={isValidating}
        proceedText="Validar"
      />
    </S.ValidateVehicleCodeWrapper>
  );
};
