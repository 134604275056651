import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { authReducer } from "../../modules/auth/redux";
import { usersReducer } from "../../modules/users/redux";
import { uiReducer } from "../reducers/ui-reducer";
import { globalApi } from "../rtk-query";

const store = configureStore({
  reducer: {
    // Rtk reducers
    [globalApi.reducerPath]: globalApi.reducer,

    // Normal Reducers
    [uiReducer.name]: uiReducer.reducer,
    [authReducer.name]: authReducer.reducer,
    [usersReducer.name]: usersReducer.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(globalApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
