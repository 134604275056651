import * as S from "./deleteAccount.styles";

export const DeleteAccountPage = () => {
  return (
    <S.container>
      <S.img src="/assets/logotipo.png" alt="anngel logo" />
      <S.title>Eliminar tu cuenta de Anngel</S.title>
      <S.desc>
        ¡Lamentamos verte partir! Entendemos que, en ocasiones, puede ser
        necesario eliminar una cuenta de usuario. Por favor, ten en cuenta que
        este proceso es irreversible y todos tus datos, incluyendo información
        de perfil, historial de compras y métodos de pago asociados, serán
        eliminados de manera permanente. Una vez que hayas completado este
        proceso, no podrás acceder a tu cuenta nuevamente. Asegúrate de estar
        seguro de tu decisión antes de proceder.
      </S.desc>
      <S.containerSteps>
        <S.subtitle>Para eliminar tu cuenta, sigue estos pasos:</S.subtitle>
        <S.listDecimal start={3}>
          <S.item>
            1. Ve a la sección de "Cuenta" en la página principal de tu perfil.
          </S.item>
          <S.item>
            2. En la parte inferior de la página de la cuenta, encontrarás un
            botón claramente identificado como "Eliminar Cuenta". Haz clic en
            este botón para continuar.
          </S.item>
          <S.item>
            3. Aparecerá un mensaje de confirmación que te informará sobre la
            eliminación irreversible de tu cuenta y datos personales. Debes
            confirmar esta acción seleccionando "Aceptar" en el mensaje de
            confirmación.
          </S.item>
        </S.listDecimal>
        <S.desc>
          Por favor, asegúrate de estar seguro de tu decisión antes de proceder.
          Una vez que confirmes la eliminación de tu cuenta, no podrás
          recuperarla ni acceder a tu información anterior.
        </S.desc>
      </S.containerSteps>
    </S.container>
  );
};
