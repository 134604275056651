import styled, { css } from "styled-components";
import { UserStatus } from "../../../../shared/enums";

export const ActiveClientCss = css`
  /* width: 4.5rem; */
  background-color: ${({ theme }) => theme.colors.green.primary};
`;

export const SuspendedClientCss = css`
  /* width: 7rem; */
  background-color: ${({ theme }) => theme.colors.red};
`;

export const InactiveClientCss = css`
  /* width: 6rem; */
  background-color: #f0ad4e;
`;

interface Props {
  status: UserStatus;
}

export const StatusBox = styled.div<Props>`
  text-align: center;
  padding: 0.3rem;
  border-radius: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};

  ${(props) =>
    props.status === "Activo"
      ? ActiveClientCss
      : props.status === "Suspendido"
      ? SuspendedClientCss
      : InactiveClientCss}
  width: 7rem;
`;
