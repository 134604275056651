import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "react-haiku";
import { UseFormSetValue } from "react-hook-form/dist/types";
import { FaUserFriends } from "react-icons/fa";
import { RiCloseCircleLine } from "react-icons/ri";
import { MyInput } from "../../../../shared/components/inputs";
import { getDateWithInputFormat } from "../../../../shared/helpers";
import { Affiliate, ReactSetter } from "../../../../shared/types";
import { For } from "../../../../shared/utilities/For";
import { If } from "../../../../shared/utilities/If";
import {
  useGetAllAffiliates,
  useGetOperatorAffiliates,
} from "../../../operators/services";
import { CreateEventFormProps } from "../../schemas";
import * as S from "./CreateEventForm.styles";

type keys = keyof CreateEventFormProps;

interface Props {
  affiliateId?: string;
  affiliateName: string;
  affiliateBirthday: string;
  setAffiliateId: ReactSetter<string | undefined>;
  setValues: UseFormSetValue<CreateEventFormProps>;
}

export const EventAffiliate = ({
  affiliateBirthday,
  affiliateName,
  affiliateId,
  setAffiliateId,
  setValues,
}: Props) => {
  const [showList, setShowList] = useState(false);
  const debounceName = useDebounce(affiliateName);
  const debounceBirthday = useDebounce(affiliateBirthday);

  const birthday = useMemo(
    () =>
      debounceBirthday ? dayjs(debounceBirthday).toISOString() : undefined,
    [debounceBirthday]
  );

  const shouldRequest = useMemo(
    () => !!debounceName || !!birthday,
    [debounceName, birthday]
  );

  const {
    data: affiliates = [],
    isFetching,
    isUninitialized,
  } = useGetAllAffiliates(
    { birthday, search: debounceName },
    { skip: !shouldRequest }
  );

  const isListEmpty = useMemo(
    () => affiliates.length === 0 && !isFetching,
    [affiliates, isFetching]
  );

  const onSelectAffiliate = (affiliate: Affiliate) => {
    return () => {
      const fullname = `${affiliate.user_fullname} ${affiliate.user_surname}`;
      setValues("fullname", fullname);
      setValues(
        "birthday",
        getDateWithInputFormat(affiliate.affiliate_birthday)
      );
      setAffiliateId(affiliate.affiliate_id);
      setShowList(false);

      const nameInputId: keys = "fullname";
      const birthdayInputId: keys = "birthday";

      document.getElementById(nameInputId)?.focus();
      document.getElementById(birthdayInputId)?.focus();
    };
  };

  const onClickInput = () => {
    if (!affiliateId) return;
    setAffiliateId(undefined);
  };

  useEffect(() => {
    if (affiliates.length >= 0 && !affiliateId && !isUninitialized) {
      setShowList(true);
    }
  }, [affiliates, affiliateId, isUninitialized]);

  return (
    <S.EventAffiliateWrapper affiliateId={affiliateId}>
      <MyInput<keys>
        name="fullname"
        readOnly={!!affiliateId}
        label="Nombre del usuario"
        onClickContainer={onClickInput}
      />
      <MyInput<keys>
        type="date"
        name="birthday"
        readOnly={!!affiliateId}
        label="Fecha de nacimiento"
        onClickContainer={onClickInput}
      />
      <If showIf={showList}>
        <S.EventAffiliateList>
          <If showIf={!isListEmpty}>
            <For
              each={affiliates}
              render={(affiliate: any) => (
                <S.EventAffiliateItem onClick={onSelectAffiliate(affiliate)}>
                  <FaUserFriends />
                  <span>
                    {affiliate.user_fullname} {affiliate.user_surname}
                  </span>
                  <span>-</span>
                  <span>{affiliate.user_email}</span>
                  <span>-</span>
                  <span>{affiliate.user_phone}</span>
                </S.EventAffiliateItem>
              )}
            />
          </If>
          <If showIf={isListEmpty}>
            <S.EventAffiliateItem>
              <RiCloseCircleLine />
              <span>No se encontraron usuarios con los datos ingresados</span>
            </S.EventAffiliateItem>
          </If>
        </S.EventAffiliateList>
      </If>
    </S.EventAffiliateWrapper>
  );
};
