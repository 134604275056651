import { EntityName } from "../../shared/enums";

export const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;
export const CLIENT_API_URL = process.env.REACT_APP_CLIENT_API_URL;
export const EVENTS_API_URL = process.env.REACT_APP_EVENTS_API_URL;
export const GENERAL_API_URL = process.env.REACT_APP_GENERAL_API_URL;
export const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API_URL;
export const AFFILIATES_API_URL = process.env.REACT_APP_AFFILIATES_API_URL;
export const USERS_API_URL = process.env.REACT_APP_USERS_API_URL;
export const SUPPLIER_API_URL = process.env.REACT_APP_SUPPLIER_API_URL;
export const MANAGERS_API_URL = process.env.REACT_APP_MANAGERS_API_URL;
export const COMMUNICATION_API_URL =
  process.env.REACT_APP_COMMUNICATION_API_URL;
export const OPERATOR_API_URL = process.env.REACT_APP_OPERATOR_API_URL;

export const generalRoutes = {
  validateCodeRT: () => `${GENERAL_API_URL}/codes/validate`,
  meRT: () => `${GENERAL_API_URL}/users/me`,
  updatePasswordRT: (id: string) =>
    `${GENERAL_API_URL}/users/update-password/${id}`,
  validateUserRT: () => `${GENERAL_API_URL}/users/validate`,
  loginRT: () => `${GENERAL_API_URL}/auth/login`,
  refreshTokenRT: () => `${GENERAL_API_URL}/auth/refresh-token`,
  validateRefreshTokenRT: () => `${GENERAL_API_URL}/auth/refresh-token`,
  validateAccessTokenRT: () => `${GENERAL_API_URL}/auth/validate-access-token`,
  sendRecoveryEmailRT: () => `${GENERAL_API_URL}/auth/recovery-password`,
  getServicesRT: () => `${GENERAL_API_URL}/services/find-all`,
  getAutocompleteRT: () =>
    `${GENERAL_API_URL}/services/maps/autocomplete-place`,
  getPlaceDetailsRT: () => `${GENERAL_API_URL}/services/maps/place-by-id`,
  getVehicleTypesRT: () => `${GENERAL_API_URL}/services/vehicle-types/find-all`,
  getCountriesRT: () => `${GENERAL_API_URL}/country-codes/find-all/v2`,
};

export const usersRoutes = {
  getAllAffiliates: () => `${USERS_API_URL}/affiliates/find-all-search`,
  updateManagerRT: (id: string) => `${USERS_API_URL}/managers/update/${id}`,
  getCompaniesRT: () => `${USERS_API_URL}/companies/find-all`,
  createCompanyRT: (id: string) => `${USERS_API_URL}/companies/create/${id}`,
  deleteCompanyRT: (id: string) => `${USERS_API_URL}/companies/delete/${id}`,
  updateCompanyRT: (id: string) => `${USERS_API_URL}/companies/update/${id}`,
  getCompanyAffiliatesRT: (id: string) =>
    `${USERS_API_URL}/affiliates/find-all-by-company/${id}`,
  deleteCompanyAffiliateRT: (id: string) =>
    `${USERS_API_URL}/affiliates/delete/${id}`,
  createCompanyAffiliateRT: (id: string) =>
    `${USERS_API_URL}/affiliates/create-by-company/${id}`,
  updateAffiliateRT: (id: string) => `${USERS_API_URL}/affiliates/update/${id}`,
  validateAffiliateRT: (id: string) =>
    `${USERS_API_URL}/affiliates/validation_call/${id}`,
  getAllClientAffiliatesRT: (id: string) =>
    `${USERS_API_URL}/affiliates/find-all-by-client/no-pagination/${id}`,
  getAllCompanyAffiliatesRT: (id: string) =>
    `${USERS_API_URL}/affiliates/find-all-by-company/no-pagination/${id}`,
  getSubscriptionAffiliatesRT: () =>
    `${USERS_API_URL}/affiliates/find-all/subs/and/validated-call`,
  getAffiliatesToCallRt: () =>
    `${USERS_API_URL}/affiliates/find-all/call/false`,
  getAdminClientsRT: () => `${USERS_API_URL}/clients/find-all`,
  createAdminClientRT: (id: string) => `${USERS_API_URL}/clients/create/${id}`,
  updateAdminClientRT: (id: string) => `${USERS_API_URL}/clients/update/${id}`,
  deleteAdminClientRT: (id: string) => `${USERS_API_URL}/clients/delete/${id}`,
  getProvidersRT: () => `${USERS_API_URL}/suppliers/find-all`,
  createProviderRT: (id: string) => `${USERS_API_URL}/suppliers/create/${id}`,
  updateProviderRT: (id: string) => `${USERS_API_URL}/suppliers/update/${id}`,
  deleteProviderRT: (id: string) => `${USERS_API_URL}/suppliers/delete/${id}`,
  getProviderWorkersRT: (id: string) =>
    `${USERS_API_URL}/workers/find-all-by-supplier/${id}`,
  getProviderWorkersPaginationRT: (id: string) =>
    `${USERS_API_URL}/workers/find-all-by-supplier/pagination/${id}`,
  getAllOperatorsRT: () => `${USERS_API_URL}/operators/find-all`,
  createCompanyOperatorRT: (id: string) =>
    `${USERS_API_URL}/operators/create-by-company/${id}`,
  createClientOperatorRT: (id: string) =>
    `${USERS_API_URL}/operators/create-by-client/${id}`,
  updateOperatorRT: (id: string) => `${USERS_API_URL}/operators/update/${id}`,
  deleteOperatorRT: (id: string) => `${USERS_API_URL}/operators/delete/${id}`,
  getCompanyOperatorsRT: (id: string) =>
    `${USERS_API_URL}/operators/find-all-by-company/${id}`,
  getClientOperatorsRT: (id: string) =>
    `${USERS_API_URL}/operators/find-all-by-client/${id}`,
  getUserDetailsRT: (id: string) => `${USERS_API_URL}/users/find-one/${id}`,
  uploadUserPhotoRT: (id: string) =>
    `${USERS_API_URL}/users/upload/photo/${id}`,
  getUserPhotoRT: (id: string) => `${USERS_API_URL}/users/download/photo/${id}`,
  getUserScoreRT: (name: EntityName, id: string) =>
    `${USERS_API_URL}/events/${name}/score/${id}`,
  getOperatorStatsRT: (id: string) =>
    `${USERS_API_URL}/events/operator/events_attended/${id}`,
  getAffiliateBeneficiariesRT: (id: string) =>
    `${USERS_API_URL}/beneficiaries/find-all-by-contributor/${id}`,
};

export const managersRoutes = {
  getServices: () => `${MANAGERS_API_URL}/services/find-all`,
  getCoverageRT: () => `${MANAGERS_API_URL}/coverage`,
  createCoveragesRT: () => `${MANAGERS_API_URL}/coverage/many`,
  deleteCoverageRT: (id: string) => `${MANAGERS_API_URL}/coverage/${id}`,
  updateCoverageRT: (id: string) => `${MANAGERS_API_URL}/coverage/${id}`,
};

export const companyRoutes = {
  uploadAffiliatesRT: (id: string) =>
    `${COMPANY_API_URL}/affiliates/create/excel-file/${id}`,
  downloadAffiliatesRT: (id: string) =>
    `${COMPANY_API_URL}/affiliates/download/excel-file/${id}`,
  downloadTemplateRT: () =>
    `${COMPANY_API_URL}/affiliates/download/excel-file/template`,
  getCompanyPlansRT: (id: string) =>
    `${COMPANY_API_URL}/plan/find-all-by-company/${id}`,
};

export const supplierRoutes = {
  getProviderCoverageRT: () => `${SUPPLIER_API_URL}/coverage`,
  getProviderZoneRT: (id: string) =>
    `${SUPPLIER_API_URL}/zones/supplierzones/${id}`,
  createProviderZonesRT: () => `${SUPPLIER_API_URL}/zones/many`,
  updateProviderZoneRT: (id: string) => `${SUPPLIER_API_URL}/zones/${id}`,
  deleteProviderZoneRT: (id: string) => `${SUPPLIER_API_URL}/zones/${id}`,
  getServicesRT: () => `${SUPPLIER_API_URL}/services/find-all`,
  createVehicleRT: (id: string) => `${SUPPLIER_API_URL}/vehicles/create/${id}`,
  updateVehicleRT: (id: string) => `${SUPPLIER_API_URL}/vehicles/update/${id}`,
  updateVehicleServiceRT: (id: string) =>
    `${SUPPLIER_API_URL}/vehicles/service/update/${id}`,
  deleteVehicleRT: (id: string) => `${SUPPLIER_API_URL}/vehicles/delete/${id}`,
  getVehiclesRT: (id: string) => `${SUPPLIER_API_URL}/vehicles/find-all/${id}`,
  reSendVehicleCodeRT: (id: string) =>
    `${SUPPLIER_API_URL}/vehicles/code/re-send/${id}`,
  validateVehicleCodeRT: (id: string, code: string) =>
    `${SUPPLIER_API_URL}/vehicles/validate/${id}/code/${code}`,
};

export const eventsRoutes = {
  createEventRT: (serviceId: string, affiliateId: string) =>
    `${EVENTS_API_URL}/events/create/${serviceId}/${affiliateId}`,
  getAllReportsRT: () => `${EVENTS_API_URL}/reports/find-all`,
  getOperatorReportsRT: (id: string) =>
    `${EVENTS_API_URL}/reports/find-all-by-operator/${id}`,
  getClientReportsRT: (id: string) =>
    `${EVENTS_API_URL}/reports/find-all-by-client/${id}`,
  getProviderReportsRT: (id: string) =>
    `${EVENTS_API_URL}/reports/find-all-by-supplier/${id}`,
  getCompanyReportsRT: (id: string) =>
    `${EVENTS_API_URL}/reports/find-all-by-company/${id}`,
  getManagerReportsRT: () => `${EVENTS_API_URL}/reports/find-all`,

  getManagerHistoriesRT: () => `${EVENTS_API_URL}/histories/find-all`,
  getOperatorHistoriesRT: (id: string) =>
    `${EVENTS_API_URL}/histories/find-all?limit=10`,
  getClientHistoriesRT: (id: string) =>
    `${EVENTS_API_URL}/histories/find-all-by-client/${id}`,
  getProviderHistoriesRT: (id: string) =>
    `${EVENTS_API_URL}/histories/find-all-by-supplier/${id}`,
  getCompanyHistoriesRT: (id: string) =>
    `${EVENTS_API_URL}/histories/find-all-by-company/${id}`,

  getEventDetailsRT: (id: string) => `${EVENTS_API_URL}/events/find-one/${id}`,
  cancelEventRT: (id: string) => `${EVENTS_API_URL}/events/cancel/${id}`,
  updateOperatorPqrRT: (id: string) => `${EVENTS_API_URL}/pqrs/operator/${id}`,
};

export const operatorRoutes = {
  getOperatorCoveragesRT: () => `${OPERATOR_API_URL}/coverage`,
  getOperatorEventsRT: (id: string) =>
    `${OPERATOR_API_URL}/events/sse/assigned/${id}`,
};

export const affiliatesRoutes = {
  getAffiliateMedicalRecordRT: (id: string) =>
    `${AFFILIATES_API_URL}/records/find-one/${id}`,
};

export const communicationRoutes = {
  getCallTokenRT: () => `${COMMUNICATION_API_URL}/callbrowser/generateToken`,
  getCallInfoRT: (callSid: string) =>
    `${COMMUNICATION_API_URL}/callbrowser/callInfo/${callSid}`,
};

export const clientRoutes = {
  createClientSubscriptionRT: (id: string) =>
    `${CLIENT_API_URL}/subscriptions/create/${id}`,
  updateClientSubscriptionRT: (id: string) =>
    `${CLIENT_API_URL}/subscriptions/update/${id}`,
  getClientSubscriptionsRT: (id: string) =>
    `${CLIENT_API_URL}/subscriptions/find-all-by-client/${id}`,
  getAdminClientAffiliatesRT: (id: string) =>
    `${CLIENT_API_URL}/affiliates/${id}`,
};
