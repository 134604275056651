import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectIsUpdatePasswordModal,
  setIsUpdatePasswordModal,
} from "../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../redux/store";
import { MyInput } from "../../../../shared/components/inputs";
import { MyModal } from "../../../../shared/components/my-modal";
import { CloseModal } from "../../../../shared/components/ui/close-modal";
import { PageButtons } from "../../../../shared/components/ui/page-buttons";
import { selectUser } from "../../../auth/redux";
import { NewPasswordProps, NewPasswordShape } from "../../schemas";
import { useUpdatePassword } from "../../services";
import * as S from "./UpdatePasswordModal.styles";

type keys = keyof NewPasswordProps;

export const UpdatePasswordModal = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const isOpen = useSelector(selectIsUpdatePasswordModal);
  const [updatePassword, { isLoading }] = useUpdatePassword();

  const methods = useForm<NewPasswordProps>({
    resolver: yupResolver(NewPasswordShape),
  });

  const onSubmit = ({ confirmPassword, ...data }: NewPasswordProps) => {
    updatePassword({
      id: user!.user_id,
      ...data,
    })
      .unwrap()
      .then(closeModal);
  };

  const closeModal = () => {
    if (isLoading) return;
    dispatch(setIsUpdatePasswordModal(false));
  };

  return (
    <MyModal isOpen={isOpen} onRequestClose={closeModal}>
      <S.UpdatePasswordFormWrapper
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <CloseModal onClick={closeModal} />
        <h2>Editar Contraseña</h2>
        <S.UpdatePasswordForm>
          <MyInput<keys>
            name="oldPassword"
            label="Contraseña Actual"
            placeholder="Escribe tu contraseña actual"
            type="password"
          />
          <S.PasswordRequirements>
            <h3>Tu contraseña debe contener</h3>
            <li>9 o más caracteres</li>
            <li>Letras mayúsculas y minúsculas</li>
            <li>Al menos un número</li>
          </S.PasswordRequirements>
          <MyInput<keys>
            name="newPassword"
            label="Nueva Contraseña"
            placeholder="Escribe tu nueva contraseña"
            type="password"
          />
          <MyInput<keys>
            name="confirmPassword"
            label="Confirmar Nueva Contraseña"
            placeholder="Confirma tu nueva contraseña"
            type="password"
            className="confirm-new-password"
          />
        </S.UpdatePasswordForm>
        <PageButtons
          show
          onCancel={closeModal}
          proceedText="Modificar"
          isLoading={isLoading}
        />
      </S.UpdatePasswordFormWrapper>
    </MyModal>
  );
};
