import { FaTimes } from "react-icons/fa";
import styled from "styled-components";

export const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.4rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue};
`;
