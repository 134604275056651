import { colors } from "../../../theme";
import { Box } from "../../styled";
import { LoaderProps, LoaderWrapper } from "./Loader.styles";

export const Loader = (props: LoaderProps) => (
  <LoaderWrapper viewBox="25 25 50 50" {...props}>
    <circle r="20" cy="50" cx="50" />
  </LoaderWrapper>
);

export const MyLoader = (props: LoaderProps) => (
  <Box width="100%" height="100%" centerAll>
    <Loader color={props.color || colors.blue} size={props.size || "4rem"} />
  </Box>
);
