import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetUserDetails } from "../../../../redux/rtk-query";
import { PageContainer } from "../../../../shared/components/styled";
import { MyLoader } from "../../../../shared/components/ui/Loader";
import { PageButtons } from "../../../../shared/components/ui/page-buttons";
import { UserSectionNav } from "../../../../shared/components/user-info/user-section-nav";
import { useToggle } from "../../../../shared/hooks";
import { If } from "../../../../shared/utilities/If";
import { ClientAffiliatesTable } from "../../components/client-affiliates-table/ClientAffiliatesTable";
import { ClientDetailsCard } from "../../components/client-details-card";
import { ClientPaymentInfo } from "../../components/client-payment-info";
import { ClientDetailsPageCtx } from "../../context/client-details-page-ctx";
import { UpdateClientFormProps } from "../../schemas";
import { useGetClientAffiliates, useUpdateClient } from "../../services";

interface ChildReturn {
  validateForm: () => Promise<{
    isValid: boolean;
    values: UpdateClientFormProps;
  }>;
}

export const ClientDetailsPage = () => {
  const formRef = useRef<ChildReturn>();

  const [page, setPage] = useState<number>();
  const { userId } = useParams() as { userId: string };
  const [isEditMode, toggleEditMode] = useToggle();

  const { data: user, isFetching } = useGetUserDetails(userId);
  const [updateClient, { isLoading: isUpdating }] = useUpdateClient();
  const { data: affiliates, isLoading: isGettingAffiliates } =
    useGetClientAffiliates(
      user?.role_name === "Empresa"
        ? { company_id: user.company_id, page }
        : skipToken
    );

  const onPaginate = (page: number) => {
    setPage(page);
  };

  const onUpdate = async () => {
    if (!formRef.current || user?.role_name !== "Empresa") return;
    const { isValid, values } = await formRef.current.validateForm();
    if (!isValid) return;
    const phone = `${values.country_code}${values.phone}`;
    updateClient({
      company_id: user.company_id,
      rfc: (values.rfc ?? "").toUpperCase(),
      user: {
        phone,
        fullname: values.name,
        email: values.email,
      },
    })
      .unwrap()
      .then(toggleEditMode);
  };

  if (isFetching) {
    return <MyLoader />;
  }

  return (
    <ClientDetailsPageCtx.Provider
      value={{
        user,
        formRef,
        isEditMode,
        toggleEditMode,
      }}
    >
      <PageContainer gap="1rem">
        <UserSectionNav
          title={user?.user_fullname || ""}
          actualSection={user?.user_fullname || ""}
          toggleText="Usuario Activo"
        />
        <ClientDetailsCard />
        <If showIf={!isEditMode}>
          <ClientAffiliatesTable
            onPageChange={onPaginate}
            isLoading={isGettingAffiliates}
            affiliates={affiliates?.results || []}
            pageCount={affiliates?.total_pages || 0}
          />
        </If>
        <If showIf={isEditMode}>
          <ClientPaymentInfo user={user} />
        </If>
        <PageButtons
          show={isEditMode}
          onProceed={onUpdate}
          isLoading={isUpdating}
          onCancel={toggleEditMode}
          proceedText="Guardar Cambios"
        />
      </PageContainer>
    </ClientDetailsPageCtx.Provider>
  );
};
