import { useRef } from "react";
import { useClickOutside } from "react-haiku";
import { ActionsCellBox } from "../../../../shared/components/styled/ActionsCellBox";
import * as T from "../../../../shared/components/styled/Table";
import { getPhoneWithFormat } from "../../../../shared/helpers";
import { useMyNavigate, useToggle } from "../../../../shared/hooks";
import { ActionsCellStyles } from "../../../../shared/styles";
import { Supplier } from "../../../../shared/types";
import { If } from "../../../../shared/utilities/If";

interface Props {
  provider: Supplier;
}

export const ProvidersTableRow = ({ provider }: Props) => {
  const navigate = useMyNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [isActionsOpen, toggle, setIsActionsOpen] = useToggle(false);
  useClickOutside(ref, () => setIsActionsOpen(false));

  const onGoToDetails = (editMode = false) => {
    editMode = provider.user_is_deleted ? false : editMode;
    return () => {
      navigate({
        name: "Detalles de proveedor",
        params: { userId: provider.user_id },
        query: { isEditMode: editMode },
      });
    };
  };

  return (
    <T.TableRow relative>
      <T.TableCell pointer onClick={onGoToDetails()}>
        {provider.user_fullname}
      </T.TableCell>
      <T.TableCell pointer onClick={onGoToDetails()}>
        {provider.supplier_rfc}
      </T.TableCell>
      <T.TableCell>{getPhoneWithFormat(provider.user_phone)}</T.TableCell>
      <T.TableCell>{provider.user_email}</T.TableCell>
      <If showIf={!isActionsOpen}>
        <T.TableCell extraStyles={ActionsCellStyles} onClick={toggle}>
          <div />
          <div />
          <div />
        </T.TableCell>
      </If>
      <If showIf={isActionsOpen}>
        <T.TableCell extraStyles={ActionsCellStyles}>
          <div />
          <div />
          <div />
        </T.TableCell>
      </If>
      <If showIf={isActionsOpen}>
        <ActionsCellBox ref={ref} top="-.5rem">
          <div onClick={onGoToDetails(true)}>Editar</div>
          {/* <div>Suspender</div> */}
        </ActionsCellBox>
      </If>
    </T.TableRow>
  );
};
