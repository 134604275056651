import React from "react";
import { useSelector } from "react-redux";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import {
  selectIsDeleteOperatorModal,
  setIsDeleteOperatorModal,
} from "../../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../../redux/store";
import { Box } from "../../../../../shared/components/styled";
import { Operator } from "../../../../../shared/types";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { useDeleteOperator } from "../../../services";
import { useMyNavigate } from "../../../../../shared/hooks";

interface Props {
  operator?: Operator;
}

export const DeleteOperatorModal = ({ operator }: Props) => {
  const navigate = useMyNavigate();
  const dispatch = useAppDispatch();
  const isOpen = useSelector(selectIsDeleteOperatorModal);
  const [deleteOperator, { isLoading: isDeleting }] = useDeleteOperator();

  const onClose = () => {
    dispatch(setIsDeleteOperatorModal(false));
  };

  const onDelete = () => {
    deleteOperator(operator!.operator_id)
      .unwrap()
      .then(() => {
        onClose();
        navigate({ name: "Operadores" });
      });
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title="Eliminar Operador"
    >
      <Box fontSize="1rem" style={{ fontWeight: 500, textAlign: "center" }}>
        {`¿Desea eliminar al operador ${operator?.user_fullname}?`}
      </Box>
      <PageButtons
        show
        onCancel={onClose}
        isLoading={isDeleting}
        onProceed={onDelete}
        proceedText="Si, eliminar"
      />
    </BaseModal>
  );
};
