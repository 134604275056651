import React from "react";
import { Box } from "../../styled";
import { GlobalContentBoxTitle } from "../global-content-box/GlobalContentBox.styles";
import { PaginatedItems, PaginationProps } from "../paginated-items";

interface Props extends PaginationProps {
  title: string;
}

export const TablePagination = ({ title, ...paginationProps }: Props) => {
  return (
    <Box flex justifyBetween alignCenter>
      <GlobalContentBoxTitle>{title}</GlobalContentBoxTitle>
      <PaginatedItems {...paginationProps} />
    </Box>
  );
};
