import { FiUser } from "react-icons/fi";
import { TfiWallet } from "react-icons/tfi";
import { UserRole } from "../../../shared/enums";
import { AdminClientUsersPage } from "../pages/admin-client-users";
import { AdminClientDetailsPage } from "../pages/admin-cliente-details";
import { AdminClientListPage } from "../pages/admin-clients-list";
import { SubscriptionsListPage } from "../pages/subscriptions-list";

const AdminClientRoutes = [
  {
    path: "/clients",
    name: "Clientes",
    Icon: FiUser,
    showInMenu: true,
    Component: () => <AdminClientListPage />,
    rolesAllowed: ["Gerente"] as UserRole[],
  },
  {
    path: "/clients/:userId",
    name: "Detalles de cliente admin",
    Component: () => <AdminClientDetailsPage />,
    rolesAllowed: ["Gerente"] as UserRole[],
  },
  {
    path: "/users",
    name: "usuarios",
    Icon: FiUser,
    showInMenu: true,
    Component: () => <AdminClientUsersPage />,
    rolesAllowed: ["Cliente"] as UserRole[],
  },
  {
    path: "/subscriptions",
    name: "Suscripciones",
    showInMenu: true,
    Icon: TfiWallet,
    Component: () => <SubscriptionsListPage />,
    rolesAllowed: ["Cliente"] as UserRole[],
  },
] as const;

export { AdminClientRoutes };
