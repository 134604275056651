import { RiHome2Line } from "react-icons/ri";
import { TbHeadset } from "react-icons/tb";
import { UserRole } from "../../../shared/enums";
import { AffiliatesToCallPage } from "../pages/affiliates-to-call";
import { OperatorDetailsPage } from "../pages/operator-details";
import { OperatorHomePage } from "../pages/operator-home";
import { OperatorsListPage } from "../pages/operators-list";

const OperatorsRoutes = [
  {
    path: "/operators",
    name: "Operadores",
    Icon: TbHeadset,
    showInMenu: true,
    Component: () => <OperatorsListPage />,
    rolesAllowed: ["Empresa", "Gerente", "Cliente"] as UserRole[],
  },
  {
    path: "/operators/:userId",
    name: "Detalles del Operador",
    Component: () => <OperatorDetailsPage />,
    rolesAllowed: ["Empresa", "Gerente", "Cliente"] as UserRole[],
  },
  {
    path: "/home",
    name: "Inicio",
    showInMenu: true,
    Icon: RiHome2Line,
    Component: () => <OperatorHomePage />,
    rolesAllowed: ["Operador"] as UserRole[],
  },
  // {
  //   path: "/affiliates-to-call",
  //   name: "Usuarios",
  //   showInMenu: true,
  //   Icon: TbHeadset,
  //   Component: () => <AffiliatesToCallPage />,
  //   rolesAllowed: ["Operador"] as UserRole[],
  // },
] as const;

export { OperatorsRoutes };
