import styled from "styled-components";
import { Form } from "../../../../shared/components/form";
import { SharedModalStyles } from "../../../../shared/styles";

export const UpdatePasswordFormWrapper = styled(Form)`
  ${SharedModalStyles}
`;

export const UpdatePasswordForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;

  .confirm-new-password {
    margin-top: 1rem;
  }
`;

export const PasswordRequirements = styled.ul`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.gray.light + 99};
  padding-top: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: circle;

  > h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
  }

  > li {
    padding-left: 1rem;
    list-style: inside;
    font-size: 0.9rem;
    ::marker {
      color: ${({ theme }) => theme.colors.text};
    }
  }
`;
