import React from "react";
import * as T from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { For } from "../../../../shared/utilities/For";
import { Vehicle } from "../../models";
import { TableTitleCss } from "./VehiclesTable.styles";
import { VehiclesTableRow } from "./VehiclesTableRow";

interface Props {
  vehicles: Vehicle[];
  isLoading: boolean;
}

const titles = [
  "Placa",
  "Modelo",
  "Color",
  "Marca",
  "Tipo Modelo",
  "Tipo",
  "Teléfono",
  "Servicio",
  "Estatus",
  "Acciones",
];

const columns = ".5fr .5fr .5fr 1fr .7fr 1fr 1fr 1fr 1fr .5fr";

export const VehiclesTable = ({ vehicles, isLoading }: Props) => {
  return (
    <GlobalContentBox isLoading={isLoading}>
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={titles}
            render={(title) => (
              <T.TableTitle extraStyles={TableTitleCss}>{title}</T.TableTitle>
            )}
          />
        </T.TableTitlesBox>
        <T.TableBody
          gridColumns={columns}
          padding={vehicles.length === 1 ? "1rem 0" : "0"}
        >
          <For
            each={vehicles}
            render={(vehicle) => <VehiclesTableRow vehicle={vehicle} />}
          />
        </T.TableBody>
      </T.Table>
    </GlobalContentBox>
  );
};
