import styled from "styled-components";
import { Skeleton } from "../../styles";

export const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

interface NavbarStatusProps {
  bgColor: string;
}

export const NavbarStatus = styled.div<NavbarStatusProps>`
  text-align: center;
  padding: 0.5rem 1.5rem;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.theme.colors.white};
  border-radius: 6.25rem;
  font-weight: 600;
  cursor: default;
`;

export const NavBarItemsContainer = styled.ul`
  display: flex;
  align-items: center;
  gap: 1rem;

  > img {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
`;

export const NavbarProfilePhoto = styled.img<{ isLoading?: boolean }>`
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  ${Skeleton}
`;
