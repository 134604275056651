import { FaRegBuilding, FaUserFriends } from "react-icons/fa";
import { UserRole } from "../../../shared/enums";
import { ClientAffiliatesListPage } from "../pages/client-affiliates-list";
import { ClientDetailsPage } from "../pages/client-details";
import { ClientsListPage } from "../pages/clients-list";

const UsersRoutes = [
  {
    path: "/companies",
    name: "Empresas",
    Icon: FaRegBuilding,
    showInMenu: true,
    Component: () => <ClientsListPage />,
    rolesAllowed: ["Gerente"] as UserRole[],
  },
  {
    path: "/companies/:userId",
    name: "Detalles de cliente",
    Component: () => <ClientDetailsPage />,
    rolesAllowed: ["Gerente"] as UserRole[],
  },
  {
    path: "/company-affiliates",
    name: "Afiliados",
    showInMenu: true,
    Icon: FaUserFriends,
    Component: () => <ClientAffiliatesListPage />,
    rolesAllowed: ["Empresa"] as UserRole[],
  },
] as const;

export { UsersRoutes };
