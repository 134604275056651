import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { colors } from "../../../shared/theme";

const initialState = {
  isCreateClientModal: false,
  isDeleteClientModal: false,
  isReportDetailsModal: false,
  isCreateProviderModal: false,
  isCreateOperatorModal: false,
  isDeleteOperatorModal: false,
  isUpdatePasswordModal: false,
  isHistoricDetailsModal: false,
  isDeleteAffiliateModal: false,
  isCreateAdminClientModal: false,
  isDeleteAdminClientModal: false,
  isCreateClientAffiliateModal: false,
  statusColor: colors.green.primary,
  statusText: undefined as string | undefined,
  serviceText: undefined as string | undefined,
};

export const uiReducer = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setStatusText: (state, { payload }: PayloadAction<string>) => {
      state.statusText = payload;
    },
    setStatusColor: (state, { payload }: PayloadAction<string>) => {
      state.statusColor = payload;
    },
    setServiceText: (state, { payload }: PayloadAction<string | undefined>) => {
      state.serviceText = payload;
    },
    setIsUpdatePasswordModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdatePasswordModal = payload;
    },
    setIsDeleteClientModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeleteClientModal = payload;
    },
    setIsCreateClientModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreateClientModal = payload;
    },
    setIsDeleteAffiliateModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeleteAffiliateModal = payload;
    },
    setIsCreateProviderModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreateProviderModal = payload;
    },
    setIsCreateOperatorModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreateOperatorModal = payload;
    },
    setIsDeleteOperatorModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeleteOperatorModal = payload;
    },
    setIsCreateAdminClientModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreateAdminClientModal = payload;
    },
    setIsDeleteAdminClientModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeleteAdminClientModal = payload;
    },
    setIsReportDetailsModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isReportDetailsModal = payload;
    },
    setIsHistoricDetailsModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isHistoricDetailsModal = payload;
    },
  },
});

export const {
  setStatusText,
  setServiceText,
  setStatusColor,
  setIsDeleteClientModal,
  setIsCreateClientModal,
  setIsReportDetailsModal,
  setIsCreateOperatorModal,
  setIsUpdatePasswordModal,
  setIsDeleteOperatorModal,
  setIsCreateProviderModal,
  setIsHistoricDetailsModal,
  setIsDeleteAffiliateModal,
  setIsDeleteAdminClientModal,
  setIsCreateAdminClientModal,
} = uiReducer.actions;
