import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { setIsCreateClientModal } from "../../../../redux/reducers/ui-reducer";
import { selectIsCreateClientModal } from "../../../../redux/reducers/ui-reducer/ui.selectors";
import { useAppDispatch } from "../../../../redux/store";
import {
  Box,
  PageContainer,
  PageTitle,
} from "../../../../shared/components/styled";
import { Button } from "../../../../shared/components/ui/button";
import { GetUsersParams } from "../../../../shared/interfaces";
import { ClientSearch } from "../../components/client-search";
import { ClientTable } from "../../components/clients-table";
import { CreateClientModal } from "../../components/modals/create-client-modal";
import { useGetCompanies } from "../../services";

export const ClientsListPage = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useSelector(selectIsCreateClientModal);
  const [clientFilters, setClientFilters] = useState<GetUsersParams>({});
  const {
    data: clients,
    isFetching,
    isLoading,
  } = useGetCompanies(clientFilters);

  const handleOpenModal = () => {
    dispatch(setIsCreateClientModal(true));
  };

  const onPaginate = (page: number) => {
    setClientFilters({
      ...clientFilters,
      page,
    });
  };

  return (
    <PageContainer>
      <Box flex justifyBetween alignCenter>
        <PageTitle>Empresas</PageTitle>
        <Button Icon={FaPlus} fontSize="1rem" onClick={handleOpenModal}>
          Crear Empresa
        </Button>
      </Box>
      <ClientSearch
        isLoading={isFetching}
        setClientFilters={setClientFilters}
      />
      <ClientTable
        isLoading={isLoading}
        onPageChange={onPaginate}
        clients={clients?.results || []}
        pageCount={clients?.total_pages || 0}
      />
      {isModalOpen && <CreateClientModal />}
    </PageContainer>
  );
};
