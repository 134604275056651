import { DeleteAccountPage } from "../pages";

const InfoRoutes = [
  {
    path: "/deleteAccount",
    name: "DeleteAccount",
    Component: () => <DeleteAccountPage />,
  },
] as const;

export { InfoRoutes };
