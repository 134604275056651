import React, { useRef, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { FaFileDownload, FaPlus } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import {
  Box,
  PageContainer,
  PageTitle,
} from "../../../../shared/components/styled";
import { Button } from "../../../../shared/components/ui/button";
import { GetUsersParams } from "../../../../shared/interfaces";
import { Company } from "../../../../shared/types";
import { downloadTemplate } from "../../../affiliates/helpers";
import { downloadAffiliates } from "../../../affiliates/helpers/downloadAffiliates";
import { useUploadAffiliates } from "../../../affiliates/services";
import { selectUser } from "../../../auth/redux";
import { ClientAffiliatesSearch } from "../../components/client-affiliates-search";
import { ClientAffiliatesTable } from "../../components/client-affiliates-table";
import { CreateClientAffiliate } from "../../components/modals/create-client-affiliate";
import { useGetClientAffiliates } from "../../services";

export const ClientAffiliatesListPage = () => {
  const user = useSelector(selectUser) as Company;
  const inputDocument = useRef<HTMLInputElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadingTemplate, setIsDownloadingTemplate] = useState(false);
  const [isDownloadingAffiliates, setIsDownloadingAffiliates] = useState(false);
  const [affiliatesFilter, setAffiliatesFilter] = useState<GetUsersParams>({});

  const [uploadAffiliates, { isLoading: isUploading }] = useUploadAffiliates();
  const {
    data: affiliates,
    isLoading,
    isFetching,
  } = useGetClientAffiliates({
    company_id: user.company_id,
    ...affiliatesFilter,
  });

  const onPaginate = (page: number) => {
    setAffiliatesFilter({
      ...affiliatesFilter,
      page,
    });
  };

  const documentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    file &&
      uploadAffiliates({
        file,
        company_id: user?.company_id,
      })
        .unwrap()
        .finally(() => {
          if (inputDocument.current) inputDocument.current.value = "";
        });
  };

  const onLoadFiles = () => {
    inputDocument.current?.click();
  };

  const onDownloadTemplate = async () => {
    setIsDownloadingTemplate(true);
    await downloadTemplate();
    setIsDownloadingTemplate(false);
  };

  const onDownloadAffiliates = async () => {
    setIsDownloadingAffiliates(true);
    await downloadAffiliates(user.company_id);
    setIsDownloadingAffiliates(false);
  };

  const closeModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const openModal = React.useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <PageContainer>
      <Box flex justifyBetween alignCenter>
        <PageTitle>Afiliados</PageTitle>
        <Box flex gap=".5rem">
          <Button
            Icon={FaPlus}
            fontSize="1rem"
            onClick={openModal}
            text="Crear afiliado"
          />
          <Button
            Icon={RiFileExcel2Fill}
            fontSize="1rem"
            bgColor="#1D6F42"
            loading={isUploading}
            onClick={onLoadFiles}
            width="13rem"
            text="Cargar Afiliados"
          />
          <Button
            Icon={BsDownload}
            fontSize="1.2rem"
            bgColor="#1D6F42"
            tippy="Descargar Afiliados"
            onClick={onDownloadAffiliates}
            loading={isDownloadingAffiliates}
          />
          <Button
            Icon={FaFileDownload}
            fontSize="1.2rem"
            bgColor="#1D6F42"
            loading={isDownloadingTemplate}
            onClick={onDownloadTemplate}
            tippy="Descargar plantilla"
          />
        </Box>
        <input
          type="file"
          accept=".xlsx, .xls"
          ref={inputDocument}
          style={{ display: "none" }}
          onChange={documentChange}
        />
      </Box>
      <ClientAffiliatesSearch
        isLoading={isFetching}
        onSearch={setAffiliatesFilter}
      />
      <ClientAffiliatesTable
        isLoading={isLoading}
        onPageChange={onPaginate}
        pageCount={affiliates?.total_pages || 0}
        affiliates={affiliates?.results || []}
      />
      <CreateClientAffiliate isOpen={isModalOpen} onClose={closeModal} />
    </PageContainer>
  );
};
