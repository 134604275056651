import styled from "styled-components";
import { Form } from "../../../../../shared/components/form";
import { SharedModalStyles } from "../../../../../shared/styles";

export const CreateClientFormWrapper = styled(Form)`
  ${SharedModalStyles}
  width: 50rem;
  overflow: auto;

  .inputs-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }

  .buttons-container {
    display: flex;
    gap: 1.5rem;
    > * {
      flex: 1;
    }
  }

  .service-inputs {
    //Take two columns
    grid-column: span 2;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: calc(50% - 1rem) 1.2fr 1.2fr 1.5fr 0.2fr;

    svg {
      align-self: center;
      position: relative;
      top: 0.5rem;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.gray.primary};
    }
  }

  .service-select {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    > label {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.gray.primary};
      font-weight: 700;
    }

    > span {
      font-size: 0.7rem;
      color: ${({ theme }) => theme.colors.red};
      font-weight: 500;
    }

    > span,
    label {
      margin-left: 0.5rem;
    }

    .input-error-icon {
      color: ${({ theme }) => theme.colors.red};
      font-size: 1rem;
      right: 0.6rem !important;
    }

    select {
      border: 1px solid ${(props) => props.theme.colors.gray.lightest};
      width: 100%;
      height: 2.7rem;
      border-radius: 0.4rem;
      color: ${({ theme }) => theme.colors.text};
      font-weight: 500;
      background-color: ${({ theme }) => theme.colors.gray.lightest};
      ::placeholder {
        color: ${({ theme }) => theme.colors.gray.secondary};
      }

      :focus {
        border: 1px solid ${({ theme }) => theme.colors.gray.secondary};
        background-color: transparent;
      }
    }
  }

  .checkbox-input {
    width: 70%;
    height: 70%;
  }
`;
