import styled from "styled-components";
import { Form } from "../../../../../shared/components/form";

export const CreateVehicleModalWrapper = styled(Form)`
  gap: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .page-buttons {
    grid-column: 1 / 3;
  }
`;

export const ValidateVehicleCodeWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 3rem;

  svg {
    font-size: 9rem;
    color: #bac4c7;
    margin: 0 auto;
  }

  p {
    text-align: center;
    font-weight: 500;
  }
`;
