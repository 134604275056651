import { motion } from "framer-motion";
import styled from "styled-components";

export const NavbarOptionsContainer = styled(motion.div)`
  background-color: ${(props) => props.theme.colors.white};
  position: absolute;
  top: 3.5rem;
  right: 0rem;
  z-index: 1;

  padding: 1rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.colors.gray.lightest};

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
  }

  h3 {
    font-weight: 700;
  }

  svg {
    color: ${(props) => props.theme.colors.blue};
    transition: all 0.2s;
    font-size: 1.5rem;
  }
`;
