export const breakpoints = {
  sm: "@media ( max-width: 640px )",
  md: "@media ( max-width: 768px )",
  lg: "@media ( max-width: 1024px )",
  xl: "@media ( max-width: 1280px )",
  xl2: "@media ( max-width: 1440px )",
  xl3: "@media ( max-width: 1600px )",
};

export const colors = {
  white: "#fff",
  text: "#212121",
  red: "#C21B1B",
  blue: "#00A5DD",
  security_blue: "#1D6DB7",
  alert: "#FADE69",
  orange: "#ED6100",
  alert_dark: "#F8BE29",
  gray: {
    light: "#BAC4C7",
    primary: "#454545",
    lightest: "#f5f5f5",
    secondary: "#878D8F",
  },
  green: {
    primary: "#48A926",
  },
};

const theme = {
  breakpoints,
  colors,
};

export default theme;
