import { toast } from "react-toastify";
import {
  globalApi,
  usersRoutes,
  companyRoutes,
} from "../../../redux/rtk-query";
import { handleServiceRequest, includesAll } from "../../../shared/helpers";
import { ErrorResponse, GetUsersParams } from "../../../shared/interfaces";
import { Affiliate, Company } from "../../../shared/types";
import { PaginateRes } from "../../../shared/types/paginate";
import {
  CreateClientAffiliateBody,
  CreateClientBody,
  GetClientAffiliatesParams,
  UpdateClientBody,
} from "./requests.interfaces";
import { CompanyPlan } from "./responses.interfaces";

export const usersService = globalApi.injectEndpoints({
  endpoints: (builder) => ({
    createClient: builder.mutation<Company, CreateClientBody>({
      query: ({ manager_id, ...body }) => ({
        url: usersRoutes.createCompanyRT(manager_id),
        method: "POST",
        body,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "email", "already exists")) {
            toast.warn("El correo electrónico ya se encuentra registrado");
          } else if (includesAll(message, "rfc", "already exists")) {
            toast.warn("El RFC ya se encuentra registrado");
          } else {
            toast.error("Error al crear el cliente, intente nuevamente");
          }
        };
        handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Cliente creado correctamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["client-companies"]),
    }),
    getCompanies: builder.query<PaginateRes<Company[]>, GetUsersParams>({
      query: (params) => ({
        url: usersRoutes.getCompaniesRT(),
        params,
      }),
      transformResponse: (response: { data: PaginateRes<Company[]> }) =>
        response.data,
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(queryFulfilled, "Error al obtener los clientes");
      },
      providesTags: ["client-companies"],
    }),
    deleteClient: builder.mutation<void, string>({
      query: (id) => ({
        url: usersRoutes.deleteCompanyRT(id),
        method: "DELETE",
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al eliminar el cliente",
          successMessage: "Cliente eliminado correctamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["client-companies"]),
    }),
    updateClient: builder.mutation<void, UpdateClientBody>({
      query: ({ company_id, ...body }) => ({
        url: usersRoutes.updateCompanyRT(company_id),
        method: "PUT",
        body,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "email", "already exists")) {
            toast.warn("El correo electrónico ya se encuentra registrado");
          } else if (includesAll(message, "rfc", "already exists")) {
            toast.warn("El RFC ya se encuentra registrado");
          } else {
            toast.error(
              "Error al actualizar los datos del cliente, intente nuevamente"
            );
          }
        };
        handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Cliente actualizado correctamente",
        });
      },
      invalidatesTags: (_, err) =>
        err ? [] : ["client-companies", "user details"],
    }),
    getClientAffiliates: builder.query<
      PaginateRes<Affiliate[]>,
      GetClientAffiliatesParams
    >({
      query: ({ company_id, ...params }) => ({
        url: usersRoutes.getCompanyAffiliatesRT(company_id),
        params,
      }),
      transformResponse: (response: { data: PaginateRes<Affiliate[]> }) =>
        response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(
          queryFulfilled,
          "Error al obtener los afiliados del cliente"
        );
      },
      providesTags: ["company affiliates"],
    }),
    createClientAffiliate: builder.mutation<
      Affiliate,
      CreateClientAffiliateBody
    >({
      query: ({ company_id, ...body }) => ({
        url: usersRoutes.createCompanyAffiliateRT(company_id),
        method: "POST",
        body,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        const onError = ({ message }: ErrorResponse) => {
          if (includesAll(message, "email", "already exists")) {
            toast.warn("El correo electrónico ya está en uso");
          } else if (includesAll(message, "rfc", "already exists")) {
            toast.warn("El RFC ya está en uso");
          } else if (includesAll(message, "curp", "already exists")) {
            toast.warn("El CURP ya está en uso");
          } else {
            toast.error(
              "Ha ocurrido un error al crear al usuario, intente nuevamente"
            );
          }
        };
        handleServiceRequest(queryFulfilled, {
          onError,
          successMessage: "Afiliado creado correctamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["company affiliates"]),
    }),
    deleteClientAffiliate: builder.mutation<void, string>({
      query: (affiliateId) => ({
        url: usersRoutes.deleteCompanyAffiliateRT(affiliateId),
        method: "DELETE",
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(queryFulfilled, {
          errorMessage: "Error al eliminar el afiliado",
          successMessage: "Afiliado eliminado correctamente",
        });
      },
      invalidatesTags: (_, err) => (err ? [] : ["company affiliates"]),
    }),
    getCompanyPlans: builder.query<CompanyPlan[], string>({
      query: (companyId) => ({
        url: companyRoutes.getCompanyPlansRT(companyId),
      }),
      transformResponse: (response: { data: CompanyPlan[] }) => response.data,
      onQueryStarted: (_, { queryFulfilled }) => {
        handleServiceRequest(queryFulfilled, "Error al obtener los planes");
      },
      providesTags: ["company plans"],
    }),
  }),
});

export const {
  useGetCompaniesQuery: useGetCompanies,
  useCreateClientMutation: useCreateClient,
  useUpdateClientMutation: useUpdateClient,
  useDeleteClientMutation: useDeleteClient,
  useGetCompanyPlansQuery: useGetCompanyPlans,
  useGetClientAffiliatesQuery: useGetClientAffiliates,
  useDeleteClientAffiliateMutation: useDeleteClientAffiliate,
  useCreateClientAffiliateMutation: useCreateClientAffiliate,
} = usersService;
