import { useRef } from "react";
import { useClickOutside } from "react-haiku";
import * as T from "../../../../shared/components/styled";
import { ActionsCellBox } from "../../../../shared/components/styled/ActionsCellBox";
import { getPhoneWithFormat } from "../../../../shared/helpers/getPhoneWithFormat";
import { useMyNavigate, useToggle } from "../../../../shared/hooks";
import { ActionsCellStyles } from "../../../../shared/styles";
import { Company } from "../../../../shared/types";
import { If } from "../../../../shared/utilities/If";
import * as S from "./ClientTable.styles";

interface Props {
  client: Company;
}

export const ClientTableRow = ({ client }: Props) => {
  const myNavigate = useMyNavigate();
  const [isActionsOpen, toggle, setIsActionsOpen] = useToggle(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setIsActionsOpen(false));

  const goToClientDetails = (isEditMode: boolean) => {
    isEditMode = client.company_is_deleted ? false : isEditMode;
    return () => {
      myNavigate({
        name: "Detalles de cliente",
        params: { userId: client.user_id },
        query: { isEditMode },
      });
    };
  };

  return (
    <T.TableRow style={{ position: "relative" }}>
      <T.TableCell pointer onClick={goToClientDetails(false)}>
        {client.user_fullname}
      </T.TableCell>
      <T.TableCell>{client.company_rfc}</T.TableCell>
      <T.TableCell>{getPhoneWithFormat(client.user_phone)}</T.TableCell>
      <T.TableCell>{client.user_email}</T.TableCell>
      <T.TableCell>
        <S.StatusBox status={client.user_status}>
          {client.user_status}
        </S.StatusBox>
      </T.TableCell>
      <If showIf={!isActionsOpen}>
        <T.TableCell onClick={toggle} extraStyles={ActionsCellStyles}>
          <div />
          <div />
          <div />
        </T.TableCell>
      </If>
      <If showIf={isActionsOpen}>
        <T.TableCell extraStyles={ActionsCellStyles}>
          <div />
          <div />
          <div />
        </T.TableCell>
      </If>
      <If showIf={isActionsOpen}>
        <ActionsCellBox ref={ref}>
          <div onClick={goToClientDetails(true)}>
            {client.company_is_deleted ? "Ver detalles" : "Editar Empresa"}
          </div>
        </ActionsCellBox>
      </If>
    </T.TableRow>
  );
};
