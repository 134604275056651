import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AiOutlineMail } from "react-icons/ai";
import { toast } from "react-toastify";
import { MyInput } from "../../../../shared/components/inputs";
import { Button } from "../../../../shared/components/ui/button";
import {
  ForgotPasswordFormProps,
  ForgotPasswordFormShape,
} from "../../schemas";
import { useSendRecoveryEmail } from "../../services";
import * as S from "./ForgotPasswordForm.styles";

type Key = keyof ForgotPasswordFormProps;

interface Props {
  onCanceled: () => void;
}

export const ForgotPasswordForm = ({ onCanceled }: Props) => {
  const methods = useForm<ForgotPasswordFormProps>({
    resolver: yupResolver(ForgotPasswordFormShape),
  });

  const [sendEmail, { isLoading: isSending }] = useSendRecoveryEmail();

  const onSubmit = (data: ForgotPasswordFormProps) => {
    toast.dismiss();
    sendEmail(data.email).unwrap().then(onCanceled);
  };

  return (
    <S.ForgotPasswordContainer
      methods={methods}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <p className="send-email-text">
        Ingrese su correo electrónico para la recuperación de su contraseña
      </p>
      <MyInput<Key>
        name="email"
        IconLeft={AiOutlineMail}
        label="Correo electrónico"
        placeholder="Ingrese su correo electrónico"
      />
      <S.ButtonsContainer>
        <Button xl width="100%" loading={isSending}>
          Enviar código
        </Button>
        <Button
          secondary
          xl
          width="100%"
          onClick={onCanceled}
          disabled={isSending}
        >
          Cancelar
        </Button>
      </S.ButtonsContainer>
    </S.ForgotPasswordContainer>
  );
};
