import {
  FetchBaseQueryMeta,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { QueryFulfilledRejectionReason } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { toast } from "react-toastify";
import { ErrorResponse } from "../interfaces";

type ServiceQuery<T> = PromiseWithKnownReason<
  {
    data: T;
    meta: FetchBaseQueryMeta | undefined;
  },
  QueryFulfilledRejectionReason<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >
  >
>;

interface HelperOptions<T> {
  errorMessage?: string;
  successMessage?: string;
  onSuccess?: (data: T) => void;
  onError?: (error: ErrorResponse) => void;
}

type SecondParameter<T> = HelperOptions<T> | string;

export const handleServiceRequest = async <T>(
  serviceQuery: ServiceQuery<T>,
  options: SecondParameter<T> = {}
) => {
  try {
    const res = await serviceQuery;
    if (typeof options !== "string") {
      options.onSuccess?.(res.data);
      if (options.successMessage) {
        toast.dismiss();
        toast.success(options.successMessage);
      }
    }
    return res.data;
  } catch (error) {
    const errorResponse = (error as any)?.error?.data;
    console.error(errorResponse);
    if (typeof options === "string") {
      toast.dismiss();
      toast.error(options);
    } else {
      if (options.errorMessage) {
        toast.dismiss();
        toast.error(options.errorMessage);
      }
      options.onError?.(errorResponse);
    }
  }
};
