import React, { useCallback, useState } from "react";
import { useGetCoverage } from "../../../../redux/rtk-query";
import { CoverageSelector } from "../../../../shared/components/coverage-selector";
import { DrawingManager } from "../../../../shared/components/map/DrawingManager";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { ProviderCoverageMap } from "../../../providers/components/provider-coverage-map";

interface Props {
  drawingMode?: any;
  isEditMode: boolean;
  onDeleteCircle?: (id: string) => void;
  onCircleLoaded?: (circle: google.maps.Circle) => void;
  onCircleComplete: (circle: google.maps.Circle) => void;
}

export const ManagerCoveragesMap = ({
  isEditMode,
  drawingMode,
  onDeleteCircle,
  onCircleLoaded,
  onCircleComplete,
}: Props) => {
  const [map, setMap] = useState<google.maps.Map>();

  const { data: coverages = [], isLoading: isGettingCoverages } =
    useGetCoverage();

  const onMapLoaded = useCallback((mapLoaded: google.maps.Map) => {
    setMap(mapLoaded);
  }, []);

  return (
    <GlobalContentBox
      style={{ height: "100%" }}
      isLoading={isGettingCoverages}
      contentStyle={{
        padding: ".5rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <CoverageSelector coverages={coverages} map={map} />
      <ProviderCoverageMap
        zoom={10}
        canDelete={false}
        coverages={coverages}
        canDeleteCoverage
        onMapLoaded={onMapLoaded}
        circleEditable={isEditMode}
        addNewCircle={onCircleLoaded}
        onDeleteCircle={onDeleteCircle}
      >
        <DrawingManager
          visible={false}
          drawingMode={drawingMode}
          onCircleComplete={onCircleComplete}
        />
      </ProviderCoverageMap>
    </GlobalContentBox>
  );
};
