import styled from "styled-components";
import { EventStatus } from "../events-table/EventsTable.styles";

export const WatchReportCell = styled.span`
  cursor: pointer;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blue};
`;

export const HistoricEvaluationCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  svg {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.alert};
  }

  span {
    font-size: 1rem;
    font-weight: bold;
  }
`;
/* --------------------------------------------------------- */

export const EventStatusCell = styled(EventStatus)`
  font-weight: 500;
`;
