import { yupResolver } from "@hookform/resolvers/yup";
import React, { useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { MyInput } from "../../../../shared/components/inputs";
import { PhoneInput } from "../../../../shared/components/inputs/PhoneInput";
import { getNumberCountryCode } from "../../../../shared/helpers";
import { If } from "../../../../shared/utilities/If";
import { useAdminClientDetailsCtx } from "../../context/admin-client-details-ctx";
import {
  UpdateAdminClientFormProps,
  UpdateAdminClientFormShape,
} from "../../schemas";
import * as S from "./AdminClientInfoForm.styles";

type Keys = keyof UpdateAdminClientFormProps;

export const AdminClientInfoForm = React.forwardRef(
  function AdminClientInfoForm(_, ref) {
    const { isEditMode, adminClient } = useAdminClientDetailsCtx();

    const methods = useForm<UpdateAdminClientFormProps>({
      resolver: yupResolver(UpdateAdminClientFormShape),
      mode: "onChange",
      defaultValues: {
        rfc: adminClient?.client_rfc,
        email: adminClient?.user_email,
        fullname: adminClient?.user_fullname,
        full_phone: adminClient?.user_phone,
        phone:
          adminClient?.user_phone &&
          getNumberCountryCode(adminClient.user_phone).phone,
        country_code:
          adminClient?.user_phone &&
          getNumberCountryCode(adminClient.user_phone).code,
      },
    });

    useImperativeHandle(ref, () => ({
      validateForm: async () => {
        const values = methods.getValues();
        const isValid = await methods.trigger();
        return { isValid, values };
      },
    }));

    return (
      <S.AdminClientInfoFormWrapper
        methods={methods}
        isEditMode={isEditMode}
        onSubmit={methods.handleSubmit(() => {})}
      >
        <If showIf={isEditMode}>
          <PhoneInput<Keys>
            autoFocus
            gap="1.5rem"
            name="phone"
            label="Teléfono"
            auto_focus_select
            auto_select={false}
            className="phone-input"
            readOnly={!isEditMode}
          />
        </If>
        <MyInput<Keys>
          name="fullname"
          label="Contacto Principal"
          readOnly={!isEditMode}
          autoFocus
        />
        <MyInput<Keys>
          name="rfc"
          label="RFC"
          readOnly={!isEditMode}
          autoFocus
        />
        <If showIf={!isEditMode}>
          <MyInput<Keys>
            name="full_phone"
            label="Numero de teléfono"
            className="phone-input"
            readOnly={!isEditMode}
            autoFocus
          />
        </If>
        <MyInput<Keys>
          name="email"
          label="Correo electrónico"
          className="email-input"
          readOnly={!isEditMode}
          autoFocus
        />
      </S.AdminClientInfoFormWrapper>
    );
  }
);
