import * as Yup from "yup";
import {
  emailRequired,
  phoneRequired,
  stringOptional,
  stringRequired,
  validPassword,
} from "../../../shared/schemas";

export const NewPasswordShape = Yup.object({
  oldPassword: stringRequired,
  newPassword: validPassword,
  confirmPassword: stringRequired.oneOf(
    [Yup.ref("newPassword"), null],
    "Las contraseñas deben coincidir"
  ),
});

export type NewPasswordProps = Yup.InferType<typeof NewPasswordShape>;

/* ------------------------------------------------------------------- */

export const UpdateProfileFormShape = Yup.object({
  email: emailRequired,
  phone: phoneRequired,
  fullname: stringRequired,
  complete_name: stringOptional,
});

export const UpdateProfileFormShape2 = UpdateProfileFormShape.shape({
  surname: stringRequired,
});

export type UpdateProfileFormProps = Yup.InferType<
  typeof UpdateProfileFormShape2
>;

/* ------------------------------------------------------------------- */
