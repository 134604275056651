import { toast } from "react-toastify";
import { generalRoutes, globalApi } from "../../../redux/rtk-query";
import { handleServiceRequest } from "../../../shared/helpers/handleServiceRequest";
import { ErrorResponse } from "../../../shared/interfaces";
import { LoginBody, ValidateCodeBody } from "./requests.interfaces";
import { LoginResponse } from "./responses.interfaces";

export const authService = globalApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginBody>({
      query: (body) => ({
        url: generalRoutes.loginRT(),
        method: "POST",
        body,
      }),
      transformResponse: (response: { data: LoginResponse }) => response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        await handleServiceRequest(queryFulfilled, {
          errorMessage:
            "Hubo un problema para validar sus datos, por favor inténtelo nuevamente.",
        });
      },
    }),
    validateUser: builder.query<void, string>({
      query: (accessToken) => ({
        url: generalRoutes.validateUserRT(),
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    }),
    validateCode: builder.mutation<void, ValidateCodeBody>({
      query: ({ code, accessToken }) => ({
        url: generalRoutes.validateCodeRT(),
        headers: { Authorization: `Bearer ${accessToken}` },
        method: "POST",
        body: { code },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = ({ statusCode }: ErrorResponse) => {
          if (statusCode === 400) {
            toast.error(
              "El código ingresado es incorrecto, por favor inténtelo nuevamente."
            );
          } else {
            toast.error(
              "Hubo un problema para validar su código, por favor inténtelo nuevamente."
            );
          }
        };
        await handleServiceRequest(queryFulfilled, { onError });
      },
    }),
    sendRecoveryEmail: builder.mutation<void, string>({
      query: (email) => ({
        url: generalRoutes.sendRecoveryEmailRT(),
        method: "POST",
        body: { email },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        const onError = (res: ErrorResponse) => {
          console.log("🚀 ~ file: auth.service.ts:55 ~ onError ~ res", res);
          if (res.statusCode === 404) {
            toast.warn("El correo ingresado no se encuentra registrado.", {
              autoClose: 7000,
              style: { fontSize: "1rem" },
            });
          } else {
            toast.error(
              "Hubo un problema para enviar el correo de recuperación, por favor inténtelo nuevamente."
            );
          }
        };
        const onSuccess = () => {
          toast.success("Correo de recuperación enviado con éxito.", {
            autoClose: 7000,
            style: { fontSize: "1rem" },
          });
        };
        await handleServiceRequest(queryFulfilled, { onError, onSuccess });
      },
    }),
  }),
});

export const {
  useLoginMutation: useLogin,
  useLazyValidateUserQuery: useValidateUser,
  useValidateCodeMutation: useValidateCode,
  useSendRecoveryEmailMutation: useSendRecoveryEmail,
} = authService;
