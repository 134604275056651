import { AllRoutes, RouteName } from "../../../router";
import { UserRole } from "../../../shared/enums";

// Function to validate if the user has the required role to access the route or to access the web app

type UserRouteFunc = (role?: UserRole) => {
  name: RouteName;
  path: string;
};

export const validateUserRoute: UserRouteFunc = (role) => {
  let name: RouteName;
  switch (role) {
    case "Gerente":
      name = "Clientes";
      break;
    case "Operador":
      name = "Inicio";
      break;
    case "Proveedor":
      name = "Vehículos";
      break;
    case "Empresa":
      name = "Afiliados";
      break;
    case "Cliente":
      name = "usuarios";
      break;
    default:
      name = "Sin acceso";
  }
  const path = AllRoutes.find((route) => route.name === name)?.path as string;
  return {
    name,
    path,
  };
};
