import React from "react";
import * as T from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { TablePagination } from "../../../../shared/components/ui/table-title";
import { TableWithPaginationProps } from "../../../../shared/types/paginate";
import { For } from "../../../../shared/utilities/For";
import { WorkerWithVehicle } from "../../services";
import { ProviderWorkersRow } from "./ProviderWorkersRow";

interface Props extends TableWithPaginationProps {
  workers: WorkerWithVehicle[];
}

const titles = [
  "Nombre",
  "Número de teléfono",
  "Correo electrónico",
  "Placa de vehículo",
  "Estatus",
  "Disponibilidad",
];

const columns = "repeat(6, 1fr)";

export const ProviderWorkersTable = ({
  workers,
  pageCount,
  isLoading,
  onPageChange,
}: Props) => {
  const pagination = (
    <TablePagination
      pageCount={pageCount}
      onPageChange={onPageChange}
      title="Listado de trabajadores"
    />
  );
  return (
    <GlobalContentBox title={pagination} isLoading={isLoading}>
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={titles}
            render={(title) => <T.TableTitle>{title}</T.TableTitle>}
          />
        </T.TableTitlesBox>
        <T.TableBody gridColumns={columns}>
          <For
            each={workers}
            render={(worker) => <ProviderWorkersRow worker={worker} />}
          />
        </T.TableBody>
      </T.Table>
    </GlobalContentBox>
  );
};
