import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectIsDeleteAffiliateModal,
  setIsDeleteAffiliateModal,
} from "../../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../../redux/store";
import { Box } from "../../../../../shared/components/styled";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { Affiliate } from "../../../../../shared/types";
import { useDeleteClientAffiliate } from "../../../services";

interface Props {
  affiliate?: Affiliate;
}

export const DeleteAffiliateModal = ({ affiliate }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isOpen = useSelector(selectIsDeleteAffiliateModal);
  const [deleteAffiliate, { isLoading }] = useDeleteClientAffiliate();

  const onClose = () => {
    dispatch(setIsDeleteAffiliateModal(false));
  };

  const onDelete = () => {
    if (!affiliate) return;
    deleteAffiliate(affiliate?.affiliate_id).then(() => {
      onClose();
      navigate(-1);
    });
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title="Eliminar afiliado"
    >
      <Box fontSize="1rem" style={{ fontWeight: 500, textAlign: "center" }}>
        {`¿Desea eliminar al afiliado ${affiliate?.user_fullname}?`}
      </Box>
      <PageButtons
        show
        onCancel={onClose}
        onProceed={onDelete}
        isLoading={isLoading}
        proceedText="Si, eliminar"
      />
    </BaseModal>
  );
};
