import styled from "styled-components";
import { Form } from "../../../../shared/components/form";

interface Props {
  isEditMode: boolean;
}

export const OperatorInfoFormWrapper = styled(Form)<Props>`
  width: 100%;
  display: grid;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1rem;

  grid-template-columns: ${({ isEditMode }) =>
    isEditMode ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"};

  > * {
    align-self: flex-start;
  }
`;
