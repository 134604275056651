import { PageContainer, PageTitle } from "../../../../shared/components/styled";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { CreateEventForm } from "../../components/create-event";

export const CreateEventPage = () => {
  return (
    <PageContainer>
      <PageTitle>Crear evento</PageTitle>
      <GlobalContentBox>
        <CreateEventForm />
      </GlobalContentBox>
    </PageContainer>
  );
};
