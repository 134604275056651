import React, { useMemo } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { Button } from "../../../../../shared/components/ui/button";
import { EventStatus } from "../../../../../shared/enums";
import { For } from "../../../../../shared/utilities/For";
import { If } from "../../../../../shared/utilities/If";
import { Message } from "../../../models/interfaces";
import { OperatorChatMessage } from "./OperatorChatMessage";
import * as S from "./OperatorChatModal.styles";

interface Props {
  isOpen: boolean;
  messages: Message[];
  userImg: string;
  operatorImg: string;
  eventStatus: EventStatus;
  closeModal: () => void;
  onSendMessage: (message: string) => void;
}

export const OperatorChatModal = ({
  closeModal,
  onSendMessage,
  messages,
  isOpen,
  userImg,
  operatorImg,
  eventStatus,
}: Props) => {
  const inputMsg = React.useRef<HTMLInputElement>(null);
  const messageList = React.useRef<HTMLUListElement>(null);

  const hideInput = useMemo(
    () => (["Cancelado", "Finalizado"] as EventStatus[]).includes(eventStatus),
    [eventStatus]
  );

  const onSendMsg = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inputMsg.current?.value.trim()) return;
    onSendMessage(inputMsg.current.value);
    inputMsg.current.value = "";
    inputMsg.current.focus();
  };

  const showPicture = (msg: Message, index: number) => {
    const bef = messages[index - 1];
    if (!bef) return true;
    return msg.role !== bef.role;
  };

  const afterOpen = React.useCallback(() => {
    inputMsg.current?.focus();
    messageList.current?.scrollTo(0, messageList.current.scrollHeight);
  }, []);

  React.useEffect(() => {
    messageList.current?.scrollTo(0, messageList.current.scrollHeight);
  }, [messages.length]);

  return (
    <BaseModal
      width="35rem"
      title="Chat"
      isOpen={isOpen}
      onAfterOpen={afterOpen}
      onRequestClose={closeModal}
    >
      <S.MessagesContainer>
        <S.MessagesList ref={messageList}>
          <For
            each={messages}
            render={(msg, index) => (
              <OperatorChatMessage
                msg={msg}
                operatorImg={operatorImg}
                userImg={userImg}
                showPicture={showPicture(msg, index)}
              />
            )}
          />
        </S.MessagesList>
        <If showIf={!hideInput}>
          <S.InputWrapper onSubmit={onSendMsg}>
            <S.Input placeholder="Escribe un mensaje" ref={inputMsg} />
            <Button Icon={IoIosArrowForward} />
          </S.InputWrapper>
        </If>
      </S.MessagesContainer>
      <Button xl width="100%" onClick={closeModal}>
        Cerrar
      </Button>
    </BaseModal>
  );
};
