import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { PrivateComponent } from "../../../../modules/auth/components/private-component";
import { RouteProps } from "../../../../router/interfaces";
import * as S from "./SidebarItem.styles";
import { selectUser } from "../../../../modules/auth/redux";
import { If } from "../../../utilities/If";

interface Props {
  route: RouteProps;
}

export const SidebarItem = ({ route }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const user = useSelector(selectUser);

  const isActive = useMemo(
    () => pathname.includes(route.path),
    [pathname, route.path]
  );

  // const hideAffiliatesToCall = useMemo(() => {
  //   if (!user || user?.role_name !== "Operador") return false;
  //   return !!user.operator_companyId && route.name === "Usuarios";
  // }, [route.name, user]);

  const onNavigate = () => {
    navigate(route.path);
  };

  return (
    <PrivateComponent roles={route.rolesAllowed}>
      {/* <If showIf={!hideAffiliatesToCall}> */}
      <S.SidebarItemWrapper
        active={isActive}
        onClick={onNavigate}
        to={route.path}
      >
        {route.Icon && <route.Icon />}
        <p>{route.name}</p>
      </S.SidebarItemWrapper>
      {/* </If> */}
    </PrivateComponent>
  );
};
