import styled from "styled-components";

export const PublicLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;

  background-image: url("/assets/public-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
