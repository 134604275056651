import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { UploadAffiliatesResponse } from "../services/responses.interfaces";
import { AffiliatesExcelErrorsList } from "../components/affiliates-excel-errors-list";

const MySwal = withReactContent(Swal);

export const handleAffiliatesExcelErrors = (
  errors: UploadAffiliatesResponse
) => {
  try {
    const { joiValidationsErrors, databaseValidationsErrors } = errors;

    // There is no errors
    if (
      joiValidationsErrors.length === 0 &&
      databaseValidationsErrors.length === 0
    ) {
      toast.success("Afiliados cargados exitosamente");
      return;
    }

    // There are invalid columns
    const isInvalidFormat = joiValidationsErrors.some(
      ({ errors: myErrors }) => myErrors.length > 8
    );
    if (isInvalidFormat) {
      toast.warn(
        "El formato del archivo no es válido, asegúrese de que el archivo tenga las columnas correctas",
        {
          style: { fontSize: "1rem" },
        }
      );
      return;
    }

    const showJoiErrors = joiValidationsErrors.length > 0;

    // Columns are correct but there are errors on the data
    MySwal.fire({
      title: "Se encontraron errores en el archivo",
      html: (
        <AffiliatesExcelErrorsList
          errors={
            showJoiErrors ? joiValidationsErrors : databaseValidationsErrors
          }
          type={showJoiErrors ? "joi" : "database"}
        />
      ),
      icon: "warning",
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#3f51b5",
      customClass: {
        title: "swal-title",
        htmlContainer: "swal-text",
        confirmButton: "swal-button",
      },
      allowOutsideClick: false,
    });
  } catch (error) {
    console.error(error);
  }
};
