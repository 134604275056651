import styled, { css } from "styled-components";
import { NoVisibleScrollBar } from "../../../../shared/styles";

interface Props {
  showEvents: boolean;
}

const IsShowed = css`
  background-color: ${({ theme }) => theme.colors.gray.lightest};
  border: 0.0625rem solid ${({ theme }) => theme.colors.gray.light};
  color: inherit;
`;

export const EventsDescription = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0.7rem 0;
  justify-content: space-between;

  .events-length {
    font-size: 0.8rem;
    padding: 0.8rem 0.9rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.red};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .toggle-list-icon {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    box-sizing: content-box;
  }

  > p {
    font-weight: 500;
  }
`;

export const OperatorActiveEventsWrapper = styled.div<Props>`
  ${NoVisibleScrollBar}
  bottom: 1rem;
  min-width: 16rem;
  max-height: 40rem;
  right: 1.8rem;
  padding: 0 0.7rem;
  font-size: 1rem;
  position: absolute;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  ${({ showEvents }) => showEvents && IsShowed}

  > p {
    font-size: 0.9rem;
  }

  ${EventsDescription} {
    .toggle-list-icon {
      color: ${({ theme, showEvents }) =>
        showEvents ? theme.colors.white : theme.colors.blue};
      background-color: ${({ theme, showEvents }) =>
        !showEvents ? theme.colors.white : theme.colors.blue};
    }
  }
`;

export const ActiveEventsListWrapper = styled.ul`
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

/* ------------------------------------------- */

export const ActiveEventItem = styled.li`
  cursor: pointer;
  padding: 0.5rem;
  word-break: break-word;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.colors.white};

  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.5rem;
  }
`;

interface ItemProps {
  color: string;
}

export const IconContainer = styled.div<ItemProps>`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ color }) => color};
`;

export const InfoContainer = styled.p`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  > span {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .ticket-description {
    font-weight: 600;
  }
`;
