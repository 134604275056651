import React from "react";
import io, { Socket } from "socket.io-client";
import { useAppSelector } from "../../../redux/store";
import { EntityNameEnum, UserRoleEnum } from "../../../shared/enums";
import {
  Chat,
  EventDetails,
  Message,
  MessagePayload,
} from "../models/interfaces";

export enum ChatSocketEvents {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  RECOVER_CHATS = "recoverChats",
  CREATE_ROOM = "createRoom",
  ROOM_CREATED = "roomCreated",
  SEND_MESSAGE = "sendMessage",
  NEW_MESSAGE = "newMessage",
  JOIN_ROOM = "joinRoom",
  RECOVER_MESSAGES = "attendChat",
  CHAT_COMPLETED = "chatCompleted",
}

export const CHAT_SOCKET_CONNECTION_URL =
  process.env.REACT_APP_CHAT_SOCKET_URL || "";
export const CHAT_SOCKET_CONNECTION_PATH = "/chats";

export const useGetAffiliateChat = (event?: EventDetails) => {
  const operatorSurname = useAppSelector(
    (state) => state.auth.user?.user_surname
  );
  const operatorFullname = useAppSelector(
    (state) => state.auth.user?.user_fullname
  );
  const operatorId = useAppSelector((state) => state.auth.user?.user_id);

  const room = React.useRef<string>();

  const [socket, setSocket] = React.useState<Socket<any, any>>();
  const [messages, setMessages] = React.useState<Message[]>([]);

  const eventId = event?._id as string;
  const clientId = event?.affiliate_id as string;
  const clientName = `${event?.affiliate_fullname} ${event?.affiliate_surname}`;
  const operatorName = `${event?.operator_fullname || operatorFullname} ${
    event?.operator_surname || operatorSurname
  }`;

  // Function to complete chat
  const completeChat = React.useCallback(() => {
    const body = room.current;
    console.log("completeChat affiliate", body);
    socket?.emit(ChatSocketEvents.CHAT_COMPLETED, body);
  }, [socket, room]);

  // Function to send a message
  const sendMessage = React.useCallback(
    (
      messagePayload: MessagePayload,
      onMessageSent?: (msg: Message) => void
    ) => {
      socket?.emit(
        ChatSocketEvents.SEND_MESSAGE,
        messagePayload,
        onMessageSent
      );
    },
    [socket]
  );

  // Function to recover the messages if chat already exists
  const recoverMessages = React.useCallback(
    (roomId: string) => {
      const body = {
        roomId,
        username: operatorName,
        userId: operatorId,
        role: EntityNameEnum.OPERATOR,
      };
      socket?.emit(
        ChatSocketEvents.RECOVER_MESSAGES,
        body,
        (chat: { messages?: Message[] }) => {
          console.log("affiliate messages", chat);
          const validMessages = chat.messages?.filter((msg) => msg.message);
          setMessages(validMessages || []);
        }
      );
    },
    [socket, operatorName, operatorId]
  );

  // Function to create room
  const createRoom = React.useCallback(async () => {
    const client = {
      reason: "Evento creado",
      cellphone: event?.affiliate_phone,
      username: clientName,
      status: "pending",
      role: EntityNameEnum.CLIENT,
      userId: clientId,
      eventId,
    };

    console.log("create affiliate room body", client);

    const roomId = await new Promise<string>((resolve) => {
      socket?.emit(
        ChatSocketEvents.CREATE_ROOM,
        client,
        (payload: { roomId: string }) => {
          console.log("new affiliate room payload", payload);
          room.current = payload.roomId;
          resolve(payload.roomId);
        }
      );
    });

    recoverMessages(roomId);
  }, [
    event?.affiliate_phone,
    eventId,
    socket,
    clientName,
    clientId,
    recoverMessages,
  ]);

  // Function to add a message to the list
  const addMessage = React.useCallback((msg: Message) => {
    setMessages((lastMessages) => [...lastMessages, msg]);
  }, []);

  // Function to send operator message
  const sendOperatorMessage = React.useCallback(
    (msg: string) => {
      const messageData = {
        roomId: room.current as string,
        message: msg,
        role: EntityNameEnum.OPERATOR,
        userId: operatorId as string,
        username: operatorName,
      };

      console.log("messageData", messageData);

      sendMessage(messageData, (myMsg) => {
        console.log("affiliate message sent", myMsg);
        addMessage(myMsg);
      });
    },
    [operatorName, operatorId, sendMessage, addMessage]
  );

  // Connect to socket
  React.useLayoutEffect(() => {
    const authorization = `Bearer ${localStorage.getItem("access")}`;
    const mySocket = io(CHAT_SOCKET_CONNECTION_URL, {
      path: CHAT_SOCKET_CONNECTION_PATH,
      transports: ["polling"],
      extraHeaders: { authorization },
    });
    setSocket(mySocket);
  }, []);

  // Socket to recover all chats
  React.useEffect(() => {
    socket?.emit(ChatSocketEvents.RECOVER_CHATS, (payload: Chat[]) => {
      console.log("chats", payload);
      const chatFound = payload.find(
        (chat) => chat.roomId === `room${eventId}${clientId}`
      );
      console.log("affiliate chat found", chatFound);
      if (chatFound) {
        console.log("Affiliate chat found", chatFound);
        recoverMessages(chatFound.roomId);
        room.current = chatFound.roomId;
      } else {
        console.log("Affiliate chat not found");
        createRoom();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, eventId, socket]);

  // Socket to receive new messages
  React.useEffect(() => {
    socket?.on(ChatSocketEvents.NEW_MESSAGE, (payload: Message) => {
      console.log("new affiliate chat message", payload);
      if (payload.role === UserRoleEnum.AFFILIATE) {
        addMessage(payload);
      }
    });
  }, [socket, addMessage]);

  // Clean up
  React.useEffect(
    () => () => {
      socket?.off(ChatSocketEvents.CONNECT);
      socket?.off(ChatSocketEvents.ROOM_CREATED);
      socket?.off(ChatSocketEvents.NEW_MESSAGE);
      socket?.disconnect();
    },
    [socket]
  );

  return {
    messages,
    completeChat,
    sendOperatorMessage,
    socket,
  };
};
