import styled from "styled-components";
import { SharedModalStyles } from "../../../../../shared/styles";

export const DeleteClientFormWrapper = styled.div`
  ${SharedModalStyles}

  > p {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }
`;
