import { useMemo } from "react";
import { Message } from "../../../models/interfaces";
import * as S from "./OperatorChatModal.styles";

interface Props {
  msg: Message;
  operatorImg: string;
  userImg: string;
  showPicture: boolean;
}

export const OperatorChatMessage = ({
  msg,
  userImg,
  operatorImg,
  showPicture,
}: Props) => {
  const isOperator = useMemo(
    () => msg?.role?.toLowerCase() === "operator",
    [msg.role]
  );

  const imgUrl = useMemo(
    () => (isOperator ? operatorImg : userImg),
    [isOperator, operatorImg, userImg]
  );

  return (
    <S.Message isOperator={isOperator} showPicture={showPicture}>
      <img src={imgUrl} alt="profile" />
      <S.MessageContent>
        <div className="title">{msg.username}</div>
        <div className="message">{msg.message}</div>
      </S.MessageContent>
    </S.Message>
  );
};
