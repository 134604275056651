import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback, useMemo, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaCheck, FaPlus, FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CoverageSelector } from "../../../../shared/components/coverage-selector";
import { DrawingManager } from "../../../../shared/components/map/DrawingManager";
import {
  Box,
  PageContainer,
  PageTitle,
} from "../../../../shared/components/styled";
import { Button } from "../../../../shared/components/ui/button";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { validatePolygonPaths } from "../../../../shared/helpers";
import { useToggle } from "../../../../shared/hooks";
import { Supplier } from "../../../../shared/types";
import { If } from "../../../../shared/utilities/If";
import { selectUser } from "../../../auth/redux";
import { ProviderCoverageMap } from "../../components/provider-coverage-map";
import {
  useCreateProviderZones,
  useGetProviderCoverage,
  useGetProviderZones,
  useUpdateProviderZone,
} from "../../services";

export type LatLng = google.maps.LatLng;
export type Polygon = google.maps.Polygon;
export type LatLngLiteral = google.maps.LatLngLiteral;

export const ProviderCoveragePage = () => {
  const provider = useSelector(selectUser) as Supplier | null;

  const [createZone, { isLoading: isCreating }] = useCreateProviderZones();
  const { data: coverages = [], isLoading: isLoadingCoverages } =
    useGetProviderCoverage();
  const { data: zones = [], isFetching: isLoadingZones } = useGetProviderZones(
    provider?.supplier_id || skipToken
  );
  const { isLoading: isUpdating } = useUpdateProviderZone({
    fixedCacheKey: "updateZone",
  })[1];

  const [map, setMap] = useState<google.maps.Map>();
  const [editMode, toggleEditMode] = useToggle();
  const [createMode, toggleCreateMode] = useToggle();
  const [polygons, setPolygons] = useState<Polygon[]>([]);
  const [zonesToCreate, setZonesToCreate] = useState<LatLngLiteral[][]>([]);

  const drawingMode = useMemo<any>(
    () => (createMode ? "polygon" : undefined),
    [createMode]
  );

  const onMapLoaded = useCallback((mapLoaded: google.maps.Map) => {
    setMap(mapLoaded);
  }, []);

  const onCreateZonesEnd = () => {
    toggleCreateMode();
    setPolygons([]);
    setZonesToCreate([]);
  };

  const onZonesCreatedSuccess = () => {
    onCreateZonesEnd();
    window.location.reload();
  };

  const onZonesCreatedError = () => {
    onCreateZonesEnd();
    polygons.forEach((polygon) => polygon.setMap(null));
  };

  const onCreateZones = () => {
    createZone({
      zones: zonesToCreate,
      // TODO: when the application supports multiple coverages, this should be changed
      coverageId: coverages[0]?.coverage_id || "",
    })
      .unwrap()
      .then(onZonesCreatedSuccess)
      .catch(onZonesCreatedError);
  };

  const onCanceled = () => {
    toggleCreateMode();
    setZonesToCreate([]);
    polygons.forEach((polygon) => polygon.setMap(null));
    setPolygons([]);
  };

  const onPolygonComplete = useCallback(
    (polygon: Polygon) => {
      const { coords, isNotValid } = validatePolygonPaths(polygon, coverages);

      if (isNotValid) {
        toast.warning("No se puede crear una zona fuera de la cobertura");
        polygon.setMap(null);
      } else {
        setPolygons((prev) => [...prev, polygon]);
        setZonesToCreate((prev) => [...prev, coords]);
      }
    },
    [coverages]
  );

  return (
    <PageContainer>
      <Box flex justifyBetween alignCenter>
        <PageTitle>Cobertura</PageTitle>
        <If showIf={!createMode && !editMode}>
          <Box flex gap="1rem">
            <Button
              width="11rem"
              fontSize="1rem"
              loaderSize="1.3rem"
              text="Editar zonas"
              loading={isUpdating}
              Icon={AiOutlineEdit}
              onClick={toggleEditMode}
            />
            <Button
              Icon={FaPlus}
              width="12rem"
              fontSize="1rem"
              text="Agregar zona"
              onClick={toggleCreateMode}
            />
          </Box>
        </If>
        <If showIf={createMode}>
          <Box flex gap="1rem">
            <Button
              secondary
              Icon={FaTimes}
              fontSize="1rem"
              onClick={onCanceled}
              disabled={isCreating}
              text="Cancelar"
            />
            <Button
              Icon={FaCheck}
              fontSize="1rem"
              loading={isCreating}
              onClick={onCreateZones}
              text="Guardar cambios"
              width="13.375rem"
            />
          </Box>
        </If>
        <If showIf={editMode}>
          <Button
            secondary
            Icon={FaTimes}
            fontSize="1rem"
            onClick={toggleEditMode}
          >
            Cancelar
          </Button>
        </If>
      </Box>
      <GlobalContentBox
        style={{ height: "100%" }}
        isLoading={isLoadingCoverages || isLoadingZones}
        contentStyle={{
          padding: ".5rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <CoverageSelector coverages={coverages} map={map} />
        <ProviderCoverageMap
          zoom={10}
          zones={zones}
          editable={editMode}
          coverages={coverages}
          onMapLoaded={onMapLoaded}
        >
          <DrawingManager
            visible={false}
            drawingMode={drawingMode}
            onPolygonComplete={onPolygonComplete}
          />
        </ProviderCoverageMap>
      </GlobalContentBox>
    </PageContainer>
  );
};
