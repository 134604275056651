import { Circle, CircleProps, useGoogleMap } from "@react-google-maps/api";
import { useCallback, useRef } from "react";
import { colors } from "../../../../shared/theme";
import { Coverage } from "../../../events/models/interfaces";

interface Props {
  coverage: Coverage;
  editable?: boolean;
  onSelectCoverage?: (data: string) => void;
  addNewCircle?: (circle: google.maps.Circle) => void;
}

const options: CircleProps["options"] = {
  strokeColor: colors.blue,
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: colors.blue,
  fillOpacity: 0.35,
  visible: true,
  strokePosition: google.maps.StrokePosition.INSIDE,
};

export const CoverageItem = ({
  coverage,
  editable,
  onSelectCoverage,
  addNewCircle,
}: Props) => {
  const map = useGoogleMap();
  const circleRef = useRef<google.maps.Circle | null>(null);

  const onLoad = useCallback(
    (circle: google.maps.Circle) => {
      circleRef.current = circle;
      circle.set("id", coverage.coverage_id);
      addNewCircle?.(circle);
    },
    [addNewCircle, coverage.coverage_id]
  );

  const onDblClick = useCallback(() => {
    onSelectCoverage?.(coverage.coverage_id);
  }, [coverage.coverage_id, onSelectCoverage]);

  return (
    <Circle
      onLoad={onLoad}
      onDblClick={onDblClick}
      radius={coverage.coverage_radio}
      options={{ ...options, map, editable }}
      center={{ lat: +coverage.coverage_lat, lng: +coverage.coverage_lng }}
    />
  );
};
