import React from "react";
import { ClientAffiliate } from "../../services";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box/GlobalContentBox";
import * as T from "../../../../shared/components/styled";
import { For } from "../../../../shared/utilities/For";
import { AdminClientAffiliatesRow } from "./AdminClientAffiliatesRow";

const titles = ["Nombre", "Correo", "Tipo de servicio", "Fecha de creación"];

const columns = "repeat(4, 1fr)";

interface Props {
  affiliates: ClientAffiliate[];
  isLoading: boolean;
}

export const AdminClientAffiliatesTable = ({
  affiliates,
  isLoading,
}: Props) => {
  return (
    <GlobalContentBox isLoading={isLoading}>
      <T.Table>
        <T.TableTitlesBox gridColumns={columns}>
          <For
            each={titles}
            render={(title) => <T.TableTitle>{title}</T.TableTitle>}
          />
        </T.TableTitlesBox>
        <T.TableBody gridColumns={columns}>
          <For
            each={affiliates}
            render={(affiliate) => (
              <AdminClientAffiliatesRow affiliate={affiliate} />
            )}
          />
        </T.TableBody>
      </T.Table>
    </GlobalContentBox>
  );
};
