import React from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { RouteName } from "../../router";
import { AllRoutes } from "../../router/app.routes";

type ToProps =
  | string
  | number
  | {
      name: RouteName;
      params?: Record<string, string>;
      query?: Record<string, any>;
    };

export const useMyNavigate = () => {
  const navigate = useNavigate();

  const navigateFn = React.useCallback(
    (to: ToProps, options?: NavigateOptions) => {
      if (typeof to === "string") {
        navigate(to, options);
      } else if (typeof to === "number") {
        navigate(to);
      } else {
        const route = AllRoutes.find((routeEl) => routeEl.name === to.name);
        if (route) {
          let pathname = route.path.replace(/:(\w+)/g, (_, key) => {
            return to.params?.[key] ?? "";
          });
          // add query params
          if (to.query) {
            const query = Object.entries(to.query)
              .map(([key, value]) => `${key}=${value}`)
              .join("&");
            pathname = `${pathname}?${query}`;
          }
          navigate(pathname, options);
        }
        return route;
      }
    },
    [navigate]
  );

  return navigateFn;
};
