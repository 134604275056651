import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectIsValidating,
  startValidateAccess,
} from "../../modules/auth/redux";
import { useAppDispatch } from "../../redux/store";
import { PageLoader } from "../../shared/components/ui/PageLoader";
import { colors } from "../../shared/theme";

interface Props {
  children: React.ReactElement;
}

export const GlobalLayout = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const isValidating = useSelector(selectIsValidating);

  useEffect(() => {
    dispatch(startValidateAccess());
  }, [dispatch]);

  if (isValidating) {
    return <PageLoader color={colors.blue} showIf />;
  }
  return children;
};
