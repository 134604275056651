import { useRef } from "react";
import { useClickOutside } from "react-haiku";
import { ActionsCellBox } from "../../../../shared/components/styled/ActionsCellBox";
import * as T from "../../../../shared/components/styled/Table";
import {
  getDateWithFormat,
  getPhoneWithFormat,
} from "../../../../shared/helpers";
import { useMyNavigate, useToggle } from "../../../../shared/hooks";
import { ActionsCellStyles } from "../../../../shared/styles";
import { Operator } from "../../../../shared/types";
import { If } from "../../../../shared/utilities/If";

interface Props {
  operator: Operator;
}

export const OperatorsTableRow = ({ operator }: Props) => {
  const navigate = useMyNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [isActionsOpen, toggle, setIsActionsOpen] = useToggle(false);
  useClickOutside(ref, () => setIsActionsOpen(false));

  const goToOperatorDetails = (isEditMode: boolean) => {
    isEditMode = operator.operator_is_deleted ? false : isEditMode;
    return () => {
      navigate({
        name: "Detalles del Operador",
        params: { userId: operator.user_id },
        query: { isEditMode },
      });
    };
  };

  return (
    <T.TableRow relative>
      <T.TableCell pointer onClick={goToOperatorDetails(false)}>
        {operator.user_fullname} {operator.user_surname}
      </T.TableCell>
      <T.TableCell>{getDateWithFormat(operator.operator_birthday)}</T.TableCell>
      <T.TableCell>{getPhoneWithFormat(operator.user_phone)}</T.TableCell>
      <T.TableCell>{operator.user_email}</T.TableCell>
      <If showIf={!isActionsOpen}>
        <T.TableCell extraStyles={ActionsCellStyles} onClick={toggle}>
          <div />
          <div />
          <div />
        </T.TableCell>
      </If>
      <If showIf={isActionsOpen}>
        <T.TableCell extraStyles={ActionsCellStyles}>
          <div />
          <div />
          <div />
        </T.TableCell>
      </If>
      <If showIf={isActionsOpen}>
        <ActionsCellBox ref={ref} top="-.5rem">
          <div onClick={goToOperatorDetails(true)}>Editar</div>
        </ActionsCellBox>
      </If>
    </T.TableRow>
  );
};
