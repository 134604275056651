import React from "react";
import { Box } from "../../../../../shared/components/styled";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { useProviderVehiclesCtx } from "../../../context/provider-vehicles-ctx";
import { Vehicle } from "../../../models";
import { useDeleteVehicle } from "../../../services";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activeVehicle?: Vehicle;
}

export const DeleteVehicleModal = ({
  isOpen,
  activeVehicle,
  onClose,
}: Props) => {
  const { setActiveVehicle } = useProviderVehiclesCtx();
  const [deleteVehicle, { isLoading: isDeleting }] = useDeleteVehicle();

  const onDelete = async () => {
    if (!activeVehicle) return;
    deleteVehicle(activeVehicle.vehicle_id).unwrap().then(onClose);
  };

  const onAfterClose = () => {
    setActiveVehicle(undefined);
  };

  return (
    <BaseModal
      width="30rem"
      isOpen={isOpen}
      title="Eliminar Vehículo"
      onRequestClose={onClose}
      onAfterClose={onAfterClose}
    >
      <Box fontSize="1rem" style={{ fontWeight: 500, textAlign: "center" }}>
        {`¿Desea eliminar al vehículo con la placa ${activeVehicle?.vehicle_license_plate}?`}
      </Box>
      <PageButtons
        show
        fullWidth
        onCancel={onClose}
        onProceed={onDelete}
        isLoading={isDeleting}
        proceedText="Si, eliminar"
      />
    </BaseModal>
  );
};
