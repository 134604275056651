import styled from "styled-components";
import { Form } from "../../../../shared/components/form";

export const ForgotPasswordContainer = styled(Form)`
  gap: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  .send-email-text {
    text-align: center;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const ButtonsContainer = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
