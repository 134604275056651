import { PropsWithChildren, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

type OverlayProps = PropsWithChildren<{
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  pane?: keyof google.maps.MapPanes;
  map: google.maps.Map;
  zIndex?: number;
  style?: React.CSSProperties;
}>;

export default function OverlayView({
  position,
  pane = "floatPane",
  map,
  zIndex,
  children,
}: OverlayProps) {
  const container = useMemo(() => {
    const div = document.createElement("div");
    div.style.position = "absolute";
    return div;
  }, []);

  const overlay = useMemo(() => {
    return new Overlay(container, pane, position);
  }, [container, pane, position]);

  useEffect(() => {
    overlay?.setMap(map);
    return () => overlay?.setMap(null);
  }, [map, overlay]);

  // to move the container to the foreground and background
  useEffect(() => {
    container.style.zIndex = `${zIndex}`;
  }, [zIndex, container]);

  return createPortal(children, container);
}

export class Overlay extends google.maps.OverlayView {
  container: HTMLElement;

  pane: keyof google.maps.MapPanes;

  position: google.maps.LatLng | google.maps.LatLngLiteral;

  constructor(
    container: HTMLElement,
    pane: keyof google.maps.MapPanes,
    position: google.maps.LatLng | google.maps.LatLngLiteral
  ) {
    super();
    this.container = container;
    this.pane = pane;
    this.position = position;
  }

  onAdd(): void {
    const pane = this.getPanes()?.[this.pane];
    pane?.appendChild(this.container);
  }

  draw(): void {
    const projection = this.getProjection();
    const point = projection.fromLatLngToDivPixel(this.position);
    if (point === null) {
      return;
    }
    this.container.style.transform = `translate(${point.x}px, ${point.y}px)`;
  }

  onRemove(): void {
    if (this.container.parentNode !== null) {
      this.container.parentNode.removeChild(this.container);
    }
  }
}
