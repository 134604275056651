import styled from "styled-components";

export const SidebarListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;
  height: 100%;
`;

export const SidebarAppVersion = styled.p`
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray.light};
  position: absolute;
`;
