import React from "react";
import { Socket } from "socket.io-client";
import { useAppSelector } from "../../../redux/store";
import { EntityNameEnum, UserRoleEnum } from "../../../shared/enums";
import {
  Chat,
  EventDetails,
  Message,
  MessagePayload,
} from "../models/interfaces";
import { ChatSocketEvents } from "./useGetAffiliateChat";

export const useGetSupplierChat = (event?: EventDetails, socket?: Socket) => {
  const operatorId = useAppSelector((state) => state.auth.user?.user_id);
  const operatorSurname = useAppSelector(
    (state) => state.auth.user?.user_surname
  );
  const operatorFullname = useAppSelector(
    (state) => state.auth.user?.user_fullname
  );

  const room = React.useRef<string>();

  const [messages, setMessages] = React.useState<Message[]>([]);

  const eventId = event?._id as string;
  const workerId = event?.worker_id as string;
  const workerName = `${event?.worker_fullname} ${event?.worker_surname}`;
  const operatorName = `${event?.operator_fullname || operatorFullname} ${
    event?.operator_surname || operatorSurname
  }`;

  // Function to complete chat
  const completeChat = React.useCallback(() => {
    const body = room.current;
    console.log("completeChat worker", body);
    socket?.emit(ChatSocketEvents.CHAT_COMPLETED, body);
  }, [socket, room]);

  // Function to send a message
  const sendMessage = React.useCallback(
    (
      messagePayload: MessagePayload,
      onMessageSent?: (msg: Message) => void
    ) => {
      socket?.emit(
        ChatSocketEvents.SEND_MESSAGE,
        messagePayload,
        onMessageSent
      );
    },
    [socket]
  );

  // Function to recover the messages if chat already exists
  const recoverMessages = React.useCallback(
    (roomId: string) => {
      const body = {
        roomId,
        username: workerName,
        userId: workerId,
        role: EntityNameEnum.SUPPLIER,
      };
      socket?.emit(
        ChatSocketEvents.RECOVER_MESSAGES,
        body,
        (chat: { messages?: Message[] }) => {
          console.log("supplier messages", chat);
          const validMessages = chat.messages?.filter((msg) => msg.message);
          setMessages(validMessages || []);
        }
      );
    },
    [socket, workerName, workerId]
  );

  // Function to create room
  const createRoom = React.useCallback(async () => {
    const supplier = {
      reason: "Evento creado",
      cellphone: event?.affiliate_phone,
      username: workerName,
      status: "pending",
      role: EntityNameEnum.SUPPLIER,
      userId: workerId,
      eventId,
    };

    console.log("create worker room body", supplier);

    const roomId = await new Promise<string>((resolve) => {
      console.log("Creando room worker");
      socket?.emit(
        ChatSocketEvents.CREATE_ROOM,
        supplier,
        (payload: { roomId: string }) => {
          console.log("new worker room payload", payload);
          room.current = payload.roomId;
          resolve(payload.roomId);
        }
      );
    });

    recoverMessages(roomId);
  }, [
    event?.affiliate_phone,
    eventId,
    socket,
    workerName,
    workerId,
    recoverMessages,
  ]);

  // Function to add a message to the list
  const addMessage = React.useCallback((msg: Message) => {
    setMessages((messages) => [...messages, msg]);
  }, []);

  // Function to send operator message
  const sendOperatorMessage = React.useCallback(
    (msg: string) => {
      const messageData = {
        roomId: room.current as string,
        message: msg,
        role: EntityNameEnum.OPERATOR,
        userId: operatorId as string,
        username: operatorName,
      };

      console.log("messageData", messageData);

      sendMessage(messageData, (msg) => {
        console.log("supplier message sent", msg);
        addMessage(msg);
      });
    },
    [operatorName, operatorId, sendMessage, addMessage]
  );

  // Socket to recover all chats
  React.useEffect(() => {
    if (!workerId) return;
    console.log("getting chats");
    socket?.emit(ChatSocketEvents.RECOVER_CHATS, (payload: Chat[]) => {
      console.log("chats", payload);
      const chatFound = payload.find(
        (chat) => chat.roomId === `room${eventId}${workerId}`
      );
      console.log("supplier chat found", chatFound);
      if (chatFound) {
        recoverMessages(chatFound.roomId);
        room.current = chatFound.roomId;
      } else {
        createRoom();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerId, eventId]);

  // Socket to receive new messages
  React.useEffect(() => {
    socket?.on(ChatSocketEvents.NEW_MESSAGE, (payload: Message) => {
      console.log("new message", payload);
      if (payload.role === UserRoleEnum.WORKER) {
        addMessage(payload);
      }
    });
  }, [socket, addMessage]);

  // Clean up
  React.useEffect(
    () => () => {
      socket?.off(ChatSocketEvents.CONNECT);
      socket?.off(ChatSocketEvents.ROOM_CREATED);
      socket?.off(ChatSocketEvents.NEW_MESSAGE);
      socket?.disconnect();
    },
    [socket]
  );

  return {
    messages,
    completeChat,
    sendOperatorMessage,
  };
};
