import styled from "styled-components";

interface IProps {
  isFocused: boolean;
  disabled: boolean;
  isValid: boolean;
}

export const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  > label {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.gray.primary};
    font-weight: 700;
  }

  > span {
    font-size: 0.7rem;
    color: ${({ theme }) => theme.colors.red};
    font-weight: 500;
  }

  > span,
  label {
    margin-left: 0.5rem;
  }
`;

export const SelectContainer = styled.div<IProps>`
  border: ${({ isFocused, theme }) =>
    isFocused
      ? `1px solid ${theme.colors.gray.secondary}`
      : `1px solid ${theme.colors.gray.lightest}`};
  background-color: ${({ isFocused, theme, disabled }) =>
    isFocused
      ? disabled
        ? theme.colors.gray.lightest
        : "transparent"
      : theme.colors.gray.lightest};

  padding-right: 0.7rem;
  padding-left: 0.7rem;

  position: relative;
  border-radius: 0.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;

  ${({ disabled, theme }) =>
    disabled &&
    `
        border: 1px solid ${theme.colors.gray.secondary};
    `}

  svg {
    position: absolute;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.gray.light};
    :first-child {
      left: 0.4rem;
    }
    :last-child {
      right: 0.4rem;
    }
  }

  .input-error-icon {
    color: ${({ theme }) => theme.colors.red};
    font-size: 1rem;
    right: 0.6rem !important;
  }

  > select {
    color: ${({ theme, isValid }) => !isValid && theme.colors.red};
  }
`;

export const Select = styled.select`
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray.secondary};
    font-size: 0.95rem;
  }

  option {
    color: ${({ theme }) => theme.colors.text} !important;
  }
`;
