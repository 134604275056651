import { useForm } from "react-hook-form";
import { MyInput } from "../../../../shared/components/inputs";
import { Button } from "../../../../shared/components/ui/button";
import { GlobalContentBox } from "../../../../shared/components/ui/global-content-box";
import { GetUsersParams } from "../../../../shared/interfaces";
import { ReactSetter } from "../../../../shared/types";
import { ClientAffiliatesSearchForm } from "./ClientAffiliatesSearch.styles";

interface Props {
  title?: string;
  isLoading: boolean;
  onSearch: ReactSetter<GetUsersParams>;
}

interface FormValues {
  fullname: string;
  phone: string;
  email: string;
}

type Keys = keyof FormValues;

export const ClientAffiliatesSearch = ({
  isLoading,
  title,
  onSearch,
}: Props) => {
  const methods = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    onSearch(data);
  };

  return (
    <GlobalContentBox title={title || "Búsqueda de afiliados"}>
      <ClientAffiliatesSearchForm
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MyInput<Keys>
          name="fullname"
          label="Nombre"
          placeholder="Ingresa un nombre"
        />
        <MyInput<Keys>
          name="phone"
          label="Número de teléfono"
          placeholder="Ingresa un teléfono"
        />
        <MyInput<Keys>
          name="email"
          label="Correo electrónico"
          placeholder="Ingresa un correo electrónico"
        />
        <Button animate loading={isLoading}>
          Buscar
        </Button>
      </ClientAffiliatesSearchForm>
    </GlobalContentBox>
  );
};
