import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  selectIsAuthenticated,
  selectIsValidating,
  selectUser,
} from "../../modules/auth/redux";
import { OperatorActiveEvents } from "../../modules/operators/components/operator-active-events";
import { useGetVehicleTypes } from "../../modules/providers/services";
import { useGetCountries, useGetServices } from "../../redux/rtk-query";
import { Navbar } from "../../shared/components/navbar";
import { Sidebar } from "../../shared/components/sidebar";
import { If } from "../../shared/utilities/If";
import * as S from "./PrivateLayout.styles";

export const PrivateLayout = () => {
  const location = useLocation();
  const user = useSelector(selectUser);
  const isValidating = useSelector(selectIsValidating);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const showActiveEvents = useMemo(
    () =>
      user?.role_name === "Operador" &&
      !location.pathname.includes("create-event") &&
      !location.pathname.includes("event-details") &&
      !location.pathname.includes("dashboard"),
    [user?.role_name, location.pathname]
  );

  const allowRequests = useMemo(
    () => !isValidating && isAuthenticated,
    [isValidating, isAuthenticated]
  );

  const requestVehicleTypes = useMemo(
    () => user?.role_name === "Proveedor" && allowRequests,
    [user?.role_name, allowRequests]
  );

  useGetServices(undefined, { skip: !allowRequests });
  useGetCountries(undefined, { skip: !allowRequests });
  useGetVehicleTypes(undefined, { skip: !requestVehicleTypes });

  if (!isValidating && !isAuthenticated) {
    return <Navigate to={`login?fr=${location.pathname}${location.search}`} />;
  }

  return (
    <S.PrivateLayoutWrapper>
      <Sidebar />
      <S.ContentWrapper>
        <Navbar />
        <S.OutletWrapper>
          <Outlet />
          <If showIf={showActiveEvents}>
            <OperatorActiveEvents />
          </If>
        </S.OutletWrapper>
      </S.ContentWrapper>
    </S.PrivateLayoutWrapper>
  );
};
