import React, { useRef } from "react";
import { useClickOutside } from "react-haiku";
import * as T from "../../../../shared/components/styled";
import { ActionsCellBox } from "../../../../shared/components/styled";
import { getPhoneWithFormat } from "../../../../shared/helpers";
import { useMyNavigate, useToggle } from "../../../../shared/hooks";
import { ActionsCellStyles } from "../../../../shared/styles";
import { Client } from "../../../../shared/types";
import { If } from "../../../../shared/utilities/If";
import { StatusBox } from "../../../users/components/clients-table/ClientTable.styles";

interface Props {
  client: Client;
}

export const AdminClientsTableRow = ({ client }: Props) => {
  const navigate = useMyNavigate();
  const actionsRef = useRef(null);
  const [actionsOpen, toggleActionsOpen, setActionsOpen] = useToggle();
  useClickOutside(actionsRef, () => setActionsOpen(false));

  const goToClientDetails = (isEditMode = false) => {
    isEditMode = client.client_is_deleted ? false : isEditMode;
    return () => {
      navigate({
        name: "Detalles de cliente admin",
        params: { userId: client.user_id },
        query: { isEditMode },
      });
    };
  };

  return (
    <T.TableRow relative>
      <T.TableCell pointer onClick={goToClientDetails()}>
        {client.user_fullname}
      </T.TableCell>
      <T.TableCell>{client.client_rfc}</T.TableCell>
      <T.TableCell>{getPhoneWithFormat(client.user_phone)}</T.TableCell>
      <T.TableCell>{client.user_email}</T.TableCell>
      <T.TableCell>
        <StatusBox status={client.user_status}>{client.user_status}</StatusBox>
      </T.TableCell>
      <If showIf={!actionsOpen}>
        <T.TableCell
          onClick={toggleActionsOpen}
          extraStyles={ActionsCellStyles}
        >
          <div />
          <div />
          <div />
        </T.TableCell>
      </If>
      <If showIf={actionsOpen}>
        <T.TableCell extraStyles={ActionsCellStyles}>
          <div />
          <div />
          <div />
        </T.TableCell>
      </If>
      <If showIf={actionsOpen}>
        <ActionsCellBox ref={actionsRef}>
          <div onClick={goToClientDetails(true)}>
            {client.client_is_deleted ? "Ver detalles" : "Editar Cliente"}
          </div>
        </ActionsCellBox>
      </If>
    </T.TableRow>
  );
};
