import styled from "styled-components";
import { Form } from "../../../../shared/components/form";

export const PaymentForm = styled(Form)`
  display: flex;
  gap: 1rem;

  > * {
    width: 100%;
  }

  .global-content-box-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;
