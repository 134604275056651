import styled from "styled-components";

interface IProps {
  fullWidth?: boolean;
}

export const PageButtonsWrapper = styled.div<IProps>`
  display: flex;
  gap: 1rem;

  button {
    flex: ${({ fullWidth }) => fullWidth && `1`};
  }
`;
