import { useState } from "react";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { useToggle } from "../../../../../shared/hooks";
import { If } from "../../../../../shared/utilities/If";
import { useProviderVehiclesCtx } from "../../../context/provider-vehicles-ctx";
import { Vehicle } from "../../../models";
import {
  CreateVehicleFormProps,
  ValidateVehicleCodeFormProps,
} from "../../../schemas";
import {
  UpdateVehicleBody,
  useCreateVehicle,
  useUpdateVehicle,
  useValidateVehicleCode,
} from "../../../services";
import { VehicleCodeForm } from "./VehicleCodeForm";
import { VehicleDataForm } from "./VehicleDataForm";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activeVehicle?: Vehicle;
}

export const CreateVehicleModal = ({
  isOpen,
  activeVehicle,
  onClose,
}: Props) => {
  const { setActiveVehicle } = useProviderVehiclesCtx();

  const [vehicleId, setVehicleId] = useState<string>();
  const [showCodeInput, , setShowCodeInput] = useToggle();

  const [updateVehicle, { isLoading: isUpdating }] = useUpdateVehicle();
  const [createVehicle, { isLoading: isCreating }] = useCreateVehicle();
  const [validateCode, { isLoading: isValidating }] = useValidateVehicleCode();

  const onCreateVehicle = async ({
    country_code,
    ...data
  }: CreateVehicleFormProps) => {
    const phone = `${country_code}${data.phone}`;
    createVehicle({ ...data, phone })
      .unwrap()
      .then((vehicle) => {
        setVehicleId(vehicle.id);
        setShowCodeInput(true);
      });
  };

  const onUpdateVehicle = async ({
    country_code,
    ...data
  }: CreateVehicleFormProps) => {
    if (!activeVehicle) return;
    const phone = `${country_code}${data.phone}`;
    const body: UpdateVehicleBody = {
      ...data,
      vehicle_id: activeVehicle.vehicle_id,
      phone,
    };

    updateVehicle(body).unwrap().then(onClose);
  };

  const onSubmit = async (data: CreateVehicleFormProps) => {
    activeVehicle ? onUpdateVehicle(data) : onCreateVehicle(data);
  };

  const onValidateCode = (data: ValidateVehicleCodeFormProps) => {
    if (!vehicleId) return;
    validateCode({
      code: data.code,
      vehicle_id: vehicleId,
    })
      .unwrap()
      .then(onClose);
  };

  const afterClose = () => {
    setShowCodeInput(false);
    setVehicleId(undefined);
    setActiveVehicle(undefined);
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={afterClose}
      width={showCodeInput ? "35rem" : "45rem"}
      title={showCodeInput ? "Validar código" : "Crear vehículo"}
      onlyCloseOnButton={showCodeInput}
    >
      <If showIf={!showCodeInput}>
        <VehicleDataForm
          onClose={onClose}
          onSubmit={onSubmit}
          activeVehicle={activeVehicle}
          isLoading={isCreating || isUpdating}
        />
      </If>
      <If showIf={showCodeInput}>
        <VehicleCodeForm
          onClose={onClose}
          isValidating={isValidating}
          onValidateCode={onValidateCode}
        />
      </If>
    </BaseModal>
  );
};
