import styled from "styled-components";
import { NoVisibleScrollBar } from "../../../../../shared/styles";

export const MessagesContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;

  height: 60vh;
  padding: 1rem 2rem;
  border-radius: 1.2rem;
  background-color: ${({ theme }) => theme.colors.gray.lightest};
`;

export const MessagesList = styled.ul`
  ${NoVisibleScrollBar}

  flex: 1;
  height: 100%;
  overflow-y: auto;

  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

/* ----------------------- */

interface MessageProps {
  isOperator?: boolean;
  showPicture?: boolean;
}

export const Message = styled.li<MessageProps>`
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;

  flex-direction: ${({ isOperator }) => (isOperator ? "row-reverse" : "row")};

  > img {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    visibility: ${({ showPicture }) => (showPicture ? "visible" : "hidden")};
  }
`;

export const MessageContent = styled.div`
  min-width: 40%;
  max-width: 70%;
  padding: 0 1rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  word-break: break-word;

  .title {
    font-size: 0.9rem;
    padding: 0.5rem 0;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray.primary};
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.gray.light};
  }

  .message {
    padding: 0.5rem 0;
    color: ${({ theme }) => theme.colors.text};
    font-size: 1rem;
  }
`;

/* ----------------------- */
export const InputWrapper = styled.form`
  width: 100%;
  position: relative;

  button {
    right: 0.4rem;
    top: 0.3rem;
    padding: 0;
    position: absolute;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;

    ${(props) => props.theme.breakpoints.xl} {
      top: 0.45rem;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 1rem;
  padding-right: 3.5rem;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  resize: none;
`;
