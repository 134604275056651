import * as Yup from "yup";
import { emailRequired, stringRequired } from "../../../shared/schemas";

export const LoginFormShape = Yup.object({
  email: emailRequired.lowercase(),
  password: stringRequired.min(
    9,
    "La contraseña debe tener al menos 9 caracteres"
  ),
});

export type LoginFormProps = Yup.InferType<typeof LoginFormShape>;

/* -------------------------------------------------------------------------------- */

export const ValidateCodeFormShape = Yup.object({
  code: stringRequired
    .min(6, "El código debe tener al menos 6 caracteres")
    .max(6, "El código debe tener máximo 6 caracteres")
    // Only numbers
    .matches(/^[0-9]+$/, "El código debe tener solo números"),
});

export type ValidateCodeFormProps = Yup.InferType<typeof ValidateCodeFormShape>;

/* -------------------------------------------------------------------------------- */

export const ForgotPasswordFormShape = Yup.object({
  email: emailRequired.lowercase(),
});

export type ForgotPasswordFormProps = Yup.InferType<
  typeof ForgotPasswordFormShape
>;

/* -------------------------------------------------------------------------------- */
