import styled from "styled-components";
import { Form } from "../../../../../shared/components/form";

export const CreateOperatorFormWrapper = styled(Form)`
  gap: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .buttons-container {
    grid-column: 1 / -1;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;
