import { useState } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { toast } from "react-toastify";
import {
  selectIsReportDetailsModal,
  setIsReportDetailsModal,
} from "../../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../../redux/store";
import { BaseModal } from "../../../../../shared/components/ui/base-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { AutomaticRecords } from "./AutomaticRecords";
import * as S from "./ReportDetails.styles";
import { ManualRecordsOperator } from "./ManualRecordsOperator";
import { ManualRecordProvider } from "./ManualRecordProvider";
import { useGetEventDetails } from "../../../services";
import { Event } from "../../../models/interfaces";
import { Button } from "../../../../../shared/components/ui/button";

type Section = (typeof detailSections)[number];
type SectionSwitcher = Record<
  Section,
  ({ event }: { event?: Event }) => JSX.Element
>;

const detailSections = [
  "Registros automáticos",
  "Registros manuales del operador",
  "Registros manuales del proveedor",
] as const;

interface Props {
  eventId?: string;
  skipRequest: boolean;
  afterCloseModal: () => void;
}

export const ReportsDetailsModal = ({
  afterCloseModal,
  eventId,
  skipRequest,
}: Props) => {
  const dispatch = useAppDispatch();

  const isOpen = useSelector(selectIsReportDetailsModal);
  const [activeSection, setSection] = useState<Section>(detailSections[0]);

  const { data: event, isLoading } = useGetEventDetails(eventId || skipToken, {
    skip: skipRequest,
  });

  const SectionToShow: SectionSwitcher = {
    "Registros automáticos": AutomaticRecords,
    "Registros manuales del proveedor": ManualRecordProvider,
    "Registros manuales del operador": ManualRecordsOperator,
  };

  const SectionComponent = SectionToShow[activeSection];

  const onDownload = () => {
    toast.dismiss();
    toast.warn("La descarga del reporte aun no está disponible");
  };

  const onClose = () => {
    dispatch(setIsReportDetailsModal(false));
  };

  const afterClose = () => {
    toast.dismiss();
    setSection(detailSections[0]);
    afterCloseModal();
  };

  return (
    <BaseModal
      width="auto"
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterClose={afterClose}
      title="Detalles de reporte"
      isLoading={isLoading}
    >
      <S.ReportDetailsWrapper>
        <S.ReportSectionsList>
          {detailSections.map((section) => (
            <S.ReportSection
              active={section === activeSection}
              onClick={() => setSection(section)}
            >
              {section}
            </S.ReportSection>
          ))}
        </S.ReportSectionsList>
        <SectionComponent event={event} />
        {/* <PageButtons
                    show
                    fullWidth
                    onCancel={onClose}
                    cancelText='Cerrar'
                    onProceed={onDownload}
                    proceedText='Descargar'
                /> */}
        <Button secondary onClick={onClose} xl width="100%">
          Cerrar
        </Button>
      </S.ReportDetailsWrapper>
    </BaseModal>
  );
};
