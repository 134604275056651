import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  Box,
  PageContainer,
  PageTitle,
} from "../../../../shared/components/styled";
import { Button } from "../../../../shared/components/ui/button";
import { GetUsersParams } from "../../../../shared/interfaces";
import { ProvidersSearch } from "../../components/providers-search";
import { ProvidersTable } from "../../components/providers-table";
import { useGetProviders } from "../../services";
import {
  selectIsCreateProviderModal,
  setIsCreateProviderModal,
} from "../../../../redux/reducers/ui-reducer";
import { CreateProviderModal } from "../../components/modals/create-provider-modal";
import { useAppDispatch } from "../../../../redux/store";

export const ProvidersListPage = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useSelector(selectIsCreateProviderModal);
  const [providerFilters, setProviderFilters] = useState<GetUsersParams>({});
  const {
    data: providers,
    isFetching,
    isLoading,
  } = useGetProviders(providerFilters);

  const onOpenModal = () => {
    dispatch(setIsCreateProviderModal(true));
  };

  const onPaginate = (page: number) => {
    setProviderFilters({
      ...providerFilters,
      page,
    });
  };

  return (
    <PageContainer>
      <Box flex justifyBetween alignCenter>
        <PageTitle>Proveedores</PageTitle>
        <Button Icon={FaPlus} fontSize="1rem" onClick={onOpenModal}>
          Crear Proveedor
        </Button>
      </Box>
      <ProvidersSearch
        isLoading={isFetching}
        setProviderFilters={setProviderFilters}
      />
      <ProvidersTable
        isLoading={isLoading}
        onPageChange={onPaginate}
        pageCount={providers?.total_pages || 0}
        providers={providers?.results || []}
      />
      {isModalOpen && <CreateProviderModal />}
    </PageContainer>
  );
};
