import { useSelector } from "react-redux";
import {
  selectIsDeleteClientModal,
  setIsDeleteClientModal,
} from "../../../../../redux/reducers/ui-reducer";
import { useAppDispatch } from "../../../../../redux/store";
import { MyModal } from "../../../../../shared/components/my-modal";
import { CloseModal } from "../../../../../shared/components/ui/close-modal";
import { PageButtons } from "../../../../../shared/components/ui/page-buttons";
import { useMyNavigate } from "../../../../../shared/hooks";
import { useClientDetailsPageCtx } from "../../../context/client-details-page-ctx/ClientDetailsPageCtx";
import { useDeleteClient } from "../../../services";
import * as S from "./ClientDeleteModal.styles";

export const ClientDeleteModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useMyNavigate();

  const isOpen = useSelector(selectIsDeleteClientModal);
  const { user } = useClientDetailsPageCtx();
  const [deleteCompany, { isLoading }] = useDeleteClient();

  const onDelete = async () => {
    if (user?.role_name !== "Empresa") return;
    deleteCompany(user.company_id)
      .unwrap()
      .then(() => {
        dispatch(setIsDeleteClientModal(false));
        navigate({ name: "Empresas" });
      });
  };

  const closeModal = () => {
    dispatch(setIsDeleteClientModal(false));
  };

  return (
    <MyModal isOpen={isOpen} onRequestClose={closeModal}>
      <S.DeleteClientFormWrapper>
        <CloseModal onClick={closeModal} />
        <h2>Eliminar usuario</h2>
        <p>{`¿Desea eliminar al usuario ${user?.user_fullname}?`}</p>
        <PageButtons
          show
          onCancel={closeModal}
          proceedText="Si, eliminar"
          onProceed={onDelete}
          isLoading={isLoading}
        />
      </S.DeleteClientFormWrapper>
    </MyModal>
  );
};
