import axios, { AxiosHeaders } from "axios";
import { COMPANY_API_URL } from "../../redux/rtk-query";

const baseURL = COMPANY_API_URL;

export const companyAxiosService = axios.create({
  baseURL,
});

companyAxiosService.interceptors.request.use((config) => {
  const token = localStorage.getItem("access");
  if (token && config.headers) {
    (config.headers as AxiosHeaders).set("Authorization", `Bearer ${token}`);
  }
  return config;
});
