import React, { useCallback, useState } from "react";
import { useToggle } from "../../../../shared/hooks";
import { For } from "../../../../shared/utilities/For";
import { Coverage } from "../../../events/models/interfaces";
import { useDeleteCoverage } from "../../../manager/services";
import { DeleteProviderZoneModal } from "../modals/delete-provider-zone";
import { CoverageItem } from "./CoverageItem";

interface Props {
  editable?: boolean;
  deletable?: boolean;
  coverages: Coverage[];
  addNewCircle?: (circle: google.maps.Circle) => void;
  onDeleteCircle?: (coverageId: string) => void;
}

export const CoverageCircles = ({
  coverages,
  editable,
  deletable,
  addNewCircle,
  onDeleteCircle,
}: Props) => {
  const [isModalOpen, toggleModalOpen] = useToggle();
  const [coverageId, setCoverageId] = useState<string>();

  const [deleteCoverage, { isLoading: isDeleting }] = useDeleteCoverage();

  const onSelectCoverage = useCallback(
    (coverageSelectedId: string) => {
      if (!deletable) return;
      setCoverageId(coverageSelectedId);
      toggleModalOpen();
    },
    [toggleModalOpen, deletable]
  );

  const onDeleteCoverage = useCallback(() => {
    if (!coverageId) return;
    deleteCoverage(coverageId)
      .unwrap()
      .then(() => {
        toggleModalOpen();
        setCoverageId(undefined);
        onDeleteCircle?.(coverageId);
      });
  }, [coverageId, toggleModalOpen, deleteCoverage, onDeleteCircle]);

  return (
    <>
      <For
        each={coverages}
        render={(coverage) => (
          <CoverageItem
            coverage={coverage}
            editable={editable}
            addNewCircle={addNewCircle}
            onSelectCoverage={onSelectCoverage}
          />
        )}
      />
      <DeleteProviderZoneModal
        isCoverage
        isLoading={isDeleting}
        onClose={toggleModalOpen}
        isOpen={isModalOpen}
        onDeleteZone={onDeleteCoverage}
      />
    </>
  );
};
