import { yupResolver } from "@hookform/resolvers/yup";
import { Marker } from "@react-google-maps/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectServices } from "../../../../redux/rtk-query";
import { Map } from "../../../../shared/components/map/Map";
import { Button } from "../../../../shared/components/ui/button";
import { includesAll } from "../../../../shared/helpers";
import { useMyNavigate } from "../../../../shared/hooks";
import { For } from "../../../../shared/utilities/For";
import { If } from "../../../../shared/utilities/If";
import { useEventsSocketCtx } from "../../context/events-socket-ctx";
import { CreateEventFormProps, CreateEventFormShape } from "../../schemas";
import { useGetPlaceDetails } from "../../services";
import { CreateEventBody } from "../../services/requests.interfaces";
import { SocketErrorResponse } from "../../services/responses.interfaces";
import * as S from "./CreateEventForm.styles";
import { EventAffiliate } from "./EventAffiliate";
import { EventDirection } from "./EventDirection";

export const CreateEventForm = () => {
  const navigate = useMyNavigate();

  const methods = useForm<CreateEventFormProps>({
    resolver: yupResolver(CreateEventFormShape),
  });

  const { createEvent } = useEventsSocketCtx();

  const direction = methods.watch("direction");
  const affiliateName = methods.watch("fullname");
  const affiliateBirthday = methods.watch("birthday");

  const [map, setMap] = useState<google.maps.Map>();
  const [placeId, setPlaceId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [serviceId, setServiceId] = useState<string>();
  const [affiliateId, setAffiliateId] = useState<string>();
  const { data: services = [], isLoading: isGettingServices } =
    useSelector(selectServices);

  const { data: placeLocation } = useGetPlaceDetails(placeId || skipToken);

  const onMapLoad = React.useCallback((mapLoaded: google.maps.Map) => {
    setMap(mapLoaded);
  }, []);

  const onMapUnmount = React.useCallback(() => {
    setMap(undefined);
  }, []);

  const onSuccessfulCreate = () => {
    toast.dismiss();
    toast.success("Evento creado");
    navigate({ name: "Dashboard" });
  };

  const onFailedCreate = (res: SocketErrorResponse) => {
    if (res?.message === "no subscriptions available") {
      return toast.warn("El usuario no cuenta con suscripciones activas");
    }
    if (res?.message === "the user has an active requested event") {
      toast.warn("El usuario ya cuenta con un evento activo");
    } else if (includesAll(res?.message, "user", "available", "quantities")) {
      toast.warn(
        "El usuario no cuenta con cantidades de servicios disponibles"
      );
    } else if (includesAll(res?.message, "no", "available")) {
      toast.warn("No hay unidades disponibles");
    } else {
      toast.error("Algo salió mal, intente nuevamente");
    }
  };

  const onCreateEvent = (data: CreateEventFormProps) => {
    toast.dismiss();
    const style = { bodyStyle: { fontSize: "1rem" } };
    if (!serviceId) {
      return toast.warn("Debe seleccionar un tipo de servicio", style);
    }
    if (!placeId || !placeLocation) {
      return toast.warn("Debe seleccionar una dirección", style);
    }
    if (!affiliateId) {
      return toast.warn("Debe seleccionar un afiliado", style);
    }
    const body: CreateEventBody = {
      affiliateId,
      serviceId,
      attention_location: data.direction,
      attention_latitude: `${placeLocation.lat}`,
      attention_longitude: `${placeLocation.lng}`,
    };
    setIsLoading(true);
    createEvent(body, onSuccessfulCreate, onFailedCreate, () =>
      setIsLoading(false)
    );
  };

  useEffect(() => {
    if (!placeLocation || !map) return;
    map.panTo(placeLocation);
    map.setZoom(16);
  }, [placeLocation, map]);

  useEffect(() => {
    if (!affiliateId) return;
    methods.clearErrors("fullname");
    methods.clearErrors("birthday");
  }, [affiliateId, methods]);

  return (
    <S.CreateEventFormContainer
      methods={methods}
      onSubmit={methods.handleSubmit(onCreateEvent)}
    >
      <EventAffiliate
        affiliateId={affiliateId}
        setValues={methods.setValue}
        affiliateName={affiliateName}
        setAffiliateId={setAffiliateId}
        affiliateBirthday={affiliateBirthday}
      />
      <S.EmergencyTypeContainer>
        <h3>Tipo de emergencia</h3>
        <S.EmergencyTypeList isLoading={isGettingServices}>
          <If showIf={!isGettingServices}>
            <For
              each={services}
              render={(service) => (
                <S.EmergencyTypeItem
                  onClick={() => setServiceId(service.service_id)}
                  active={service.service_id === serviceId}
                >
                  {service.service_name}
                </S.EmergencyTypeItem>
              )}
            />
          </If>
          <If showIf={isGettingServices}>
            <For
              each={[...Array(5)]}
              render={() => <S.EmergencyTypeItem active={false} />}
            />
          </If>
        </S.EmergencyTypeList>
      </S.EmergencyTypeContainer>
      <EventDirection
        placeId={placeId}
        direction={direction}
        setPlaceId={setPlaceId}
        setDirection={methods.setValue}
      />
      <Map height="35rem" onLoaded={onMapLoad} onUnmounted={onMapUnmount}>
        {placeLocation && (
          <Marker icon="/assets/anngel_pin.png" position={placeLocation} />
        )}
      </Map>
      <S.KeyWordInputContainer hasError={false}>
        <Button xl loading={isLoading}>
          Crear evento
        </Button>
      </S.KeyWordInputContainer>
    </S.CreateEventFormContainer>
  );
};
