import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { PaginationContainer } from "./PaginatedItems.styles";

export interface PaginationProps {
  pageCount: number;
  onPageChange?: (page: number) => void;
}

export const PaginatedItems = ({
  pageCount,
  onPageChange,
}: PaginationProps) => {
  const handlePageChange = (page: { selected: number }) => {
    onPageChange?.(page.selected + 1);
  };

  return (
    <PaginationContainer>
      <ReactPaginate
        pageCount={pageCount}
        nextLabel={<MdArrowForwardIos className="movement-icon" />}
        previousLabel={<MdArrowBackIos className="movement-icon" />}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        previousLinkClassName="pagination__link"
        nextLinkClassName="pagination__link"
        disabledClassName="pagination__link--disabled"
        activeClassName="pagination__link--active"
      />
    </PaginationContainer>
  );
};
