import styled from "styled-components";
import { ProviderWorkersListItem } from "../../../../providers/components/provider-workers/ProviderWorkers.styles";

export const HistoricDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HistoricEntity = styled(ProviderWorkersListItem)`
  > img {
    border-radius: 50%;
    min-width: 7rem;
    width: 7rem;
  }
`;

export const HistoricEntityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;

  .entity-title {
    font-size: 1.3rem;
    font-weight: bold;
  }

  :first-child {
    border-bottom: 0.1rem solid #e5e5e5;
  }

  :not(:first-child) {
    padding-top: 2rem;
  }
`;
