import React, { useState } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { For } from "../../utilities/For";
import { If } from "../../utilities/If";
import * as S from "./MySelect.styles";

interface Props<T, U> {
  name: T;
  label?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  setValueAs?: (value: any) => void;
  onChange?: (element: React.ChangeEvent<HTMLSelectElement>) => void;

  values?: U[];
  get?: keyof U;
  show?: keyof U | (keyof U)[];
  getSeparator?: string;

  [key: string]: any;
}

export const MySelect = <T extends string, U>({
  name,
  label,
  className,
  disabled,
  children,
  placeholder = "Seleccione una opción",
  onChange,
  setValueAs,

  get,
  show,
  values,
  getSeparator = " - ",

  ...props
}: Props<T, U>) => {
  const [isFocused, setIsFocused] = useState(false);

  const { register, getValues, setValue } = useFormContext();
  const { errors } = useFormState({ name });

  const hasErrors = !!errors[name];

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    const value = getValues(name);
    if (value) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
      setValue(name, "" as any);
    }
  };

  const onClickContainer = () => {
    const input = document.getElementById(name);
    input?.focus();
    setIsFocused(true);
  };

  const valueToGet = (value: any) => {
    return get ? value[get] : value;
  };

  const valueToShow = (value: any) => {
    if (!show) return value;
    if (Array.isArray(show)) {
      return show.map((s) => value[s]).join(getSeparator);
    }
    return value[show];
  };

  return (
    <S.SelectWrapper className="select-wrapper">
      <If showIf={!!label}>
        <label htmlFor={name}>{label}</label>
      </If>
      <S.SelectContainer
        isValid={!hasErrors}
        disabled={!!disabled}
        className={`select-container ${className}`}
        isFocused={isFocused}
        onClick={onClickContainer}
      >
        <S.Select
          id={name}
          defaultValue=""
          disabled={disabled}
          placeholder={placeholder}
          className="select-item"
          onFocus={onFocus}
          {...register(name, {
            setValueAs,
            onBlur,
            onChange,
          })}
          {...props}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          <If showIf={!children}>
            <For
              each={values || []}
              render={(value) => (
                <option value={valueToGet(value)}>{valueToShow(value)}</option>
              )}
            />
          </If>
          <If showIf={!!children}>{children}</If>
        </S.Select>
      </S.SelectContainer>
      <ErrorMessage
        name={name}
        render={({ message }) => <span className="input-error">{message}</span>}
      />
    </S.SelectWrapper>
  );
};
